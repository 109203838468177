import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import {
  AccountingBooking,
  AccountingBookingGroup,
  AccountingBookingType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import StructLoader from "@/components/StructLoader/StructLoader";
import DebugDataComponent from "@/debug/DebugDataComponent";
import Log from "@/debug/Log";
import i18n from "@/i18n";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFQuickSelect from "@/modules/abstract-ui/forms/input/BFQuickSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { useHttpCache } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import NumberUtils from "@/utils/ NumberUtils";
import ArrayUtils from "@/utils/ArrayUtils";
import { hasValue, isDefined } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { Loader } from "rsuite";
import "./CBRentalAgreementOpenDebitPositions.scss";
import CBRentalAgreementPositionInfo from "./CBRentalAgreementPositionInfo";

export type DebitPositionResult = AccountingBookingGroup & {
  bookings: AccountingBooking[];
  contraBookings: AccountingBooking[];
};
export interface ManualBookingPosition {
  name: string;
  id: string;
  taxRate: number;
}

export type OpenDebitPositionValue = {
  value: {
    amount: number;
    currency: string;
  };

  bookingType: AccountingBookingType;
  costId: string;
  costAccount?: string;

  counterBooking?: {
    value: {
      amount: number;
      currency: string;
    };
    bookingType: AccountingBookingType;
  };
};
interface CBRentalAgreementOpenDebitPositionsProps {
  accountingData: AccountingData;
  account: string;
  countbooking?: boolean;
  value?: OpenDebitPositionValue[];

  onChange?: (value: OpenDebitPositionValue[]) => void;

  openAmount?: number;

  manualBookingPositions?: ManualBookingPosition[];
}

const calcAmount = (booking: AccountingBooking, pos: DebitPositionResult) => {
  const relevantContraBookings = pos.contraBookings
    .map((e) => AccountingService.getCorrectedBooking(pos.data.account, e))
    .filter((e) => e.data.costId === booking._id);

  const amount = NumberUtils.normalizeDecimal(
    booking.data.value.converted.amount *
      (booking.data.bookingType === AccountingBookingType.HABEN ? -1 : 1) -
      _.sum(
        relevantContraBookings
          .filter((e) => e.data.bookingType === AccountingBookingType.HABEN)
          .map((e) => e.data.value.converted.amount)
      ) +
      _.sum(
        relevantContraBookings
          .filter((e) => e.data.bookingType === AccountingBookingType.SOLL)
          .map((e) => e.data.value.converted.amount)
      )
  );

  return amount;
};

const CBRentalAgreementOpenDebitPositions = (
  props: CBRentalAgreementOpenDebitPositionsProps
) => {
  const [openState, setOpenState] = useState<string[]>([]);
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-open-debit-positions-${props.account}`,
    `/api/accounting/${props.accountingData.accounting.data.entity}/${props.account}/getOpenDebitPositions`,
    "get",
    null,
    undefined,
    undefined,
    (data) => {
      return ArrayUtils.sortData(data, {
        dir: "desc",
        key: "data.date",
      });
    }
  );

  const evaluatedData = evaluateCurrentData({
    account: props.account,
    accountingData: props.accountingData,
    positions: data?.data,
    openAmount: props.openAmount,
    value: props.value,
  });

  const distribute = (
    amountToDistribute: number,
    bookingType: AccountingBookingType,
    bookingsToBook: AccountingBooking[] | null,
    isCounterBooking: boolean,
    ignoreMax = false
  ) => {
    console.log("DISTRIBUTION 1", {
      params: {
        amountToDistribute,
        bookingType,
        bookingsToBook,
        isCounterBooking,
        ignoreMax,
      },
    });
    const currency = props.accountingData.accounting.data.currency;
    const bookingsSorted = bookingsToBook
      ? getSortedBookings(bookingsToBook, props.accountingData)
      : [];
    const bookingsSortedIds = bookingsSorted.map((e) => e._id);
    if (bookingsToBook === null) {
      bookingsSortedIds.push(null);
    }

    const valuesWithoutBookings = props.value?.filter(
      (e) => !bookingsSortedIds.includes(e.costId)
    );
    const valuesWhichAreBooked = props.value?.filter((e) =>
      bookingsSortedIds.includes(e.costId)
    );

    let amountToDistributeNormalized = normalize(
      amountToDistribute,
      bookingType
    );

    const typeToSet =
      amountToDistributeNormalized < 0
        ? AccountingBookingType.SOLL
        : AccountingBookingType.HABEN;
    const absoluteAmountToDistribute = Math.abs(amountToDistributeNormalized);

    let restSum = absoluteAmountToDistribute;
    // set all values to 0, they will be changed afterwards
    let alteredValues = valuesWhichAreBooked.map(
      (bookingValue) =>
        ({
          ...bookingValue,
          ...(isCounterBooking
            ? {
                counterBooking: {
                  value: {
                    amount: 0,
                    currency,
                  },
                  bookingType: typeToSet,
                },
              }
            : {
                bookingType: typeToSet,
                value: {
                  amount: 0,
                  currency,
                },
              }),
        } as OpenDebitPositionValue)
    );

    const updateAlteredValue = (
      bookingToBook: AccountingBooking,
      distributeToBookingAmount: number
    ) => {
      const alteredValueToUpdate = alteredValues.find(
        (e) => e.costId === (bookingToBook?._id || null)
      );

      if (alteredValueToUpdate) {
        if (isCounterBooking) {
          alteredValueToUpdate.counterBooking = {
            value: {
              amount: NumberUtils.normalizeDecimal(distributeToBookingAmount),
              currency,
            },
            bookingType: typeToSet,
          };
        } else {
          alteredValueToUpdate.value = {
            amount: NumberUtils.normalizeDecimal(distributeToBookingAmount),
            currency,
          };
          alteredValueToUpdate.bookingType = typeToSet;
        }
      } else {
        alteredValues.push({
          costId: bookingToBook?._id || null,
          costAccount: bookingToBook?.data.contraAccount || null,
          value: {
            amount: isCounterBooking
              ? 0
              : NumberUtils.normalizeDecimal(distributeToBookingAmount),
            currency,
          },
          bookingType: typeToSet,
          counterBooking: isCounterBooking
            ? {
                value: {
                  amount: NumberUtils.normalizeDecimal(
                    distributeToBookingAmount
                  ),
                  currency,
                },
                bookingType: typeToSet,
              }
            : undefined,
        });
      }
    };

    if (bookingsToBook === null) {
      updateAlteredValue(null, restSum);
    } else {
      for (let i = 0; i < bookingsSorted.length && restSum > 0; i++) {
        const bookingToBook = bookingsSorted[i];
        // const bookingTypeToBook =
        //   bookingToBook.data.bookingType === AccountingBookingType.HABEN
        //     ? AccountingBookingType.SOLL
        //     : AccountingBookingType.HABEN;

        const valuesOfBooking = evaluatedData.bookingValues[bookingToBook._id];
        const op = ignoreMax ? restSum : valuesOfBooking.op;

        const distributeToBookingAmount = Math.min(Math.abs(op), restSum);
        updateAlteredValue(bookingToBook, distributeToBookingAmount);

        restSum -= distributeToBookingAmount;
      }
    }

    Log.info("DISTRIBUTION", {
      params: {
        amountToDistribute,
        bookingType,
        bookingsToBook,
        isCounterBooking,
      },
      result: {
        valuesWithoutBookings,
        alteredValues,
      },
    });
    props.onChange([...valuesWithoutBookings, ...alteredValues]);
  };

  const editable = isDefined(props.onChange);
  const headers = [
    {
      label: "",
      renderPosition: (pos: DebitPositionResult) => {
        return (
          <div className="collapse-button">
            <BFButton
              onClick={() => {
                if (openState.includes(pos._id)) {
                  setOpenState(openState.filter((id) => id !== pos._id));
                } else {
                  setOpenState([...openState, pos._id]);
                }
              }}
            >
              <BfIcon
                size="xxs"
                {...DefaultIcons.CHEVRON}
                className={`${openState.includes(pos._id) ? "open" : ""}`}
              />
            </BFButton>
          </div>
        );
      },
      renderManualPosition: () => {
        return <div></div>;
        // return (
        //   <div className="collapse-button">
        //     <BFButton
        //       onClick={() => {
        //         if (openState.includes("manual")) {
        //           setOpenState(openState.filter((id) => id !== "manual"));
        //         } else {
        //           setOpenState([...openState, "manual"]);
        //         }
        //       }}
        //     >
        //       <BfIcon
        //         size="xxs"
        //         {...DefaultIcons.CHEVRON}
        //         className={`${openState.includes("manual") ? "open" : ""}`}
        //       />
        //     </BFButton>
        //   </div>
        // );
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t("cb:RentalAgreement.OpenDebitPositions.Date", "Datum"),

      renderPosition: (pos: DebitPositionResult) => {
        return <div>{StringUtils.formatDate(pos.data.date)} </div>;
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        return <div>{StringUtils.formatDate(booking.data.date)}</div>;
      },

      renderManualPosition: () => {
        return <div></div>;
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.OpenDebitPositions.BookingText",
        "Buchungstext"
      ),

      renderPosition: (pos: DebitPositionResult) => {
        return <div>{pos.data.displayName} </div>;
      },
      renderManualPosition: () => {
        return (
          <div>
            {i18n.t("cb:RentalAgreement.OpenDebitPositions.Other", "Sonstiges")}
          </div>
        );
      },
      // renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
      //   return null;
      //   return <div>{manualBookingPosition.name}</div>;
      // },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResult,
        subIndex: number,
        subLength: number
      ) => {
        const account = props.accountingData.accounting.data.accounts.find(
          (e) => e.id === booking.data.contraAccount
        );
        return (
          <div className={`position-name`}>
            <div
              className={`position-parent-indicator ${
                subIndex === subLength - 1 ? "last" : ""
              }`}
            >
              <div className={`position-border`} />
              <div className={`position-indicator`} />
            </div>
            <div className={`position-text`}>
              {LanguageService.translateLabel(account.displayName)}
            </div>
          </div>
        );
      },
    },

    {
      fixedWith: 85,
      label: "",
      renderPosition: (pos: DebitPositionResult) => {
        return <CBRentalAgreementPositionInfo pos={pos} />;
      },
      renderBooking: () => {
        return <div></div>;
      },

      renderManualPosition: () => {
        return <div></div>;
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
    },
    editable
      ? {
          label: i18n.t(
            "cb:RentalAgreement.OpenDebitPositions.AmountOfBookings",
            "OP-Summe"
          ),

          className: "currency-column",
          renderPosition: (pos: DebitPositionResult) => {
            const entryData = evaluatedData.groupValues[pos._id];
            const type =
              entryData.op >= 0
                ? AccountingBookingType.HABEN
                : AccountingBookingType.SOLL;
            return (
              <div className={`value ${entryData.op > 0 ? "negative" : ""}`}>
                {StringUtils.formatCurrency(
                  Math.abs(entryData.op),
                  undefined,
                  undefined,
                  props.accountingData.accounting.data.currency
                )}{" "}
                {type}
              </div>
            );
          },
          renderBooking: (
            booking: AccountingBooking,
            pos: DebitPositionResult
          ) => {
            const entryData = evaluatedData.bookingValues[booking._id];
            const type =
              entryData.op >= 0
                ? AccountingBookingType.HABEN
                : AccountingBookingType.SOLL;
            return (
              <div className={`value ${entryData.op > 0 ? "negative" : ""}`}>
                {StringUtils.formatCurrency(
                  Math.abs(entryData.op),
                  undefined,
                  undefined,
                  booking.data.value.currency
                )}{" "}
                {type}
              </div>
            );
          },

          renderManualPosition: () => {
            return <div></div>;
          },
        }
      : null,
    {
      label: i18n.t(
        "cb:RentalAgreement.OpenDebitPositions.OpenAmount",
        "Offen"
      ),
      className: "currency-column",
      renderPosition: (pos: DebitPositionResult) => {
        const entryData = evaluatedData.groupValues[pos._id];
        const type =
          entryData.open >= 0
            ? AccountingBookingType.HABEN
            : AccountingBookingType.SOLL;

        return (
          <div
            className={`value ${entryData.open === 0 ? "balanced" : ""} ${
              entryData.open > 0 ? "negative" : ""
            }`}
          >
            {StringUtils.formatCurrency(
              Math.abs(entryData.open),
              undefined,
              undefined,
              props.accountingData.accounting.data.currency
            )}{" "}
            {type}
          </div>
        );
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        const entryData = evaluatedData.bookingValues[booking._id];
        const type =
          entryData.open >= 0
            ? AccountingBookingType.HABEN
            : AccountingBookingType.SOLL;
        return (
          <div
            className={`value  ${entryData.open === 0 ? "balanced" : ""} ${
              entryData.open > 0 ? "negative" : ""
            }`}
          >
            {StringUtils.formatCurrency(
              Math.abs(entryData.open),
              undefined,
              undefined,
              booking.data.value.currency
            )}{" "}
            {type}
          </div>
        );
      },

      renderManualPosition: () => {
        return <div></div>;
      },
      // renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
      //   return <div></div>;
      // },
    },
    ...(editable
      ? [
          {
            label: props.countbooking
              ? i18n.t(
                  "cb:RentalAgreement.OpenDebitPositions.Bookout",
                  "Ausbuchung"
                )
              : i18n.t(
                  "cb:RentalAgreement.OpenDebitPositions.Amount",
                  "Zahlung"
                ),
            renderPosition: (pos: DebitPositionResult) => {
              const entryData = evaluatedData.groupValues[pos._id];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction =
                props.openAmount === 0 || entryData.open === 0;
              const allowRevert = entryData.bookIn !== 0;
              return (
                <div>
                  <BFInput
                    type="priceInput"
                    currencySuffix={StringUtils.getCurrencySymbol(
                      props.accountingData.accounting.data.currency
                    )}
                    removePrefixPadding
                    prefix={
                      <div className={`position-quick-actions`}>
                        <BFButton
                          appearance="link"
                          disabled={disableQuickAction}
                          noPadding
                          size="sm"
                          onClick={() => {
                            distribute(
                              hasValue(props.openAmount)
                                ? Math.min(
                                    Math.abs(entryData.op),
                                    Math.abs(props.openAmount)
                                  )
                                : Math.abs(entryData.op),
                              entryData.op < 0
                                ? AccountingBookingType.HABEN
                                : AccountingBookingType.SOLL,
                              pos.bookings,
                              false
                            );
                          }}
                          tooltip={{
                            tooltip: i18n.t(
                              "cb:RentalAgreement.OpenDebitPositions.DistributeOpenAmount",
                              "Offenen Betrag auf den Positionen aufteilen"
                            ),
                          }}
                        >
                          <BfIcon
                            size="xxs"
                            type="light"
                            data="diagram-arrow-right"
                          />
                        </BFButton>
                        {allowRevert && (
                          <BFButton
                            appearance="link"
                            noPadding
                            size="sm"
                            onClick={() =>
                              distribute(
                                0,
                                entryData.op < 0
                                  ? AccountingBookingType.SOLL
                                  : AccountingBookingType.HABEN,
                                pos.bookings,
                                false
                              )
                            }
                            tooltip={{
                              tooltip: i18n.t(
                                "cb:RentalAgreement.OpenDebitPositions.RedoOpenAmount",
                                "Beträge wieder zurücksetzen auf 0"
                              ),
                            }}
                          >
                            <BfIcon size="xxs" type="light" data="undo" />
                          </BFButton>
                        )}
                      </div>
                    }
                    suffix={
                      <BFQuickSelect
                        value={valueType}
                        // disabled={values.length === 0}
                        onChange={(newType: AccountingBookingType) => {
                          if (newType !== valueType) {
                            distribute(
                              entryData.bookIn,
                              newType,
                              pos.bookings,
                              false
                            );
                          }
                        }}
                        options={[
                          { label: i18n.t("acc:SollShort", "S"), value: "S" },
                          { label: i18n.t("acc:HabenShort", "H"), value: "H" },
                        ]}
                      />
                    }
                    removeSuffixPadding
                    textAlign="right"
                    value={Math.abs(entryData.bookIn)}
                    onChange={(value: number) => {
                      distribute(
                        props.openAmount
                          ? Math.min(
                              Math.abs(value),
                              Math.abs(props.openAmount)
                            )
                          : Math.abs(value),

                        valueType,
                        pos.bookings,
                        false
                      );
                    }}
                  />
                </div>
              );
            },
            renderFooter: () => {
              const sum = evaluatedData.sums.bookIn;
              return (
                <div
                  className={classNames("footer-value", {
                    negative: sum < 0,
                    positive: sum > 0,
                  })}
                >
                  {StringUtils.formatCurrency(
                    sum,
                    undefined,
                    undefined,
                    props.accountingData.accounting.data.currency
                  )}
                </div>
              );
            },
            renderBooking: (
              booking: AccountingBooking,
              pos: DebitPositionResult
            ) => {
              const entryData = evaluatedData.bookingValues[booking._id];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction =
                props.openAmount === 0 || entryData.open === 0;
              return (
                <div>
                  <BFInput
                    type="priceInput"
                    currencySuffix={StringUtils.getCurrencySymbol(
                      props.accountingData.accounting.data.currency
                    )}
                    removePrefixPadding
                    prefix={
                      <BFButton
                        appearance="link"
                        disabled={disableQuickAction}
                        size="xs"
                        onClick={() => {
                          distribute(
                            hasValue(props.openAmount)
                              ? Math.min(
                                  Math.abs(entryData.op),
                                  Math.abs(props.openAmount)
                                )
                              : Math.abs(entryData.op),
                            entryData.op < 0
                              ? AccountingBookingType.HABEN
                              : AccountingBookingType.SOLL,
                            [booking],
                            false
                          );
                        }}
                        tooltip={{
                          tooltip: i18n.t(
                            "cb:RentalAgreement.OpenDebitPositions.BalanceOpenAmount",
                            "Offenen Betrag ausgleichen"
                          ),
                        }}
                      >
                        <BfIcon
                          size="xxs"
                          type="light"
                          data="keyboard-arrow-right"
                        />
                      </BFButton>
                    }
                    suffix={
                      <BFQuickSelect
                        value={valueType}
                        onChange={(newType: AccountingBookingType) => {
                          if (newType !== valueType) {
                            distribute(
                              entryData.bookIn,
                              newType,
                              [booking],
                              false
                            );
                          }
                        }}
                        options={[
                          { label: i18n.t("acc:SollShort", "S"), value: "S" },
                          { label: i18n.t("acc:HabenShort", "H"), value: "H" },
                        ]}
                      />
                    }
                    removeSuffixPadding
                    textAlign="right"
                    value={Math.abs(entryData.bookIn)}
                    onChange={(value: number) => {
                      distribute(
                        props.openAmount
                          ? Math.min(
                              Math.abs(value),
                              Math.abs(props.openAmount)
                            )
                          : Math.abs(value),

                        valueType,
                        [booking],
                        false
                      );
                    }}
                  />
                </div>
              );
            },

            renderManualPosition: () => {
              const entryData = evaluatedData.bookingValues["null"];
              const valueType =
                (entryData?.bookIn || 0) < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction =
                NumberUtils.normalizeDecimal(props.openAmount) === 0;
              return (
                <div>
                  <BFInput
                    type="priceInput"
                    currencySuffix={StringUtils.getCurrencySymbol(
                      props.accountingData.accounting.data.currency
                    )}
                    removePrefixPadding
                    prefix={
                      hasValue(props.openAmount) && (
                        <BFButton
                          appearance="link"
                          disabled={disableQuickAction}
                          size="xs"
                          onClick={() => {
                            distribute(
                              Math.abs(props.openAmount),
                              (props.openAmount || 0) >= 0
                                ? AccountingBookingType.HABEN
                                : AccountingBookingType.SOLL,
                              null,
                              false
                            );
                          }}
                          tooltip={{
                            tooltip: i18n.t(
                              "cb:RentalAgreement.OpenDebitPositions.BalanceOpenAmount",
                              "Offenen Betrag ausgleichen"
                            ),
                          }}
                        >
                          <BfIcon
                            size="xxs"
                            type="light"
                            data="keyboard-arrow-right"
                          />
                        </BFButton>
                      )
                    }
                    suffix={
                      <BFQuickSelect
                        value={valueType}
                        onChange={(val: AccountingBookingType) => {}}
                        options={[
                          { label: i18n.t("acc:SollShort", "S"), value: "S" },
                          { label: i18n.t("acc:HabenShort", "H"), value: "H" },
                        ]}
                      />
                    }
                    removeSuffixPadding
                    textAlign="right"
                    value={Math.abs(entryData?.bookIn || 0)}
                    onChange={(value: number) => {
                      distribute(
                        hasValue(props.openAmount)
                          ? Math.min(
                              Math.abs(value),
                              Math.abs(props.openAmount)
                            )
                          : Math.abs(value),

                        valueType,
                        null,
                        false
                      );
                    }}
                  />
                </div>
              );
            },
          },
          ...(props.countbooking
            ? [
                {
                  label: i18n.t(
                    "cb:RentalAgreement.OpenDebitPositions.BookIn",
                    "Einbuchung"
                  ),

                  renderFooter: () => {
                    const sum =
                      evaluatedData.sums.bookOut + evaluatedData.sums.bookIn;

                    return (
                      <div
                        className={classNames("footer-value", {
                          negative: sum < 0,
                          positive: sum > 0,
                        })}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.OpenDebitPositions.BookingOutstanding",
                          "Noch zu Buchen:"
                        )}{" "}
                        {StringUtils.formatCurrency(
                          sum,
                          undefined,
                          undefined,
                          props.accountingData.accounting.data.currency
                        )}
                      </div>
                    );
                  },
                  renderPosition: (pos: DebitPositionResult) => {
                    const entryData = evaluatedData.groupValues[pos._id];
                    const valueType =
                      entryData.bookOut >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;
                    const allowRevert = entryData.bookOut !== 0;

                    // const disableQuickAction =
                    //   props.openAmount === 0 || entryData.open === 0;
                    const disableQuickAction = false;
                    const values = [];

                    const maxValue =
                      evaluatedData.sums.bookIn * -1 -
                      evaluatedData.sums.bookOut;
                    return (
                      <div>
                        <BFInput
                          type="priceInput"
                          currencySuffix={StringUtils.getCurrencySymbol(
                            props.accountingData.accounting.data.currency
                          )}
                          removePrefixPadding
                          prefix={
                            <div className={`position-quick-actions`}>
                              <BFButton
                                appearance="link"
                                disabled={disableQuickAction}
                                noPadding
                                size="sm"
                                onClick={() => {
                                  distribute(
                                    maxValue,
                                    entryData.op < 0
                                      ? AccountingBookingType.HABEN
                                      : AccountingBookingType.SOLL,
                                    pos.bookings,
                                    true
                                  );
                                }}
                                tooltip={{
                                  tooltip: i18n.t(
                                    "cb:RentalAgreement.OpenDebitPositions.DistributeOpenAmount",
                                    "Offenen Betrag auf den Positionen aufteilen"
                                  ),
                                }}
                              >
                                <BfIcon
                                  size="xxs"
                                  type="light"
                                  data="diagram-arrow-right"
                                />
                              </BFButton>
                              {allowRevert && (
                                <BFButton
                                  appearance="link"
                                  noPadding
                                  size="sm"
                                  onClick={() => {
                                    distribute(
                                      0,
                                      entryData.op < 0
                                        ? AccountingBookingType.HABEN
                                        : AccountingBookingType.SOLL,
                                      pos.bookings,
                                      true
                                    );
                                  }}
                                  tooltip={{
                                    tooltip: i18n.t(
                                      "cb:RentalAgreement.OpenDebitPositions.RedoOpenAmount",
                                      "Beträge wieder zurücksetzen auf 0"
                                    ),
                                  }}
                                >
                                  <BfIcon size="xxs" type="light" data="undo" />
                                </BFButton>
                              )}
                            </div>
                          }
                          suffix={
                            <BFQuickSelect
                              value={valueType}
                              disabled={values.length === 0}
                              onChange={(newType: AccountingBookingType) => {
                                if (newType !== valueType) {
                                  distribute(
                                    entryData.bookOut,
                                    newType,
                                    pos.bookings,
                                    true
                                  );
                                }
                              }}
                              options={[
                                {
                                  label: i18n.t("acc:SollShort", "S"),
                                  value: "S",
                                },
                                {
                                  label: i18n.t("acc:HabenShort", "H"),
                                  value: "H",
                                },
                              ]}
                            />
                          }
                          removeSuffixPadding
                          textAlign="right"
                          value={Math.abs(entryData.bookOut)}
                          onChange={(value: number) => {
                            distribute(
                              Math.min(Math.abs(maxValue), Math.abs(value)),
                              valueType,
                              pos.bookings,
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  },
                  //todo change to einbuchung
                  renderBooking: (
                    booking: AccountingBooking,
                    pos: DebitPositionResult
                  ) => {
                    const entryData = evaluatedData.bookingValues[booking._id];

                    // const maxValue =
                    //   evaluatedData.sums.bookIn + evaluatedData.sums.bookOut;

                    const maxValue =
                      evaluatedData.sums.bookIn * -1 -
                      evaluatedData.sums.bookOut +
                      (entryData?.bookOut || 0);

                    const valueType =
                      (entryData?.bookIn || 0) >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;
                    const disableQuickAction =
                      props.openAmount === 0 || maxValue === 0;

                    return (
                      <div>
                        <BFInput
                          type="priceInput"
                          currencySuffix={StringUtils.getCurrencySymbol(
                            props.accountingData.accounting.data.currency
                          )}
                          removePrefixPadding
                          prefix={
                            <BFButton
                              appearance="link"
                              disabled={disableQuickAction}
                              size="xs"
                              onClick={() => {
                                distribute(
                                  Math.min(
                                    Math.abs(entryData.op),
                                    Math.abs(maxValue)
                                  ),
                                  valueType,
                                  [booking],
                                  true
                                );
                              }}
                              tooltip={{
                                tooltip: i18n.t(
                                  "cb:RentalAgreement.OpenDebitPositions.BalanceOpenAmount",
                                  "Offenen Betrag ausgleichen"
                                ),
                              }}
                            >
                              <BfIcon
                                size="xxs"
                                type="light"
                                data="keyboard-arrow-right"
                              />
                            </BFButton>
                          }
                          suffix={
                            <BFQuickSelect
                              value={valueType}
                              onChange={(newType: AccountingBookingType) => {
                                if (newType !== valueType) {
                                  distribute(
                                    entryData.bookOut,
                                    newType,
                                    [booking],
                                    true
                                  );
                                }
                              }}
                              options={[
                                {
                                  label: i18n.t("acc:SollShort", "S"),
                                  value: "S",
                                },
                                {
                                  label: i18n.t("acc:HabenShort", "H"),
                                  value: "H",
                                },
                              ]}
                            />
                          }
                          removeSuffixPadding
                          textAlign="right"
                          value={Math.abs(entryData.bookOut)}
                          onChange={(value: number) => {
                            distribute(
                              Math.min(value, Math.abs(maxValue)),
                              valueType,
                              [booking],
                              true,
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  },

                  //todo change to einbuchung
                  renderManualPosition: () => {
                    const entryData = evaluatedData.bookingValues["null"];
                    const valueType =
                      (entryData?.bookIn || 0) >= 0
                        ? AccountingBookingType.SOLL
                        : AccountingBookingType.HABEN;

                    const maxValue =
                      evaluatedData.sums.bookOut +
                      evaluatedData.sums.bookIn -
                      (entryData?.bookOut || 0);
                    const disableQuickAction = maxValue === 0;
                    return (
                      <div>
                        <BFInput
                          type="priceInput"
                          currencySuffix={StringUtils.getCurrencySymbol(
                            props.accountingData.accounting.data.currency
                          )}
                          removePrefixPadding
                          prefix={
                            <BFButton
                              appearance="link"
                              disabled={disableQuickAction}
                              size="xs"
                              onClick={() => {
                                distribute(
                                  Math.abs(maxValue),
                                  (maxValue || 0) >= 0
                                    ? AccountingBookingType.SOLL
                                    : AccountingBookingType.HABEN,
                                  null,
                                  true
                                );
                              }}
                              tooltip={{
                                tooltip: i18n.t(
                                  "cb:RentalAgreement.OpenDebitPositions.BalanceOpenAmount",
                                  "Offenen Betrag ausgleichen"
                                ),
                              }}
                            >
                              <BfIcon
                                size="xxs"
                                type="light"
                                data="keyboard-arrow-right"
                              />
                            </BFButton>
                          }
                          suffix={
                            <BFQuickSelect
                              value={valueType}
                              onChange={(val: AccountingBookingType) => {}}
                              options={[
                                {
                                  label: i18n.t("acc:SollShort", "S"),
                                  value: "S",
                                },
                                {
                                  label: i18n.t("acc:HabenShort", "H"),
                                  value: "H",
                                },
                              ]}
                            />
                          }
                          removeSuffixPadding
                          textAlign="right"
                          value={Math.abs(entryData?.bookOut || 0)}
                          onChange={(value: number) => {
                            distribute(
                              Math.min(Math.abs(value), Math.abs(maxValue)),
                              valueType,
                              null,
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]
            : []),
        ]
      : []),
  ].filter((e) => e);

  return (
    <StructLoader
      unitType={props.accountingData.accounting.data.type}
      structTypes={["objectKind", "orga"]}
      render={() => {
        return (
          <div
            className={classNames(`cb-rental-agreement-open-debit-positions`)}
          >
            <DebugDataComponent
              data={{
                date: data,
                value: props.value,
                openAmount: props.openAmount,
              }}
            />
            <table>
              <tr className={`head-row`}>
                {headers.map((e, index) => (
                  <th className={classNames(e.className)} key={index}>
                    {e.label}
                  </th>
                ))}
              </tr>
              {data.state === "loading" && (
                <tr>
                  <td colSpan={headers.length}>
                    <Loader />
                  </td>
                </tr>
              )}
              {data.state === "error" && (
                <tr>
                  <td colSpan={headers.length}>
                    {i18n.t(
                      "cb:RentalAgreement.OpenDebitPositions.Error",
                      "Fehler beim Laden der Positionen"
                    )}
                  </td>
                </tr>
              )}
              {data.state === "success" && (
                <>
                  <DebugDataComponent data={data.data} />
                  {data.data.length === 0 && (
                    <tr>
                      <td
                        colSpan={headers.length}
                        className={`no-debit-position`}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.OpenDebitPositions.NoOpenPositions",
                          "Keine offenen Sollstellungen"
                        )}
                      </td>
                    </tr>
                  )}
                  {data.data.map((pos) => (
                    <>
                      <tr className={`debit-position`}>
                        {headers.map((e, index) => {
                          return (
                            <td
                              className={classNames(e.className)}
                              style={{
                                width: e.fixedWith,
                              }}
                            >
                              {e.renderPosition(pos)}
                            </td>
                          );
                        })}
                      </tr>
                      {openState.includes(pos._id) &&
                        pos.bookings
                          .map((booking) =>
                            AccountingService.getCorrectedBooking(
                              pos.data.account,
                              booking
                            )
                          )
                          .map((booking, i) => {
                            return (
                              <tr
                                className={`debit-booking ${
                                  i === pos.bookings.length - 1
                                    ? "last-booking"
                                    : ""
                                }`}
                              >
                                {headers.map((e, index) => {
                                  return (
                                    <td className={classNames(e.className)}>
                                      {e.renderBooking(
                                        booking,
                                        pos,
                                        i,
                                        pos.bookings.length
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                    </>
                  ))}

                  {props.manualBookingPositions && (
                    <>
                      <tr>
                        {headers.map((e, index) => {
                          return (
                            <td className={classNames(e.className)}>
                              {e.renderManualPosition()}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  )}
                  {editable && (
                    <tr className={`footer-row`}>
                      {headers.map((e, index) => {
                        return (
                          <td className={classNames(e.className)}>
                            {e.renderFooter?.() || <div></div>}
                          </td>
                        );
                      })}
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        );
      }}
    />
  );
};

export default CBRentalAgreementOpenDebitPositions;

type ValueObject = {
  op: number;
  open: number;
  bookIn: number;
  bookOut: number;
};

const normalize = (amount: number, bookingType: AccountingBookingType) => {
  if (bookingType === "S") {
    return amount * -1;
  }
  return amount;
};

const evaluateCurrentData = (props: {
  positions: DebitPositionResult[];
  accountingData: AccountingData;
  account: string;
  openAmount?: number;
  value?: OpenDebitPositionValue[];
}) => {
  const result: {
    bookingValues: {
      [bookingId: string]: ValueObject;
    };
    groupValues: {
      [groupId: string]: ValueObject;
    };
    sums: {
      bookIn: number;
      bookOut: number;
    };
  } = {
    bookingValues: {},
    groupValues: {},
    sums: {
      bookIn: 0,
      bookOut: 0,
    },
  };

  props.positions?.forEach((pos) => {
    const positionObject: ValueObject = {
      op: 0,
      open: 0,
      bookIn: 0,
      bookOut: 0,
    };

    pos.bookings
      .map((booking) =>
        AccountingService.getCorrectedBooking(props.account, booking)
      )
      .forEach((booking) => {
        const valuesForBooking =
          props.value?.filter((e) => e.costId === booking._id) || [];

        const contraBookingsForBooking = pos.contraBookings
          .map((e) => AccountingService.getCorrectedBooking(props.account, e))
          .filter((e) => e.data.costId === booking._id);

        const sumOfContraBookings = _.sum(
          contraBookingsForBooking.map((e) =>
            normalize(e.data.value.converted.amount, e.data.bookingType)
          )
        );

        const bookIn =
          _.sum(
            valuesForBooking.map((value) =>
              normalize(value.value?.amount || 0, value.bookingType)
            )
          ) || 0;
        const bookOut =
          _.sum(
            valuesForBooking.map((value) =>
              normalize(
                value.counterBooking?.value?.amount || 0,
                value.counterBooking?.bookingType
              )
            )
          ) || 0;

        const original = normalize(
          booking.data.value.converted.amount,
          booking.data.bookingType
        );

        const op = original + sumOfContraBookings;

        result.bookingValues[booking._id] = {
          op: NumberUtils.normalizeDecimal(op),
          open: NumberUtils.normalizeDecimal(op + bookIn),
          bookIn: NumberUtils.normalizeDecimal(bookIn),
          bookOut: NumberUtils.normalizeDecimal(bookOut),
        };

        positionObject.op = NumberUtils.normalizeDecimal(
          positionObject.op + op
        );
        positionObject.bookIn = NumberUtils.normalizeDecimal(
          positionObject.bookIn + bookIn
        );
        positionObject.bookOut = NumberUtils.normalizeDecimal(
          positionObject.bookOut + bookOut
        );
        positionObject.open = NumberUtils.normalizeDecimal(
          positionObject.open + op + bookIn
        );

        result.sums.bookIn = NumberUtils.normalizeDecimal(
          result.sums.bookIn + bookIn
        );
        result.sums.bookOut = NumberUtils.normalizeDecimal(
          result.sums.bookOut + bookOut
        );
      });

    result.groupValues[pos._id] = positionObject;
  });

  const otherBooking = props.value?.find((e) => e.costId === null);

  if (otherBooking) {
    const bookIn =
      NumberUtils.normalizeDecimal(
        normalize(otherBooking.value.amount, otherBooking.bookingType)
      ) || 0;
    const bookOut = otherBooking.counterBooking
      ? NumberUtils.normalizeDecimal(
          normalize(
            otherBooking.counterBooking?.value?.amount || 0,
            otherBooking.counterBooking?.bookingType
          )
        )
      : 0;

    result.bookingValues["null"] = {
      bookIn,
      bookOut,
      op: 0,
      open: 0,
    };
    result.sums.bookIn += bookIn;
    result.sums.bookOut += bookOut;
  }

  return result;
};

const getSortedBookings = (
  bookings: AccountingBooking[],
  accountingData: AccountingData
) => {
  const bookingsSorted = [...bookings].sort((a, b) => {
    const accA = accountingData.accounting.data.accounts.find(
      (e) => e.id === a.data.contraAccount
    );
    const accB = accountingData.accounting.data.accounts.find(
      (e) => e.id === b.data.contraAccount
    );

    return (
      (accA.priority || Number.MAX_SAFE_INTEGER) -
      (accB.priority || Number.MAX_SAFE_INTEGER)
    );
  });
  return bookingsSorted;
};
