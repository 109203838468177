import AccountingLoader, {
  AccountingData,
} from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import {
  AccountingAccountDataAccount,
  AccountingBookingFormValue,
  AccountType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import CBRentalAgreementOpenDebitPositions, {
  OpenDebitPositionValue,
} from "@/apps/tatar/cashBudget/views/tenants/components/rental-agreements/accounting/CBRentalAgreementOpenDebitPositions";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import { hasValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import React, { useState } from "react";
import { Field } from "react-final-form";
import AccountingFormAdditionalData from "../AccountingFormAdditionalData";
import AccountingRentalInfo from "../info/AccountingRentalInfo";
import "./AccountingInternBookingForm.scss";

interface AccountingInternBookingFormProps {
  onClose: () => void;
  onSuccess?: (asset: BaseAsset) => void;

  forAccount?: string;
  accounting?: AccountingData;
  accountTypes: AccountType[];
  baseAccount: string;

  title: string;

  transformSubmitValues?: (
    values: AccountingInternBookingFormValue
  ) => AccountingBookingFormValue;
}

const getInitialValue = (props: AccountingInternBookingFormProps) => {
  const { forAccount } = props;
  let account: AccountingAccountDataAccount = null;
  if (forAccount) {
    account = props.accounting.accounting.data.accounts.find(
      (e) => e.id === forAccount
    );
  }
  return {
    entity: props.accounting.accounting.data.entity,
    objectId: account?.objectId || null,
    account: account?.id || null,
    bookingText: `${props.title} - ${StringUtils.formatDate(new Date())}`,
    date: new Date(),
    bookings: [],
    note: "",
    linkedAsset: [],
    attachments: [],
  } as Partial<AccountingInternBookingFormValue>;
};

const transformFormdataToSubmitdata = (
  props: AccountingInternBookingFormProps,
  values: AccountingInternBookingFormValue
) => {
  return props.transformSubmitValues
    ? props.transformSubmitValues(values)
    : ({
        groupDisplayName: values.bookingText,
        date: values.date,
        entity: values.entity,
        account: props.baseAccount,
        fullAmount: 0,
        note: values.note,
        linkedAsset: [
          ...values.linkedAsset,
          ...values.attachments.map((e) => ({
            assetType: AssetTypes.CashBudget.Attachment,
            assetId: e,
          })),
        ],

        frames: [
          {
            objectId: values.objectId,
            contraAccount: values.account,
            bookings: values.bookings.filter(
              (e) => (e.value?.amount || 0) !== 0
            ),
          },
        ],
      } as AccountingBookingFormValue);
};

export type AccountingInternBookingFormValue = {
  entity: string;
  objectId: string;
  account: string;
  bookingText: string;
  date: Date;
  bookings: OpenDebitPositionValue[];
  note: string;
  linkedAsset: {
    assetType: string;
    assetId: string;
    extra?: any;
  }[];
  attachments: string[];
};

const AccountingInternBookingForm: React.FC<AccountingInternBookingFormProps> =
  (props) => {
    const [initialValue] = useState(getInitialValue(props));
    return (
      <FormStruct
        title={props.title}
        className={classNames("accounting-intern-booking-form")}
        onSubmit={async (values: AccountingInternBookingFormValue) => {
          const submitData: AccountingBookingFormValue =
            transformFormdataToSubmitdata(props, values);

          const result = await AccountingService.createBooking(submitData);
          props.onSuccess?.(result);
          props.onClose();
        }}
        ignoreSubmitOnEnter
        usePrompt
        //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
        submitText={i18n.t(
          "Accounting.AccountingInternBookingForm.Submit",
          "Speichern"
        )}
        onAbort={props.onClose}
        initialValues={initialValue}
        render={(form) => (
          <>
            <div className={`__flex`}>
              <Field
                name={`objectId`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity"]}>
                      {([entity]) => (
                        <BFChooserSelect
                          label={i18n.t(
                            "acc:AccountingBookingForm.objectId",
                            "Objekt"
                          )}
                          disabled={!entity || hasValue(props.forAccount)}
                          hideSubLabel
                          {...input}
                          {...FormValidators.getValidation(meta)}
                          // DataBus.emit("WHISPER", {
                          //   identifier: "accounting-entity-select",
                          //   type: "CLOSE",
                          // });
                          onChange={(value) => {
                            if (value !== input.value) {
                              // reset bookings
                              form.form.mutators.setValue(`bookings`, []);
                              form.form.mutators.setValue(`account`, null);
                            }
                            input.onChange(value);
                          }}
                          data={
                            entity
                              ? OrgaStruct.getObjectSelectOptions(entity)
                              : []
                            //   AccountingService.getAccountsOfEntityGrouped(
                            //   props.accounting
                            // )
                          }
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
              <Field
                name={`account`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity", `objectId`]}>
                      {([entity, objectId]) => (
                        <AccountingLoader
                          entityId={entity}
                          render={(accounting) => (
                            <BFChooserSelect
                              label={i18n.t(
                                "acc:AccountingBookingForm.account",
                                "Konto"
                              )}
                              disabled={!entity || hasValue(props.forAccount)}
                              hideSubLabel
                              {...input}
                              onChange={(value) => {
                                if (value !== input.value) {
                                  // reset bookings
                                  form.form.mutators.setValue(`bookings`, []);
                                }
                                input.onChange(value);
                              }}
                              {...FormValidators.getValidation(meta)}
                              groupSort={(a: string, b: string) => {
                                const sortArr = [
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.debitor_rentalagreement
                                  ),
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.debitor_deposit
                                  ),
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.debitor_loss
                                  ),
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.debitor_rentalposition
                                  ),
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.debitor_other
                                  ),
                                  AccountingService.getAccountTypeLabel(
                                    AccountType.bank
                                  ),
                                ];

                                // return by sortArr =>
                                return (
                                  (sortArr.indexOf(a) || 0) -
                                  (sortArr.indexOf(b) || 0)
                                );
                              }}
                              data={
                                AccountingService.getAccountsOfEntityGrouped(
                                  accounting,
                                  undefined,
                                  props.accountTypes
                                )
                                //   AccountingService.getAccountsOfEntityGrouped(
                                //   props.accounting
                                // )
                              }
                            />
                          )}
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
            </div>

            <FormFieldValues names={[`account`]}>
              {([account]) => {
                if (account) {
                  const foundAccount =
                    props.accounting.accounting.data.accounts.find(
                      (e) => e.id === account
                    );
                  if (
                    foundAccount?.assetLink.assetType ===
                    AssetTypes.Rental.RentalAgreement
                  ) {
                    return (
                      <AccountingRentalInfo
                        accounting={props.accounting}
                        rentalAgreementId={foundAccount?.assetLink.assetId}
                      />
                    );
                  }
                }

                return null;
              }}
            </FormFieldValues>

            <AccountingFormAdditionalData accounting={props.accounting} />
            <FormFieldValues names={[`objectId`, "account"]}>
              {([objectId, account]) => (
                <>
                  {objectId && account && (
                    <AssetLoader
                      assetType={AssetTypes.Portfolio.Object}
                      id={objectId}
                      render={(object: OAObject) => (
                        <Field name={`bookings`}>
                          {({ input, meta }) => (
                            <>
                              <CBRentalAgreementOpenDebitPositions
                                {...input}
                                accountingData={props.accounting}
                                // openAmount={openAmount}
                                account={account}
                                manualBookingPositions={object.data.feature?.immo?.accounting?.debitposition.map(
                                  (debit) => ({
                                    id: debit.id,
                                    name: LanguageService.translateLabel(
                                      debit.displayName
                                    ),
                                    taxRate: debit.taxRate,
                                  })
                                )}
                              />
                            </>
                          )}
                        </Field>
                      )}
                    />
                  )}
                </>
              )}
            </FormFieldValues>
          </>
        )}
      />
    );
  };

export default AccountingInternBookingForm;
