import classNames from "classnames";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Message } from "rsuite";
import { IComponent } from "../../../../configurable/layouts/IComponent";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../../utils/abstracts/AbstractStylableComponent";
import BfIcon from "../../icon/BfIcon";
import "./BFMessage.scss";

type Props = {
  type: "info" | "success" | "warning" | "error";
  titleKey?: string;
  title?: React.ReactNode;
  textKey?: string;
  text?: React.ReactNode;
  component?: IComponent;
  closable?: boolean;
  showIcon?: boolean;
  squished?: boolean;
  className?: string;
} & AbstractStylableProps &
  RouteComponentProps<any> &
  WithTranslation;

type States = {} & AbstractStylableStates;

class BFMessage extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  renderIcon(type) {
    let icon = null;
    switch (type) {
      case "info":
        icon = "information-circle";
        break;
      case "success":
        icon = "check-1";
        break;
      case "warning":
        icon = "alert-circle";
        break;
      case "error":
        icon = "alert-triangle";
        break;
    }
    if (icon) {
      return <BfIcon type="bf" data={icon} size={"xs"} />;
    }
    return null;
  }

  render() {
    const {
      i18n,
      type,

      titleKey,
      textKey,
      text,
      title,
      component,
      className,
      closable,
      showIcon,
    } = this.props;

    return (
      <Message
        style={this.state.usedStyle}
        type={type}
        showIcon={false}
        closable={closable}
        className={classNames("bf-message", className, {
          squished: this.props.squished,
        })}
      >
        <div className={`bf-message-content`}>
          {showIcon ? this.renderIcon(type) : null}
          {component ? (
            (window as any).ComponentsMapper.createElement(
              component,
              this.props.params
            )
          ) : (
            <div className={`text-content`}>
              {titleKey || title ? (
                <div className={`title`}>
                  {titleKey ? i18n.t(titleKey) : title || ""}
                </div>
              ) : null}
              {textKey || text ? (
                <div className={`message-description`}>
                  {textKey ? i18n.t(textKey) : text || ""}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </Message>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(BFMessage))
) as React.ComponentType<any>;
