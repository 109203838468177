import { AssetTypes } from "@/model/AssetTypes";
import React, { Component } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Animation } from "rsuite";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import BFDropzone from "../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFCheckbox from "../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFSelect from "../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import CDNService from "../../../../services/CDNService";
import DataBus from "../../../../services/DataBus";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import LanguageService from "../../../../services/LanguageService";
import SubmitService from "../../../../services/SubmitService";
import { BFToast } from "../../../../utils/BFToast";
import { DataBusSubKeys } from "../../../../utils/Constants";
import { HTTP } from "../../../../utils/Http";
import CashBudgetUtils from "../CashBudgetUtils";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import { splitBookingWithFile } from "../services/CashBudgetActions";
import CashBudgetService from "../services/CashBudgetService";
import ActionComponent from "./../../../../configurable/components/ActionComponent/ActionComponent";
import { clearFlexCacheData } from "./../../../../redux/actions/application/application-actions";
import "./CashBudgetCategoryDetailEntry.scss";
import { DetailEntryType } from "./CashBudgetCategoryDetailEntryTypes";
import CashBudgetDetailEntryCardContent from "./CashBudgetDetailEntryCardContent";
import CBBookingCommentForm from "./bookingCommentForm/CBBookingCommentForm";

const { Collapse } = Animation;

enum Functionality {
  DELETION,
  COMMENT,
  CATEGORY_CHANGE,
  OBJECT_CHANGE,
  UPLOAD,
  SPLIT,
  SEARCH_INVOICE,
}

function getFunctionalityForType(
  type: string,
  assetType: string,
  assetLinkType: string
): Functionality[] {
  if (type === undefined || type === null) {
    return [];
  }
  if (type === DetailEntryType.IMPORT) {
    if (assetType === "lq-booking") {
      return [
        Functionality.SEARCH_INVOICE,
        Functionality.SPLIT,
        Functionality.CATEGORY_CHANGE,
        Functionality.COMMENT,
        Functionality.UPLOAD,
      ];
    } else {
      return [Functionality.CATEGORY_CHANGE, Functionality.COMMENT];
    }
  }
  if (type === DetailEntryType.COMPARISON_FIX) {
    return [Functionality.COMMENT];
  }
  if (type === DetailEntryType.COMPARISON_ADJUSTMENTS) {
    return [Functionality.DELETION, Functionality.COMMENT];
  }
  if (type === DetailEntryType.MANUAL) {
    return [
      Functionality.DELETION,
      Functionality.COMMENT,
      Functionality.CATEGORY_CHANGE,
      Functionality.OBJECT_CHANGE,
    ];
  }
  if (type === null || type === undefined || type === DetailEntryType.IMPORT) {
    return [
      Functionality.DELETION,
      Functionality.COMMENT,
      Functionality.CATEGORY_CHANGE,
      Functionality.OBJECT_CHANGE,
      Functionality.UPLOAD,
    ];
  }
  return [];
}

type Props = {
  requestType: "starmoney" | "datev";
  assetType: string;
  clearFlexCacheData: (category: string) => void;
  userId: string;
  userDisplayname: string;
  entry: AssetCashBudgetEntry;
  debug: boolean;
  hashId: string;
  category?: string;
  expanded?: boolean;
  onSelect?: () => void;
  tableToUpdate: string;
  showCategory?: boolean;
  showAccountData?: boolean;
  hideEntityData?: boolean;
  simple?: boolean;
  filteredCategories?: string[];
};

type States = {
  expanded: boolean;
  commentFormVisible: boolean;
  categoryFormVisible: boolean;
  categoryFormOtherCategories: boolean;
  commentText: string;
  selectedFilter: string;
  objectFormVisible: boolean;
  selectedCategory: string;
  submitLoading: boolean;
  isDraggingOver: boolean;
  isUploading: boolean;
};
export const CASHBUDGET_CATEGORY_DETAIL_TABLE = "cash-budget-list-overlay";
class CashBudgetCategoryDetailEntry extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    expanded: false,
    selectedFilter: null,
    categoryFormOtherCategories: false,
    selectedCategory: null,
    objectFormVisible: false,
    categoryFormVisible: false,
    commentFormVisible: false,
    commentText: "",
    submitLoading: false,
    isDraggingOver: false,
    isUploading: false,
  };

  ref = React.createRef<any>();
  openFileDialogSepaXML;
  clearCache() {
    const {
      entry: {
        data: { type },
      },
    } = this.props;
    if (
      type === DetailEntryType.COMPARISON_ADJUSTMENTS ||
      type === DetailEntryType.COMPARISON_FIX
    ) {
      CashBudgetService.clearFlexCachesPlan();
    } else {
      CashBudgetService.clearFlexCaches();
    }
  }
  submitCategory(targetCategory) {
    if (this.state.submitLoading) {
      return;
    }
    this.setState({ submitLoading: true });

    const submitData = {
      _id: this.props.entry._id,
      data: {
        category: targetCategory,
      },
    };
    SubmitService.submitData({
      id: this.props.entry._id,
      data: submitData,
      type: "asset",
      assetType: this.props.assetType,
      pushToTableCache: this.props.tableToUpdate,
      pushToCache: true,
      onSuccess: (data) => {
        this.setState({
          categoryFormOtherCategories: false,
          submitLoading: false,
          selectedCategory: null,
          categoryFormVisible: false,
        });
        this.clearCache();
      },
      onError: (err) => {
        this.setState({ submitLoading: false });
      },
      ignorePropChecks: true,
    });
  }
  submitObject(targetObject) {
    if (this.state.submitLoading) {
      return;
    }
    this.setState({ submitLoading: true });

    const submitData = {
      _id: this.props.entry._id,
      data: {
        objectId: targetObject,
      },
    };
    SubmitService.submitData({
      id: this.props.entry._id,
      data: submitData,
      type: "asset",
      assetType: this.props.assetType,
      pushToTableCache: this.props.tableToUpdate,
      pushToCache: true,
      onSuccess: (data) => {
        this.setState({
          submitLoading: false,
          selectedFilter: data.data.objectId,
          objectFormVisible: false,
        });
        this.clearCache();
        // DataBus.emit(DataBusSubKeys.RELOAD, { identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE] });
      },
      onError: (err) => {
        this.setState({ submitLoading: false });
      },
      ignorePropChecks: true,
    });
  }

  reloadEntry() {
    SubmitService.submitData({
      id: this.props.entry._id,
      data: {
        _id: this.props.entry._id,
      },
      type: "asset",
      assetType: this.props.assetType,
      pushToTableCache: this.props.tableToUpdate,
      pushToCache: true,
      onSuccess: (data) => {
        this.clearCache();
      },
      force: true,
      onError: (err) => {},
      ignorePropChecks: true,
    });
  }

  onDropHandler(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) {
    Log.info("###BFUpload drophandler", acceptedFiles, rejectedFiles, event);
    this.setState({ isDraggingOver: false });
    if (this.state.isUploading || this.props.entry.data.type === "forecast") {
      return;
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({ isUploading: true });
      Promise.allSettled(
        acceptedFiles.map((file, index) =>
          this.handleFileupload(this.props.entry._id + "_" + index, file)
        )
      )
        .then((values) => {
          values.forEach((e, index) => {
            if (e.status === "rejected") {
              BFToast.open({
                type: "error",
                content: (
                  <Trans key="cb:CategoryDetailEntry.errorUpload">
                    Hochladen der Datei "{{ name: acceptedFiles[index].name }}"
                    ist fehlgeschlagen. Bitte versuchen Sie es erneut.
                  </Trans>
                ),
              });
            }
          });
        })
        .catch((values) => {
          if (Array.isArray(values)) {
            values.forEach((e, index) => {
              if (e.status === "rejected") {
                BFToast.open({
                  type: "error",
                  content: (
                    <Trans key="cb:CategoryDetailEntry.errorUpload">
                      Hochladen der Datei "{{ name: acceptedFiles[index].name }}
                      " ist fehlgeschlagen. Bitte versuchen Sie es erneut.
                    </Trans>
                  ),
                });
              }
            });
          } else {
            BFToast.open({
              type: "error",
              content: i18n.t(
                "cb:CateoryDetailEntry.errorUploadGeneral",
                "Upload war fehlerhaft. Bitte versuchen Sie es erneut."
              ),
            });
          }
        })
        .finally(() => {
          this.reloadEntry();

          this.setState({ isUploading: false });
        });
    }
  }
  async handleFileupload(tempId: string, file: File) {
    return new Promise<any>((resolve, reject) => {
      CDNService.uploadFile({
        tempID: "tst",
        assetType: AssetTypes.CashBudget.Attachment,
        assetField: "data.files",
        filename: file.name,
        file: file,
      })
        .then((resp: { cdnID: string; tempID: string }) => {
          SubmitService.submitData({
            id: tempId,
            type: "asset",
            assetType: AssetTypes.CashBudget.Attachment,
            data: {
              data: {
                mandantor: "", // this.props.entry.data.mandant, // TODO changed - chekc if correct
                type: this.props.entry.data.type,
                uploadType: "manual",
                files: {
                  [resp.cdnID]: {},
                },
              },
            },
            ignorePropChecks: true,

            onSuccess: (submittedData) => {
              HTTP.post({
                url: `liquiplanservice/receipt/assign`,
                target: "EMPTY",
                bodyParams: {
                  entryID: this.props.entry._id,
                  receiptID: submittedData._id,
                },
              })
                .then((data) => {
                  resolve(data);
                })
                .catch((err) => {
                  reject(err);
                });
            },
            onError: (err) => {
              reject(err);
            },
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTopHeader() {
    const { entry, hashId, category } = this.props;

    if (!entry.data.type) {
      return null;
    }

    if (entry.data.type === DetailEntryType.COMPARISON_FIX) {
      return i18n.t("CategoryDetailsEntry.fixedShouldValue", "Fixer SOLL-Wert");
    }
    if (entry.data.type === DetailEntryType.COMPARISON_ADJUSTMENTS) {
      return i18n.t(
        "CategoryDetailsEntry.adjustedShouldValue",
        "Angepasster SOLL-Wert"
      );
    }
    if (entry.data.type === DetailEntryType.MANUAL) {
      return `${i18n.t("CategoryDetailsEntry.plannedFrom", "Geplant von")} ${
        entry.meta.cf ? entry.meta.cf.name : "-"
      }`;
    }
    if (entry.data.type.indexOf("forecast") !== -1) {
      return i18n.t("Label.prognose");
    }
    if (entry.data.type.indexOf("sharepoint") !== -1) {
      return i18n.t(
        "CategoryDetailsEntry.sharepointImport",
        "Sharepoint Import"
      );
    }
    return null;
  }

  render() {
    const { expanded, isDraggingOver } = this.state;
    const { entry, hashId, category, assetType, filteredCategories } =
      this.props;
    // const otherCategories =
    // 	this.props.type === "POSITIVE" ? CashBudgetService.getPositiveCategories() : CashBudgetService.getNegativeCategories();

    const categoryChanged = !filteredCategories
      ? false
      : category === undefined
      ? false
      : filteredCategories.indexOf(entry.data.category) === -1;

    const functionalities = getFunctionalityForType(
      entry.data.type,
      assetType,
      entry.data?.asset_link?.type
    );
    return (
      <Dropzone
        onDragEnter={(e) => this.setState({ isDraggingOver: true })}
        onDragLeave={(e) => this.setState({ isDraggingOver: false })}
        noClick={true}
        multiple={true}
        accept={{
          "image/*": [],
          "application/pdf": [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
        }}
        maxSize={10000000}
        onDrop={(acceptedFiles, rejectedFiles, event) =>
          this.onDropHandler(acceptedFiles, rejectedFiles, event)
        }
      >
        {({ getRootProps, getInputProps, open, isDragReject }) => (
          <div
            className={`cash-budget-category-detail-entry ${
              entry.data.type === DetailEntryType.COMPARISON_FIX
                ? "fixed-comparison"
                : ""
            } ${entry.data.type === DetailEntryType.MANUAL ? "planned" : ""} ${
              entry.data.type && entry.data.type.indexOf("forecast") !== -1
                ? "forecast"
                : ""
            } ${
              entry.data.type && entry.data.type.indexOf("sharepoint") !== -1
                ? "sharepoint"
                : ""
            } ${categoryChanged ? "category-changed" : ""} dropzone ${
              isDraggingOver ? "dragging" : ""
            }`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div
              className="wrapper"
              ref={this.ref}
              onClick={
                this.props.onSelect
                  ? () => {
                      if (!this.props.expanded) {
                        this.props.onSelect();
                      }
                    }
                  : undefined
              }
            >
              <CashBudgetDetailEntryCardContent
                entry={entry}
                showAccountData={this.props.showAccountData}
                showCategory={this.props.showCategory}
                hideEntityData={this.props.hideEntityData}
                simple={this.props.simple}
                isUploading={this.state.isUploading}
              />

              <Collapse in={this.props.expanded} unmountOnExit={true}>
                <div>
                  {functionalities.length > 0 ? (
                    <>
                      <div className={`action-row`}>
                        {functionalities.indexOf(Functionality.DELETION) !==
                        -1 ? (
                          <ActionComponent
                            params={{ assetData: entry }}
                            // disabled={!!this.state.commentFormVisible}
                            actionId="ACTION_DELETE_ASSET"
                            actionParams={{
                              assetType: `'${this.props.assetType}'`,
                              assetId:
                                "#{assetData} ? #{assetData}._id : undefined",
                              tablesToReload: `['${CASHBUDGET_CATEGORY_DETAIL_TABLE}']`,
                              additionalEvents: [
                                (val) => {
                                  this.clearCache();
                                },
                              ],
                              confirmText: `'${i18n.t(
                                "CategoryDetailsEntry.confirmDeletePlannedBooking",
                                "Wollen Sie die geplante Buchung entgültig löschen?"
                              )}'`,
                            }}
                            appearance="clear-on-white"
                            text={i18n.t("Global.Buttons.delete")}
                          />
                        ) : null}
                        {functionalities.indexOf(Functionality.SPLIT) !== -1 ? (
                          <>
                            <BFDropzone
                              multipe={false}
                              style={{ display: "none" }}
                              onDrop={(acceptedFiles, rejectedFiles, event) => {
                                if (acceptedFiles.length === 1) {
                                  ModalManager.confirm({
                                    title: i18n.t(
                                      "cb:CategoryDetailsEntry.confirmSplitBookingByFile",
                                      "Buchung aufteilen"
                                    ),
                                    message: i18n.t(
                                      "cb:CategoryDetailsEntry.confirmSplitBookingByFileMessage",
                                      "Wollen Sie die Buchung anhand der hochgeladenen XML-Datei aufteilen?"
                                    ),
                                    confirmButtonText: i18n.t(
                                      "cb:CategoryDetailsEntry.confirmSplitBookingByFileConfirm",
                                      "Aufteilen"
                                    ),
                                    onConfirm: () => {
                                      splitBookingWithFile(
                                        this.props.entry._id,
                                        acceptedFiles[0]
                                      ).then(() => {
                                        CashBudgetService.clearFlexCaches();
                                        DataBusDefaults.reload({
                                          identifiers: [
                                            CASHBUDGET_CATEGORY_DETAIL_TABLE,
                                          ],
                                        });
                                      });
                                    },
                                  });
                                }

                                if (rejectedFiles.length > 0) {
                                  rejectedFiles.forEach((file) => {
                                    Log.info(
                                      `Rejected file`,
                                      file.file.name,
                                      file.file.type,
                                      file.file.size,
                                      file.errors
                                        .map((e) => e.message)
                                        .join(" & ")
                                    );
                                  });
                                }
                              }}
                              render={(open) => {
                                this.openFileDialogSepaXML = open;
                                return null;
                              }}
                              accept={{
                                "application/xml": [],
                                "text/xml": [".cct", ".xml"],
                                "": [".cct", ".xml"],
                              }}
                            />

                            <BFDropdown
                              label={i18n.t(
                                "CategoryDetailsEntry.splitBooking",
                                "Aufteilen"
                              )}
                              disabled={
                                this.state.categoryFormVisible ||
                                this.state.objectFormVisible
                              }
                              items={[
                                {
                                  type: "button",
                                  text: i18n.t(
                                    "CategoryDetailsEntry.splitBookingManual",
                                    "Manuell"
                                  ),
                                  onSelect: () => {
                                    DataBus.emit(DataBusSubKeys.ROUTE, {
                                      route: `__splitBooking/${entry._id}`,
                                      append: true,
                                    });
                                  },
                                },
                                {
                                  type: "button",
                                  text: i18n.t(
                                    "CategoryDetailsEntry.splitBookingBySepaXML",
                                    "mit Zahlungsdatei"
                                  ),
                                  onSelect: () =>
                                    this.openFileDialogSepaXML?.(),
                                },
                              ]}
                              renderToggle={(props) => (
                                <BFButton
                                  {...props}
                                  appearance="clear-on-white"
                                >
                                  {i18n.t(
                                    "CategoryDetailsEntry.splitBooking",
                                    "Aufteilen"
                                  )}
                                </BFButton>
                              )}
                            />
                          </>
                        ) : null}
                        {functionalities.indexOf(Functionality.UPLOAD) !==
                        -1 ? (
                          <BFButton
                            disabled={
                              this.state.categoryFormVisible ||
                              this.state.objectFormVisible ||
                              this.state.commentFormVisible
                            }
                            appearance="clear-on-white"
                            onClick={open}
                            loading={this.state.isUploading}
                          >
                            {i18n.t(
                              "CategoryDetailsEntry.uploadAttachment",
                              "Anhang hochladen"
                            )}
                          </BFButton>
                        ) : null}
                        {functionalities.indexOf(
                          Functionality.SEARCH_INVOICE
                        ) !== -1 ? (
                          <BFButton
                            disabled={
                              this.state.categoryFormVisible ||
                              this.state.objectFormVisible ||
                              this.state.commentFormVisible
                            }
                            appearance="clear-on-white"
                            onClick={() =>
                              DataBusDefaults.route({
                                append: true,
                                route: `__link-invoice-to-booking/${entry._id}`,
                              })
                            }
                            loading={this.state.isUploading}
                          >
                            {i18n.t(
                              "CategoryDetailsEntry.searchInvoice",
                              "Beleg suchen"
                            )}
                          </BFButton>
                        ) : null}
                        <div className={`fill`} />{" "}
                        {functionalities.indexOf(Functionality.COMMENT) !==
                        -1 ? (
                          <BFButton
                            className={`${
                              this.state.commentFormVisible ? "toggled" : ""
                            }`}
                            disabled={
                              this.state.categoryFormVisible ||
                              this.state.objectFormVisible
                            }
                            appearance="clear-on-white"
                            onClick={() =>
                              this.setState({
                                commentFormVisible:
                                  !this.state.commentFormVisible,
                              })
                            }
                          >
                            {i18n.t(
                              "CategoryDetailsEntry.comment",
                              "Kommentieren"
                            )}
                          </BFButton>
                        ) : null}
                        {functionalities.indexOf(
                          Functionality.CATEGORY_CHANGE
                        ) !== -1 ? (
                          <BFButton
                            className={`${
                              this.state.categoryFormVisible ? "toggled" : ""
                            }`}
                            disabled={
                              this.state.commentFormVisible ||
                              this.state.objectFormVisible
                            }
                            appearance="clear-on-white"
                            onClick={() =>
                              this.setState({
                                categoryFormVisible:
                                  !this.state.categoryFormVisible,
                              })
                            }
                          >
                            {i18n.t(
                              "CategoryDetailsEntry.changeCategory",
                              "Kategorie wechseln"
                            )}
                          </BFButton>
                        ) : null}
                        {functionalities.indexOf(
                          Functionality.OBJECT_CHANGE
                        ) !== -1 ? (
                          <BFButton
                            className={`${
                              this.state.objectFormVisible ? "toggled" : ""
                            }`}
                            disabled={
                              this.state.commentFormVisible ||
                              this.state.categoryFormVisible
                            }
                            appearance="clear-on-white"
                            onClick={() =>
                              this.setState({
                                objectFormVisible:
                                  !this.state.objectFormVisible,
                                selectedFilter: entry.data.objectId,
                              })
                            }
                          >
                            {i18n.t(
                              "CategoryDetailsEntry.changeObject",
                              "Objekt wechseln"
                            )}
                          </BFButton>
                        ) : null}
                      </div>

                      {functionalities.indexOf(Functionality.COMMENT) !== -1 &&
                        this.renderCommentForm()}
                      {functionalities.indexOf(
                        Functionality.CATEGORY_CHANGE
                      ) !== -1 && this.renderCategoryForm()}
                      {functionalities.indexOf(Functionality.OBJECT_CHANGE) !==
                        -1 && this.renderObjectForm()}
                    </>
                  ) : null}
                </div>
              </Collapse>

              {categoryChanged ? (
                <div className={`category-changed-overlay`}>
                  <div>
                    {i18n.t(
                      "CategoryDetailsEntry.categoryHasBeenChanged",
                      "Wurde umkategorisiert"
                    )}
                  </div>
                  <BFButton
                    onClick={() => this.submitCategory(this.props.category)}
                  >
                    {i18n.t("Global.Buttons.reset")}
                  </BFButton>
                </div>
              ) : null}

              <div
                className={`overlay-drop-indicator ${
                  isDragReject ? "reject" : ""
                }`}
              >
                <BfIcon type="bf" data="upload-bottom" />
              </div>
            </div>
          </div>
        )}
      </Dropzone>

      // <div className={`cash-budget-category-detail-entry ${entry.plannedBy ? "planned" : ""}`}>
    );
  }

  renderCategoryForm() {
    const { expanded, categoryFormOtherCategories } = this.state;
    const { entry } = this.props;
    // const otherCategories =
    // 	this.props.type === "POSITIVE" ? CashBudgetService.getPositiveCategories() : CashBudgetService.getNegativeCategories();

    //  const isForecast =  entry.data.type.indexOf("forecast") !== -1

    const categoryType = CashBudgetUtils.getAllCategories()?.find(
      (e) => e._id === entry.data.category
    )?.data.kind;
    const availableCategories = CashBudgetUtils.getAllCategories()
      ?.filter((e) =>
        categoryFormOtherCategories
          ? e.data.kind !== categoryType
          : e.data.kind === categoryType
      )
      .filter((e) => (e.data.tags || []).indexOf("group") === -1)
      .filter((e) => e._id !== entry.data.category);
    return (
      <Collapse
        in={this.state.categoryFormVisible}
        unmountOnExit={true}
        onEntered={() =>
          this.ref.current
            .querySelector(".category-form .rs-picker-input")
            .click()
        }
      >
        <div>
          <div className={`category-form`}>
            <div className={`description`}>
              {i18n.t(
                "CategoryDetailsEntry.categoryChangeForm.Description",
                "Bitte wählen Sie die Kategorie aus, in welche Sie die Buchung umkategorisieren wollen. Bitte bedenken Sie, dass die Umkategorisierung sich auf die Prognose auswirken kann."
              )}
            </div>
            <div className={`showOtherCategory`}>
              <BFCheckbox
                checked={categoryFormOtherCategories}
                onChange={(val, checked) =>
                  this.setState({
                    categoryFormOtherCategories: checked,
                    selectedCategory: null,
                  })
                }
              >
                {i18n.t(
                  "CategoryDetailsEntry.categoryChangeForm.showReverseCategory",
                  "Kategorien für Gegenbuchung anzeigen"
                )}
              </BFCheckbox>
            </div>
            <BFSelect
              data={availableCategories.map((e) => ({
                value: e._id,
                label: LanguageService.translateLabel(e.data.displayName),
              }))}
              value={this.state.selectedCategory}
              onChange={(val) => this.setState({ selectedCategory: val })}
            />
            <div className={`category-actions`}>
              <BFButton
                onClick={() =>
                  this.setState({
                    categoryFormOtherCategories: false,
                    categoryFormVisible: false,
                    selectedCategory: null,
                  })
                }
              >
                <Trans i18nKey="Global.Buttons.cancel" />
              </BFButton>
              <BFButton
                onClick={() => this.submitCategory(this.state.selectedCategory)}
                loading={this.state.submitLoading}
                disabled={this.state.selectedCategory === null}
                appearance="primary"
              >
                {i18n.t("Global.Buttons.submit")}
              </BFButton>
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
  renderCommentForm() {
    return (
      <CBBookingCommentForm
        commentFormVisible={this.state.commentFormVisible}
        entry={this.props.entry}
        onFormClose={() => {
          this.setState({ commentFormVisible: false });
        }}
        assetType={this.props.assetType}
        tableToUpdate={this.props.tableToUpdate}
      />
    );
  }

  renderObjectForm() {
    const { expanded } = this.state;
    const { entry } = this.props;
    // const otherCategories =
    // 	this.props.type === "POSITIVE" ? CashBudgetService.getPositiveCategories() : CashBudgetService.getNegativeCategories();

    const usedEntity = CashBudgetUtils.getAllEntities()?.find((e) =>
      e.banks.find((acc) => acc._id === entry.data.bankAccount)
    );
    const options = usedEntity.objects.map((e) => ({
      value: e.id,
      label: e.displayName,
    }));

    const categoryType = CashBudgetUtils.getAllCategories()?.find(
      (e) => e._id === entry.data.category
    ).data.kind;
    return (
      <Collapse
        in={this.state.objectFormVisible}
        unmountOnExit={true}
        onEntered={() =>
          this.ref.current
            .querySelector(".object-form .rs-picker-input")
            .click()
        }
      >
        <div>
          <div className={`object-form`}>
            <div className={`description`}>
              {i18n.t(
                "CategoryDetailsEntry.objectForm.description",
                "Bitte wählen Sie das Objekt aus, welchem Sie die Buchung zuweisen wollen:"
              )}
            </div>
            <BFSelect
              data={options}
              value={this.state.selectedFilter}
              onChange={(val) => this.setState({ selectedFilter: val })}
            />
            <div className={`object-actions`}>
              <BFButton
                onClick={() =>
                  this.setState({
                    objectFormVisible: false,
                    selectedFilter: null,
                  })
                }
              >
                <Trans i18nKey="Global.Buttons.cancel" />
              </BFButton>
              <BFButton
                onClick={() => this.submitObject(this.state.selectedFilter)}
                loading={this.state.submitLoading}
                disabled={this.state.selectedFilter === null}
                appearance="primary"
              >
                {i18n.t("Global.Buttons.submit")}
              </BFButton>
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    userId: state.global.user._id,
    userDisplayname: state.global.user.displayname,
    debug: state.uiConfig.general[DefaultUIConfigs.DEBUG],
  };
};

export default connect(mapStateToProps, { clearFlexCacheData })(
  CashBudgetCategoryDetailEntry
) as any;
