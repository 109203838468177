import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import i18n from "../../i18n";
import BFDropzone from "../../modules/abstract-ui/dropzone/BFDropzone";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../modules/abstract-ui/icon/DefaultIcons";
import CDNService from "../../services/CDNService";
import DataBusDefaults from "../../services/DataBusDefaults";
import FilePreviewComponent from "../FilePreviewComponent/FilePreviewComponent";
import "./AssetImageUploadDialog.scss";

interface AssetImageUploadDialogProps {
  assetId: string;
  assetType: string;
  assetField: string;
  files: File[];
  onClose: () => void;
  onSuccess: () => void;
}
const AssetImageUploadDialog = (props: AssetImageUploadDialogProps) => {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState(props.files);
  const finishCount = useRef(0);
  useEffect(() => {
    if (files.length === 0) {
      props.onClose();
    }
  }, [files]);

  const startUpload = async () => {
    setUploading(true);
  };

  return (
    <BFDropzone
      onDrop={(acceptedFiles, rejectedFiles, event) => {
        if (uploading) {
          DataBusDefaults.toast({
            type: "warning",
            text: i18n.t(
              "AssetImages.UploadDialog.CantAddImageWhileUploading",
              "Während Fotos hochageladen werden, können Sie keine weiteren Bilder hinzufügen"
            ),
          });
          return;
        }
        if (acceptedFiles.length > 0) {
          setFiles([...files, ...acceptedFiles]);
        }
      }}
      multipe
      accept={{
        "image/*": [],
      }}
      render={(open) => (
        <div className={classNames(`asset-image-upload-dialog`)}>
          <div className={`title`}>
            {i18n.t(
              "AssetImages.UploadDialog.Title",
              "Wollen Sie die folgenden Bilder hochladen?"
            )}
          </div>
          <div className={`images`}>
            {files.map((file, index) => (
              <AssetImageUpload
                assetField={props.assetField}
                assetId={props.assetId}
                assetType={props.assetType}
                uploading={uploading}
                key={file.name}
                file={file}
                onUploaded={() => {
                  finishCount.current++;
                  if (finishCount.current === files.length) {
                    props.onSuccess();
                  }
                }}
                onDelete={() => {
                  setFiles(files.filter((_, i) => i !== index));
                }}
              />
            ))}
            {!uploading && (
              <BFButton
                appearance="outline"
                onClick={open}
                className={`upload-button`}
              >
                <BfIcon size="2x" {...DefaultIcons.ADD} />
              </BFButton>
            )}
          </div>
          <div className={`actions`}>
            <BFButton
              disabled={uploading}
              appearance="outline"
              onClick={props.onClose}
            >
              {i18n.t("Global.Buttons.cancel")}
            </BFButton>
            <BFButton
              appearance="primary"
              loading={uploading}
              onClick={startUpload}
            >
              {i18n.t("Global.Buttons.upload")}
            </BFButton>
          </div>
        </div>
      )}
    />
  );
};

export default AssetImageUploadDialog;

const AssetImageUpload = (props: {
  assetId: string;
  assetType: string;
  assetField: string;
  file: File;
  onDelete: () => void;
  onUploaded: () => void;
  uploading: boolean;
}) => {
  const [cdnTempId] = useState(nanoid());
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (props.uploading) {
      CDNService.uploadAttachment(
        props.assetId,
        {
          file: props.file,
          name: File.name,
        },
        props.assetType,
        props.assetField,
        cdnTempId,
        (progress) => {
          setProgress(progress);
          if (progress === 1) {
            props.onUploaded();
          }
        }
      );
    }
  }, [props.uploading]);
  return (
    <div className={`image-container`}>
      <div className={`image`}>
        <FilePreviewComponent
          type="file"
          file={props.file}
          width={150}
          height={150}
        />
      </div>
      {props.uploading && (
        <div className={`uploading-overlay`}>
          {progress < 1 && <Loader size="md" />}
          {progress >= 1 && (
            <BfIcon
              className={`finish-icon`}
              type="light"
              data="check"
              size="2x"
            />
          )}
          <div className={`progress`} style={{ width: `${progress * 100}%` }} />
        </div>
      )}
      {!props.uploading && (
        <BFButton
          appearance="outline"
          // noPadding
          size="xs"
          className={`remove-button`}
          onClick={props.onDelete}
        >
          <BfIcon size="xxs" {...DefaultIcons.CLOSE} />
        </BFButton>
      )}
    </div>
  );
};
