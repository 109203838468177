import { HttpCache, useHttpCache } from "@/redux/hooks";
import { isDefined } from "@/utils/Helpers";
import { AccountingAccount } from "./interfaces/account.interface";

export type AccountingNotFound = {
  status: 404;
  code: "ID_NOT_FOUND";
};
export const useAccounting = (entity: string) => {
  const httpResult = useHttpCache<
    (AccountingAccount | AccountingNotFound) & { reload: () => Promise<void> }
  >(
    `accounting-${entity}`,
    `api/accounting/${entity}/getAccountingConfig`,
    "get"
  );
  return {
    ...httpResult,
    data:
      isDefined(httpResult.data) &&
      (httpResult.data as AccountingNotFound).status === 404
        ? null
        : httpResult.data,
  } as HttpCache<AccountingAccount | null> & { reload: () => Promise<void> };
};
