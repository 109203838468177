import i18n from "@/i18n";
import { getRestrictions } from "@/services/RestrictionService";
import { hasNoValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

export type EntityModel = {
  _id: string;
  id: string;
  entityName: string;
  displayName: string;
  type: string; // unit
  objects: ObjectModel[];
  banks: BankAccountModel[];
  usesAccounting?: boolean;
};
export type ObjectModel = {
  _id: string;
  bankInfo: {
    mainBankAccount: string;
    outgoingInvoiceBankAccount?: string;
  };
  displayName: string;
  end: Date;
  start: Date;
  id: string;
  objectKindId: string;
  status: "active" | "archived";
};
export type BankAccountModel = {
  _id: string;
  account: string;
  bankName: string;
  blz: string;
  displayName: string;
  iban: string;
  startBalance: number;
  objects: string[];
};
class OrgaStructClass extends AbstractStructSelectors<EntityModel[]> {
  getStructType(): StructType {
    return "orga";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<EntityModel[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/liquiplanservice/getObjectStructure`,
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as EntityModel[];
        const result: DataByUnitType<EntityModel[]> = {};
        types.forEach((type) => {
          result[type] = data?.filter((config) => config.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  getEntities(type?: string | string[], considerRestrictions = false) {
    return this.useCache<EntityModel[]>("getEntities", arguments, () => {
      const entities = this.getAllData()
        ?.flat()
        .filter((e) =>
          hasNoValue(type)
            ? true
            : Array.isArray(type)
            ? type.includes(e.type)
            : e.type === type
        );

      if (considerRestrictions) {
        const restrictions = getRestrictions();
        if (restrictions) {
          return (
            entities?.filter((e) =>
              restrictions.dependencies.entities.includes(e._id)
            ) || []
          );
        }
      }

      return entities;
    });
  }

  getEntity(id: string): EntityModel {
    return this.useCache<EntityModel>("getEntity", arguments, () => {
      const allData = this.getAllData();
      for (const dataOfUnit of allData) {
        const entity = dataOfUnit.find((e) => e._id === id);
        if (entity) {
          return entity;
        }
      }
    });
  }
  getEntitySelectOptions(
    type?: string | string[],
    considerRestrictions = false
  ) {
    return this.useCache<{ label: string; value: string }[]>(
      "getEntitySelectOptions",
      arguments,
      () =>
        this.getEntities(type, considerRestrictions).map((entity) => ({
          label: entity.displayName,
          value: entity._id,
        }))
    );
  }

  getAllBankAccounts(types?: string[], considerRestrictions = false) {
    return this.useCache<
      (BankAccountModel & { entityId: string; type: string })[]
    >("getAllBankAccounts", arguments, () => {
      const bankAccounts = this.getAllData()
        .flat()
        .reduce(
          (prev, current) => [
            ...prev,
            ...(current.banks?.map((object) => ({
              ...object,
              entityId: current._id,
              type: current.type,
            })) || []),
          ],
          []
        )
        .filter((e) => (hasNoValue(types) ? true : types.includes(e.type)));

      if (considerRestrictions) {
        const restrictions = getRestrictions();
        if (restrictions) {
          return (
            bankAccounts?.filter((e) =>
              restrictions.bankAccounts.includes(e._id)
            ) || []
          );
        }
      }
      return bankAccounts;
    });
  }
  getBankAccount(
    id: string
  ): BankAccountModel & { entityId: string; type: string } {
    return this.useCache<BankAccountModel & { entityId: string; type: string }>(
      "getBankAccount",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const dataOfUnit of allData) {
          for (const entity of dataOfUnit) {
            const account = entity.banks.find((a) => a._id === id);
            if (account) {
              return { ...account, entityId: entity._id, type: entity.type };
            }
          }
        }
      }
    );
  }

  getBankAccountsOfEntity(entityId: string, considerRestrictions = false) {
    return this.useCache<
      (BankAccountModel & { entityId: string; type: string })[]
    >("getBankAccountsOfEntity", arguments, () => {
      return this.getAllBankAccounts(undefined, considerRestrictions).filter(
        (e) => e.entityId === entityId
      );
    });
  }

  getObject(id: string): ObjectModel & { entityId: string; type: string } {
    return this.useCache<ObjectModel & { entityId: string; type: string }>(
      "getObject",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const dataOfUnit of allData) {
          for (const entity of dataOfUnit) {
            const object = entity.objects.find((o) => o._id === id);
            if (object) {
              return { ...object, entityId: entity._id, type: entity.type };
            }
          }
        }
      }
    );
  }
  getBankAccountSelectOptions(entityId: string, considerRestrictions = false) {
    return this.useCache<{ label: string; value: string; subLabel?: any }[]>(
      "getBankAccountSelectOptions",
      arguments,
      () =>
        this.getBankAccountsOfEntity(entityId, considerRestrictions).map(
          (bankAccount) => ({
            label: bankAccount.displayName,
            subLabel:
              bankAccount.objects.length === 0 ? (
                StringUtils.formatIban(bankAccount.iban)
              ) : (
                <>
                  {bankAccount.objects.length === 1
                    ? this.getObject(bankAccount.objects[0])?.displayName
                    : i18n.t("Base.CountAssets", "{{count}} Assets", {
                        count: bankAccount.objects.length,
                      })}
                  <br />
                  {StringUtils.formatIban(bankAccount.iban)}
                </>
              ),
            value: bankAccount._id,
          })
        )
    );
  }

  getEntityOfUnit(unit: string, id: string): EntityModel {
    return this.useCache<EntityModel>("getEntityOfUnit", arguments, () =>
      this.getData(unit).find((e) => e._id === id)
    );
  }
  getEntitiesByObjects(objectIds: string[], considerRestrictions = false) {
    return this.useCache<EntityModel[]>(
      "getEntitiesByObjects",
      arguments,
      () => {
        return this.getEntities(null, considerRestrictions).filter((e) =>
          e.objects?.some((object) => objectIds.includes(object._id))
        );
      }
    );
  }
  getObjectsByEntities(entityIds: string[], considerRestrictions = false) {
    return this.useCache<ObjectModel[]>("getObjectsByEntities", arguments, () =>
      this.getAllObjects(undefined, considerRestrictions).filter((object) =>
        entityIds.includes(object.entityId)
      )
    );
  }
  getAllObjects(type?: string[], considerRestrictions = false) {
    return this.useCache<(ObjectModel & { entityId: string; type: string })[]>(
      "getAllObjects",
      arguments,
      () => {
        const objects = this.getAllData()
          ?.flat()
          .reduce(
            (prev, current) => [
              ...prev,
              ...(current.objects?.map((object) => ({
                ...object,
                entityId: current._id,
                type: current.type,
              })) || []),
            ],
            []
          )
          .filter((e) => (hasNoValue(type) ? true : type.includes(e.type)));

        if (considerRestrictions) {
          const restrictions = getRestrictions();
          if (restrictions) {
            return (
              objects?.filter((e) => restrictions.lqObjects.includes(e._id)) ||
              []
            );
          }
        }

        return objects;
      }
    );
  }

  getObjectsOfEntity(entityId: string, considerRestrictions?: boolean) {
    return this.useCache<(ObjectModel & { entityId: string; type: string })[]>(
      "getObjectsOfEntity",
      arguments,
      () => {
        const entity = this.getAllData()
          .flat()
          .find((entity) => entity._id === entityId);
        const restrictions = getRestrictions();
        return entity?.objects
          .map((object) => ({
            ...object,
            entityId: entity._id,
            type: entity.type,
          }))
          .filter((e) =>
            considerRestrictions && restrictions
              ? restrictions.lqObjects.includes(e._id)
              : true
          );
      }
    );
  }

  getAllObjectsByBankAccount(accountId: string, considerRestrictions = false) {
    return this.useCache<ObjectModel[]>(
      "getAllObjectsByBankAccount",
      arguments,
      () =>
        this.getAllObjects(undefined, considerRestrictions).filter(
          (object) => object.bankInfo?.mainBankAccount === accountId
        )
    );
  }
  findEntityByName(name: string) {
    return this.useCache<EntityModel>("findEntityByName", arguments, () =>
      this.getAllData()
        .flat()
        .find(
          (entity) =>
            entity.displayName.trim().toLowerCase() ===
            name.trim().toLowerCase()
        )
    );
  }

  getObjectSelectOptions(entityId?: string, type?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getObjectSelectOptions",
      arguments,
      () => {
        let objects;
        if (entityId) {
          objects = this.getObjectsOfEntity(entityId);
        } else {
          objects = this.getAllObjects(type);
        }

        return (
          objects?.map((object) => ({
            label: `${object.id} - ${object.displayName}`,
            value: object._id,
          })) || []
        );
      }
    );
  }
  getObjectSelectOptionsOfKind(kind: string) {
    return this.useCache<{ label: string; value: string }[]>(
      "getObjectSelectOptions",
      arguments,
      () => {
        const objects = this.getAllObjects().filter(
          (e) => e.objectKindId === kind
        );

        return (
          objects?.map((object) => ({
            label: `${object.id} - ${object.displayName}`,
            value: object._id,
          })) || []
        );
      }
    );
  }

  filterObjectsBy(units: string[], entities: string[], objects: string[]) {
    return this.useCache<string[]>("filterObjectsBy", arguments, () => {
      const objectIds =
        this.getAllObjects()
          ?.map((object) => object._id)
          .filter((id) => objects.includes(id)) || [];
      const objectIdsByIds = this.getObjectsByEntities(entities)?.map(
        (object) => object._id
      );
      const objectIdsByUnits = this.getAllObjects()
        .filter((obj) => units.includes(obj.type))
        .map((e) => e._id);

      return _.uniq([...objectIds, ...objectIdsByIds, ...objectIdsByUnits]);
    });
  }

  filterEntitiesBy(units: string[], entities: string[]) {
    return this.useCache<string[]>("filterEntitiesBy", arguments, () => {
      const entityIds =
        this.getEntities()
          ?.map((entity) => entity._id)
          .filter((id) => entities.includes(id)) || [];
      const entityIdsByUnits = this.getEntities()
        .filter((entity) => units.includes(entity.type))
        .map((e) => e._id);

      return _.uniq([...entityIds, ...entityIdsByUnits]);
    });
  }
}
const OrgaStruct = new OrgaStructClass();
(window as any).OrgaStruct = OrgaStruct;
export default OrgaStruct;
