import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ContactSelect from "@/modules/contacts-module/ContactSelect";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import _ from "lodash";
import { useState } from "react";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFFormSection from "../../../../../../../modules/abstract-ui/data/form-section/BFFormSection";
import BFDate from "../../../../../../../modules/abstract-ui/forms/date/BFDate";
import BFInputTable, {
  BFInputTableColumn,
} from "../../../../../../../modules/abstract-ui/forms/input-table/BFInputTable";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../../../../../../modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../../../../../../modules/abstract-ui/forms/radio/BfRadio";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import EZAssetSelectMultiple from "../../../../../../../modules/ez-form/form-elements/ez-asset-select/EZAssetSelectMultiple";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import LanguageService from "../../../../../../../services/LanguageService";
import NumberUtils from "../../../../../../../utils/ NumberUtils";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../../../activityApp/ActivityHooks";
import CBRentalService from "../../CBRentalService";
import {
  CB_RENTAL_AGREEMENT_LIST,
  getRentPaymentTimingOption,
} from "../../CBTenantsConst";
import {
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalUnit,
} from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import "./CBRentalAgreementForm.scss";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import CBRentalAgreementDepositForm, {
  CBRentalAgreementDepositButton,
} from "./form-components/CBRentalAgreementDepositForm";
import CBRentalAgreementNoteForm, {
  CBRentalAgreementNoteButton,
} from "./form-components/CBRentalAgreementNoteForm";

interface Props {
  kind: ObjectKind;
  rentalAgreement?: RentalAgreement;
  forObject?: string;
  onClose: () => void;
}
const CBRentalAgreementForm = (props: Props) => {
  const [initialValue] = useState({
    type: props.kind?.data.type,
    rentPaymentTimingOptions: "startOfMonth",
    ...(props.forObject
      ? {
          objectId: props.forObject,
          entity: OrgaStruct.getObject(props.forObject)?.entityId,
          type: OrgaStruct.getObject(props.forObject)?.type,
        }
      : {}),
    note: null,
    // deposits: null,
    helpers: {},
    createTenant: {
      personType: "organization",
    },
  });
  const constants = useActivityConstants();

  return (
    <FormStruct
      className={"cb-rental-agreement-form"}
      submitText={
        props.rentalAgreement
          ? i18n.t("Global.Buttons.submit")
          : i18n.t("Global.Buttons.add")
      }
      title={
        props.rentalAgreement
          ? i18n.t(
              "cb:RentalAgreement.Form.EditTitle",
              "Mietvertrag bearbeiten"
            )
          : i18n.t("cb:RentalAgreement.Form.AddTitle", "Mietvertrag hinzufügen")
      }
      description={
        props.rentalAgreement
          ? i18n.t(
              "cb:RentalAgreement.Form.EditDescription",
              "Bearbeiten Sie die Daten des Mietvertrages"
            )
          : i18n.t(
              "cb:RentalAgreement.Form.AddDescription",
              "Füllen Sie die Daten des Mietvertrages aus"
            )
      }
      initialValues={initialValue}
      onSubmit={async (values) => {
        // todo submit data
        const result = await CBRentalService.submitRentalAgreement(
          values,
          props.rentalAgreement
        );

        DataBusDefaults.reload({
          identifiers: [CB_RENTAL_AGREEMENT_LIST],
        });

        props.onClose();
      }}
      onAbort={props.onClose}
      render={({ form }) => {
        return (
          <>
            <div className={`form-wrapper`}>
              <div className={`left __flex-1`}>
                <BFFormSection
                  initialOpen
                  marginBottom={20}
                  title={i18n.t(
                    "cb:RentalAgreement.Form.Sections.contractor",
                    "Vertragsgesellschaft"
                  )}
                >
                  <div>
                    {/* {typeOptions.length !== 1 && (
                      <div className={`__field`}>
                        <Field
                          name="type"
                          validate={FormValidators.compose(
                            FormValidators.required()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFSelect
                              {...input}
                              label={`${i18n.t(
                                "cb:RentalAgreement.Form.Fields.type",
                                "Bereich"
                              )}*`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              data={typeOptions}
                              onChange={(value) => {
                                input.onChange(value);
                                form.mutators.setValue("entity", null);
                                form.mutators.setValue("objectId", null);
                                form.mutators.setValue("rentalUnits", []);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    )} */}

                    <div className={`__field`}>
                      <FormFieldValues names={["type"]}>
                        {([type]) => (
                          <Field
                            name="entity"
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                          >
                            {({ input, meta }) => (
                              <BFSelect
                                {...input}
                                disabled={!type}
                                label={`${i18n.t(
                                  "cb:RentalAgreement.Form.Fields.entity",
                                  "Gesellschaft"
                                )}*`}
                                validation={
                                  meta.error && meta.touched
                                    ? { level: "error", message: meta.error }
                                    : undefined
                                }
                                data={OrgaStruct.getEntitySelectOptions(type)}
                                onChange={(value) => {
                                  input.onChange(value);
                                  form.mutators.setValue("objectId", null);
                                  form.mutators.setValue("rentalUnits", []);
                                }}
                              />
                            )}
                          </Field>
                        )}
                      </FormFieldValues>
                    </div>

                    <div className={`__field`}>
                      <FormFieldValues names={["entity"]}>
                        {([entity]) => (
                          <Field
                            name="objectId"
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                          >
                            {({ input, meta }) => (
                              <BFSelect
                                {...input}
                                disabled={!entity}
                                label={`${i18n.t(
                                  "cb:RentalAgreement.Form.Fields.objectId",
                                  "Objekt"
                                )}*`}
                                validation={
                                  meta.error && meta.touched
                                    ? { level: "error", message: meta.error }
                                    : undefined
                                }
                                data={
                                  entity
                                    ? OrgaStruct.getObjectSelectOptions(entity)
                                    : []
                                }
                                onChange={(value) => {
                                  input.onChange(value);
                                  // form.mutators.setValue("rentalUnits", []);
                                }}
                              />
                            )}
                          </Field>
                        )}
                      </FormFieldValues>
                    </div>
                  </div>
                </BFFormSection>
                <BFFormSection
                  initialOpen
                  title={i18n.t(
                    "cb:RentalAgreement.Form.Sections.Beneficiary",
                    "Vertragspartner"
                  )}
                >
                  <FormFieldValues names={["type"]}>
                    {([type]) => (
                      <div>
                        <div className={`__field`}>
                          <Field name="tenant">
                            {({ input, meta }) => (
                              <ContactSelect
                                businessUnits={[type]}
                                contactTypes={["TENANT"]}
                                onChange={(value) => {
                                  setTimeout(() => {
                                    input.onChange(value);
                                  });
                                }}
                                value={input.value}
                                // label={
                                //   direction === "INCOMING"
                                //     ? RA_INVOICE_MAIN_FIELDS().contact.label + "*"
                                //     : i18n.t(
                                //         "ra:InvoiceForm.BillReceiver",
                                //         "Rechnungsempfänger"
                                //       ) + "*"
                                // }
                                {...FormValidators.getValidation(meta)}
                                placeholder={i18n.t(
                                  "cb:RentalAgreement.Form.Fields.tenantPlaceholder",
                                  "Vertragspartner auswählen"
                                )}
                                addText={i18n.t(
                                  "cb:RentalAgreement.Form.Fields.createTenant",
                                  "Vertragspartner erstellen"
                                )}
                                initialCreateObj={
                                  {
                                    data: {
                                      // ...initialData,
                                      type: type,
                                      contactType: ["TENANT"],
                                    },
                                  } as any
                                }
                                {...FormValidators.getValidation(meta)}
                              />

                              // <EZAssetSelect
                              //   {...input}
                              //   block
                              //   additionalPanel={(context, onClose) => (
                              //     <div>Test hier</div>
                              //   )}
                              //   identifier="cb-rental-agreement-tenant-select"
                              //   disabled={!type}
                              //   appearance="bf"
                              //   validation={
                              //     meta.error && meta.touched
                              //       ? {
                              //           level: "error",
                              //           message: meta.error,
                              //         }
                              //       : undefined
                              //   }
                              //   assetTypes={[
                              //     {
                              //       id: "tenants",
                              //       label: i18n.t(
                              //         "cb:AssetTypes.Rental.Tenant",
                              //         "Mieter"
                              //       ),
                              //       renderValue: (asset: Contact) => {
                              //         return (
                              //           <div className={`assign-tenant-entry`}>
                              //             {/* <div>{asset._id}</div>
                              //           <div> */}
                              //             {asset.data.displayName}
                              //             {/* </div> */}
                              //           </div>
                              //         );
                              //       },
                              //       assetType: AssetTypes.Contact,
                              //       matchQuery: MQ.combine("and", [
                              //         MQ.eq("data.type", type),
                              //         MQ.eq<ContactType>(
                              //           "data.contactType",
                              //           "TENANT"
                              //         ),
                              //       ]),
                              //     },
                              //   ]}
                              // />
                            )}
                          </Field>
                        </div>
                      </div>
                    )}
                  </FormFieldValues>
                </BFFormSection>
              </div>
              <div className={`right __flex-2`}>
                <BFFormSection
                  initialOpen
                  title={i18n.t(
                    "cb:RentalAgreement.Form.Sections.RentalUnits",
                    "Mieteinheiten"
                  )}
                  marginBottom={20}
                >
                  <div>
                    <FormFieldValues names={["objectId", "type"]}>
                      {([objectId, type]) => {
                        const options =
                          ObjectKindStruct.getRentalUnitAssetSelectionOptions(
                            {
                              renderValue: (asset: RentalUnit) => {
                                return (
                                  <RentalUnitTableEntry rentalUnit={asset} />
                                );
                              },
                            },
                            props.kind._id
                          );
                        return (
                          <div className={`__field`}>
                            {!objectId && (
                              <div className={`__empty`}>
                                {i18n.t(
                                  "cb:RentalAgreement.Form.Sections.NoObjectsSelected",
                                  "Kein Objekt ausgewählt"
                                )}
                              </div>
                            )}

                            {objectId && (
                              <Field
                                name="rentalUnits"
                                validate={FormValidators.compose(
                                  FormValidators.required(),
                                  FormValidators.min(1)
                                )}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <div className={`stacking-plan`}>
                                      <ObjectStackingPlan
                                        objectId={objectId}
                                        selectMode={"multiple"}
                                        onSelect={(
                                          units: EnrichtedRentalUnit[]
                                        ) => {
                                          if (
                                            !_.isEqual(
                                              input.value?.map(
                                                (e) => e.assetId
                                              ),
                                              units.map((e) => e._id)
                                            )
                                          ) {
                                            input.onChange(
                                              units.map((e) => ({
                                                assetId: e._id,
                                                assetType:
                                                  AssetTypes.Rental.RentalUnit,
                                              }))
                                            );
                                          }
                                        }}
                                        selected={input.value.map(
                                          (e) => e.assetId
                                        )}
                                      />
                                    </div>
                                    <RentalUnitTableHeader />
                                    {(input.value || []).length === 0 && (
                                      <div className={`__empty`}>
                                        {i18n.t(
                                          "cb:RentalAgreement.Form.Fields.noRentalUnits",

                                          "Keine Mieteinheiten ausgewählt"
                                        )}
                                      </div>
                                    )}
                                    <EZAssetSelectMultiple
                                      disabled={!objectId || !type}
                                      {...input}
                                      initialAssetType={options[0].id}
                                      block
                                      identifier="cb-rental-agreement-rental-unit-select"
                                      addButtonText={i18n.t(
                                        "cb:RentalAgreement.Form.Fields.addRentalUnit",
                                        "Mieteinheit hinzufügen"
                                      )}
                                      appearance="clear"
                                      validation={
                                        meta.error && meta.touched
                                          ? {
                                              level: "error",
                                              message: meta.error,
                                            }
                                          : undefined
                                      }
                                      additionalMatchQuery={MQ.combineSpread(
                                        "and",
                                        {
                                          type: "op",
                                          op: "eq",
                                          name: "data.type",
                                          value: type,
                                        },
                                        objectId
                                          ? {
                                              type: "op",
                                              op: "eq",
                                              name: "data.objectId",
                                              value: objectId,
                                            }
                                          : null
                                      )}
                                      assetTypes={options}
                                    />
                                  </>
                                )}
                              </Field>
                            )}
                          </div>
                        );
                      }}
                    </FormFieldValues>
                  </div>
                </BFFormSection>
                <BFFormSection
                  initialOpen
                  title={i18n.t(
                    "cb:RentalAgreement.Form.Sections.RentalAgreementData",
                    "Vertragsdaten"
                  )}
                >
                  <div>
                    <div className="field-row">
                      <div className={`__field __flex-1`}>
                        <Field
                          name="id"
                          validate={FormValidators.compose(
                            FormValidators.required()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              label={`${i18n.t(
                                "cb:RentalAgreement.Form.Fields.ID",
                                "ID"
                              )}*`}
                              {...input}
                              {...FV.getValidation(meta)}
                              // placeholder={i18n.t(
                              //   "cb:RentalAgreement.Form.Fields.willBeGenerated",
                              //   "wird generiert"
                              // )}
                              // readonly
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__field __flex-3`}>
                        <Field
                          name="displayName"
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              label={`${i18n.t(
                                "cb:RentalAgreement.Form.Fields.displayName",
                                "Anzeigename"
                              )}*`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="field-row">
                      <div className={`__field __flex-1`}>
                        <Field
                          name="moveIn"
                          validate={FormValidators.compose(
                            FormValidators.required()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFDate
                              label={`${i18n.t(
                                "cb:RentalAgreement.Form.Fields.moveIn",
                                "Mietbeginn"
                              )}*`}
                              oneTap
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                      <FormFieldValues names={["moveIn"]}>
                        {([moveIn]) => (
                          <div className={`__field __flex-1`}>
                            <Field
                              name="agreementExpiration"
                              validate={FormValidators.compose(
                                FormValidators.dateAfter(moveIn)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFDate
                                  label={i18n.t(
                                    "cb:RentalAgreement.Form.Fields.agreementExpiration",
                                    "Vertrag bis"
                                  )}
                                  oneTap
                                  validation={
                                    meta.error && meta.touched
                                      ? { level: "error", message: meta.error }
                                      : undefined
                                  }
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </FormFieldValues>
                      <FormFieldValues names={["moveIn"]}>
                        {([moveIn]) => (
                          <div className={`__field __flex-1`}>
                            <Field
                              name="moveOut"
                              validate={FormValidators.compose(
                                FormValidators.dateAfter(moveIn)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFDate
                                  label={i18n.t(
                                    "cb:RentalAgreement.Form.Fields.moveOut",
                                    "Auszug"
                                  )}
                                  oneTap
                                  validation={
                                    meta.error && meta.touched
                                      ? { level: "error", message: meta.error }
                                      : undefined
                                  }
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </FormFieldValues>
                    </div>
                    <div className={`section-title __h3  margin-top-10`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.SectionTitle.PaymentData",
                        "Zahldaten"
                      )}
                    </div>

                    <div className={`field-row`}>
                      <div className={`__field`}>
                        <Field name="taxable">
                          {({ input, meta }) => (
                            <BFRadioGroup
                              inline
                              value={input.value}
                              onChange={(_value, checked) =>
                                input.onChange(checked)
                              }
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                            >
                              <BfRadio value={"taxable"}>
                                {i18n.t(
                                  "cb:RentalAgreement.Form.taxable",
                                  "Steuerpflichtig"
                                )}
                              </BfRadio>
                              <BfRadio value={"notTaxable"}>
                                {i18n.t(
                                  "cb:RentalAgreement.Form.notTaxable",
                                  "Nicht steuerpflichtig"
                                )}
                              </BfRadio>
                            </BFRadioGroup>
                          )}
                        </Field>
                      </div>

                      <div className={`__field margin-left-20`}>
                        <Field name="rentPaymentTimingOptions">
                          {({ input, meta }) => (
                            <BFSelect
                              label={`${i18n.t(
                                "cb:RentalAgreement.Form.Fields.rentPaymentTimingOptions",
                                "Sollstellung"
                              )}`}
                              cleanable={false}
                              labelPosition={"left"}
                              ignoreDataSort
                              data={getRentPaymentTimingOption()}
                              {...FV.getValidation(meta)}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={`__field`}>
                      <FormFieldValues names={["taxable", "objectId"]}>
                        {([taxable, objectId]) => (
                          <>
                            {!objectId && (
                              <div className={`select-object-first`}>
                                {i18n.t(
                                  "cb:RentalAgreement.Form.missingObjectId",
                                  "Wählen Sie zuerst ein Objekt aus"
                                )}
                              </div>
                            )}
                            {!taxable && (
                              <div className={`select-object-first`}>
                                {i18n.t(
                                  "cb:RentalAgreement.Form.selectTaxableFirst",
                                  "Wählen Sie zuerst den Steuerstatus aus"
                                )}
                              </div>
                            )}
                            {objectId && taxable && (
                              <AssetLoader
                                assetType={AssetTypes.Portfolio.Object}
                                id={objectId}
                                render={(object: OAObject) => (
                                  <Field
                                    name="paymentPositions"
                                    validate={FormValidators.compose(
                                      FormValidators.required(),
                                      FormValidators.min(0)
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <BFInputTable
                                        value={input.value || []}
                                        columns={[
                                          {
                                            identifier: "net",
                                            label: i18n.t(
                                              "cb:RentalAgreement.Form.Fields.paymentPositions.net",
                                              "Netto"
                                            ),
                                            flexWidth: 3,
                                            inputProps: (
                                              rowValue,
                                              onRowChange
                                            ) => ({
                                              type: "priceInput",
                                              textAlign: "left",
                                              prefix:
                                                StringUtils.getCurrencySymbol(),
                                              removeSuffixPadding: true,
                                              suffix:
                                                taxable === "taxable" &&
                                                rowValue?.gross &&
                                                !NumberUtils.equalsNormalized(
                                                  rowValue.gross / 1.19,
                                                  rowValue.net
                                                ) ? (
                                                  <BFButton
                                                    className={`suggest-button`}
                                                    appearance="link"
                                                    size="xs"
                                                    onClick={() => {
                                                      onRowChange({
                                                        ...rowValue,
                                                        net:
                                                          rowValue.gross / 1.19,
                                                      });
                                                    }}
                                                  >
                                                    {StringUtils.formatCurrency(
                                                      rowValue.gross / 1.19
                                                    )}
                                                  </BFButton>
                                                ) : undefined,
                                            }),
                                          },
                                          ...(taxable === "taxable"
                                            ? [
                                                {
                                                  identifier: "gross",
                                                  label: i18n.t(
                                                    "cb:RentalAgreement.Form.Fields.paymentPositions.gross",
                                                    "Brutto"
                                                  ),
                                                  flexWidth: 3,
                                                  inputProps: (
                                                    rowValue,
                                                    onRowChange
                                                  ) => ({
                                                    type: "priceInput",
                                                    textAlign: "left",
                                                    prefix:
                                                      StringUtils.getCurrencySymbol(),

                                                    removeSuffixPadding: true,
                                                    suffix:
                                                      rowValue?.net &&
                                                      taxable === "taxable" &&
                                                      !NumberUtils.equalsNormalized(
                                                        rowValue?.net * 1.19,
                                                        rowValue?.gross
                                                      ) ? (
                                                        <BFButton
                                                          className={`suggest-button`}
                                                          appearance="link"
                                                          size="xs"
                                                          onClick={() => {
                                                            onRowChange({
                                                              ...rowValue,
                                                              gross:
                                                                rowValue?.net *
                                                                1.19,
                                                            });
                                                          }}
                                                        >
                                                          {StringUtils.formatCurrency(
                                                            rowValue?.net * 1.19
                                                          )}
                                                        </BFButton>
                                                      ) : undefined,
                                                  }),
                                                } as BFInputTableColumn,
                                                {
                                                  identifier: "tax",
                                                  label: i18n.t(
                                                    "cb:RentalAgreement.Form.Fields.paymentPositions.tax",
                                                    "Ust. Betrag"
                                                  ),
                                                  flexWidth: 2,
                                                  render: (
                                                    rowValue,
                                                    onChange,
                                                    row
                                                  ) => {
                                                    return (
                                                      <div className={`info`}>
                                                        {rowValue?.net &&
                                                        rowValue?.gross
                                                          ? StringUtils.formatCurrency(
                                                              rowValue?.gross -
                                                                rowValue?.net
                                                            )
                                                          : "-"}
                                                      </div>
                                                    );
                                                  },
                                                } as BFInputTableColumn,
                                              ]
                                            : []),
                                          {
                                            identifier: "type",
                                            label: "",
                                            flexWidth: 2,
                                            render: (value, onChange, row) => (
                                              <div className={`info`}>
                                                <div className={`info-1`}>
                                                  {row.data.info1}
                                                </div>
                                                <div className={`info-2`}>
                                                  {row.data.info2}
                                                </div>
                                              </div>
                                            ),
                                          },
                                        ]}
                                        rows={
                                          //TODO portfolio restruct
                                          // OrgaStruct.getRentalTargetPositions(objectId)
                                          object.data.feature?.immo?.accounting?.debitposition
                                            ?.filter(
                                              (position) =>
                                                position.relevantForAgreement
                                            )
                                            .filter((position) =>
                                              taxable === "taxable"
                                                ? position.taxRate !== 0
                                                : position.taxRate === 0
                                            )
                                            .filter(
                                              (position) =>
                                                position.status !== "archived"
                                            )
                                            .map((position) => ({
                                              identifier: position.id,
                                              label:
                                                LanguageService.translateLabel(
                                                  position.displayName
                                                ),
                                              data: {
                                                info1:
                                                  position.automaticDebitPosition
                                                    ? i18n.t(
                                                        "cb:",
                                                        "monatliche Abrechnung"
                                                      )
                                                    : "einmalige Abrechnung",
                                                taxRate: position.taxRate,
                                                info2: ((kind) => {
                                                  switch (kind) {
                                                    case "rent":
                                                      return i18n.t(
                                                        "cb:RentalAgreement.Form.Fields.paymentPositions.rent",
                                                        "Mieteinnahmen"
                                                      );
                                                    case "operatingCost":
                                                      return i18n.t(
                                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCost",
                                                        "Betriebskosten"
                                                      );
                                                    case "operatingCostAllInclusive":
                                                      return i18n.t(
                                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCostAllInclusive",
                                                        "Betriebskostenpauschale"
                                                      );
                                                    case "additionalPayment":
                                                      return i18n.t(
                                                        "cb:RentalAgreement.Form.Fields.paymentPositions.additionalPayment",
                                                        "Zusatzleistung"
                                                      );
                                                    default:
                                                      return "-";
                                                  }
                                                })(position.kind),
                                              },
                                            })) || []
                                        }
                                        onChange={input.onChange}
                                      />
                                    )}
                                  </Field>
                                )}
                              />
                            )}
                          </>
                        )}
                      </FormFieldValues>
                    </div>
                  </div>

                  {/* <div
                    className={`section-title __h3 margin-bottom-10 margin-top-10 `}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.Form.SectionTitle.Misc",
                      "Sonstiges"
                    )}
                  </div> */}
                  <CBRentalAgreementDepositForm />
                  <CBRentalAgreementNoteForm />

                  <div className={`additional-actions`}>
                    <CBRentalAgreementNoteButton />
                    <CBRentalAgreementDepositButton />
                  </div>
                </BFFormSection>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default CBRentalAgreementForm;
