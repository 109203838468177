import classNames from "classnames";
import { Nav } from "rsuite";
import i18n from "../../../../../i18n";
import { MatchQuery } from "../../../../../services/DataService";
import { hasValue } from "../../../../../utils/Helpers";
import StringUtils from "../../../../../utils/StringUtils";
import BFCheckbox from "../../../forms/checkbox/BFCheckbox";
import BFInput, { BFInputProps } from "../../../forms/input/BFInput";
import { FilterSearchOption } from "../BFTableFilterModel";
import "./OverlayNumberSelection.scss";
import OverlayTitle from "./OverlayTitle";
export type NumberSelectionValueBase = {
  ignoreDecimals?: boolean;
};

const NUMBER_EXTRACT_REGEX = /(\b\d+(?:[\.,]\d+)?\b(?!(?:[\.,]\d+)))/gm;

export type NumberSelectionValueRange = NumberSelectionValueBase & {
  type: "range";
  from?: number;
  to?: number;
};
export type NumberSelectionValueEquals = NumberSelectionValueBase & {
  type: "equals";
  value?: number;
};

export type NumberSelectionValue =
  | NumberSelectionValueRange
  | NumberSelectionValueEquals;

interface OverlayNumberSelectionProps {
  title: string;
  value: NumberSelectionValue;
  onChange: (value: NumberSelectionValue) => void;

  inputProps?: Partial<BFInputProps>;
}

export const getDefaultNumberOptions = (
  value: NumberSelectionValue,
  searchTerm: string,
  filterKey: string,
  typeLabel: string,
  numberFormatter: (value: number) => string = (value: number) =>
    value.toString(),
  additionalKeys?: string[]
) => {
  let options: FilterSearchOption[] = [];
  if (searchTerm) {
    const matches = searchTerm.match(NUMBER_EXTRACT_REGEX);
    if (matches && matches.length > 0) {
      let pushFrom = true,
        pushTo = true,
        pushEquals = true;

      const onSelect = (
        optionValue: any,
        currentValue: any,
        onChange: (value: any) => void
      ) => {
        onChange({ ...(currentValue || {}), ...optionValue });
      };
      if (hasValue(value)) {
        if (value.type === "equals") {
          pushEquals = false;
          pushFrom = false;
          pushTo = false;
        }
        if (value.type === "range") {
          pushEquals = false;
          pushFrom = !hasValue(value.from);
          pushTo = !hasValue(value.to);
        }
      }

      if (pushFrom) {
        options.push({
          filterKey,
          valueKey: `_${filterKey}_gt`,
          label: `${i18n.t("Global.Labels.from", "von")} ${numberFormatter(
            StringUtils.parseNumber(matches[0])
          )}`,
          value: {
            type: "range",
            from: StringUtils.parseNumber(matches[0]),
          },
          labelAsString: `${matches[0]} von ${typeLabel} > `,
          onSelect,
          typeLabel,
          searchKeys: [...(additionalKeys || []), ">"],
        });
      }
      if (pushTo) {
        options.push({
          filterKey,
          valueKey: `_${filterKey}_lt`,
          label: `${i18n.t("Global.Labels.to", "to")} ${numberFormatter(
            StringUtils.parseNumber(matches[0])
          )}`,
          value: {
            type: "range",
            to: StringUtils.parseNumber(matches[0]),
          },
          labelAsString: `${matches[0]} ${i18n.t(
            "Global.Labels.to",
            "to"
          )} ${typeLabel} < `,
          onSelect,
          typeLabel,
          searchKeys: [...(additionalKeys || []), "<"],
        });
      }
      if (pushEquals) {
        options.push({
          filterKey,
          valueKey: `_${filterKey}_eq`,
          label: `${i18n.t("Global.Labels.equals", "gleich")} ${numberFormatter(
            StringUtils.parseNumber(matches[0])
          )}`,
          value: {
            type: "equals",
            value: StringUtils.parseNumber(matches[0]),
            ignoreDecimals: true,
          },
          labelAsString: `${matches[0]} ${i18n.t(
            "Global.Labels.equals",
            "gleich"
          )} ${typeLabel}  =`,
          onSelect,
          typeLabel,
          searchKeys: [...(additionalKeys || []), "="],
        });
      }
    }
  }
  return options;
};

export const DefaultNumberSelectionValue: NumberSelectionValue = {
  type: "equals",
};

export const generateLabelForNumberFilter = (
  filter: NumberSelectionValue,
  formatter: (value: number) => string = (value: number) => value.toString()
) => {
  let label = null;
  if (filter) {
    if (filter.type === "range") {
      if (hasValue(filter.from) && hasValue(filter.to)) {
        label = `${formatter(filter.from)} - ${formatter(filter.to)}`;
      } else if (hasValue(filter.from)) {
        label = `> ${formatter(filter.from)}`;
      } else if (hasValue(filter.to)) {
        label = `< ${formatter(filter.to)}`;
      }
    } else if (filter.type === "equals") {
      if (hasValue(filter.value)) {
        label = `= ${formatter(filter.value)}`;
      }
    }
  }
  return label;
};
export const generateMatchQueryForNumberFilter = (
  fieldName: string,
  filter: NumberSelectionValue
) => {
  let matchQuery: MatchQuery = null;
  if (filter) {
    if (filter.type === "range") {
      if (hasValue(filter.from) && hasValue(filter.to)) {
        matchQuery = {
          type: "and",
          query: [
            {
              type: "op",
              op: "gte",
              name: fieldName,
              value: filter.ignoreDecimals
                ? Math.floor(filter.from)
                : Number(filter.from),
            },
            {
              type: "op",
              op: "lte",
              name: fieldName,
              value: filter.ignoreDecimals
                ? Math.floor(filter.to) + 0.99
                : Number(filter.to),
            },
          ],
        };
      } else if (hasValue(filter.from)) {
        matchQuery = {
          type: "op",
          op: "gte",
          name: fieldName,
          value: filter.ignoreDecimals
            ? Math.floor(filter.from)
            : Number(filter.from),
        };
      } else if (hasValue(filter.to)) {
        matchQuery = {
          type: "op",
          op: "lte",
          name: fieldName,
          value: filter.ignoreDecimals
            ? Math.floor(filter.to) + 0.99
            : Number(filter.to),
        };
      }
    } else if (filter.type === "equals") {
      if (hasValue(filter.value)) {
        matchQuery = filter.ignoreDecimals
          ? {
              type: "and",
              query: [
                {
                  type: "op",
                  op: "gte",
                  name: fieldName,
                  value: Math.floor(filter.value),
                },
                {
                  type: "op",
                  op: "lte",
                  name: fieldName,
                  value: Math.floor(filter.value) + 0.99,
                },
              ],
            }
          : {
              type: "op",
              op: "eq",
              name: fieldName,
              value: Number(filter.value),
            };
      }
    }
  }
  return matchQuery;
};
const OverlayNumberSelection = (props: OverlayNumberSelectionProps) => {
  const type = props.value?.type || "equals";
  const changeType = (newType: "range" | "equals") => {
    if (newType === "range") {
      props.onChange({
        type: "range",
        from:
          type === "equals"
            ? (props.value as NumberSelectionValueEquals).value
            : null,
        to:
          type === "equals"
            ? (props.value as NumberSelectionValueEquals).value
            : null,
        ignoreDecimals: props.value.ignoreDecimals,
      });
    } else if (newType === "equals") {
      props.onChange({
        type: "equals",
        value:
          type === "range"
            ? (props.value as NumberSelectionValueRange).from
            : null,
        ignoreDecimals: props.value.ignoreDecimals,
      });
    }
  };
  return (
    <div className={classNames(`overlay-number-selection`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <Nav activeKey={type} onSelect={changeType} appearance="subtle">
        <Nav.Item eventKey="equals">
          {i18n.t("BFFilterbar.Number.equals", "Gleich")}
        </Nav.Item>
        <Nav.Item eventKey="range">
          {i18n.t("BFFilterbar.Number.between", "Zwischen")}
        </Nav.Item>
      </Nav>
      <div className="content">
        {type === "range" && (
          <div className="range">
            <div className="from">
              <BFInput
                prefix={i18n.t("Global.Labels.From", "Von")}
                {...props.inputProps}
                value={(props.value as NumberSelectionValueRange)?.from}
                onChange={(value: number) =>
                  props.onChange({
                    ...((props.value || {}) as NumberSelectionValueRange),
                    from: value,
                  })
                }
              />
            </div>
            <div className="to">
              <BFInput
                prefix={i18n.t("Global.Labels.To", "Bis")}
                {...props.inputProps}
                value={(props.value as NumberSelectionValueRange)?.to}
                onChange={(value: number) =>
                  props.onChange({
                    ...((props.value || {}) as NumberSelectionValueRange),
                    to: value,
                  })
                }
              />
            </div>
          </div>
        )}
        {type === "equals" && (
          <div className="equals">
            <div className="value">
              <BFInput
                {...props.inputProps}
                value={(props.value as NumberSelectionValueEquals)?.value}
                onChange={(value: number) =>
                  props.onChange({
                    ...((props.value || {}) as NumberSelectionValueEquals),
                    value: value,
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <BFCheckbox
          size="xs"
          onChange={(_value, checked, ev) => {
            props.onChange({
              ...props.value,
              ignoreDecimals: checked,
            });
          }}
        >
          {i18n.t(
            "BFFilterbar.Number.ignoreDecimals",
            "Dezimalstellen ignorieren"
          )}
        </BFCheckbox>
        {/* <BFButton
          appearance="link"
          onClick={() => props.onChange(props.entries.map((e) => e.value))}
        >
          Alle auswählen
        </BFButton>
        <BFButton appearance="link" onClick={() => props.onChange([])}>
          Alle abwählen
        </BFButton> */}
      </div>
    </div>
  );
};

export default OverlayNumberSelection;
