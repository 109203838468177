import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import { RentalAgreement } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import "./AccountingRentalInfo.scss";

interface AccountingRentalInfoProps {
  rentalAgreementId: string;
  accounting: AccountingData;
}
const AccountingRentalInfo = (props: AccountingRentalInfoProps) => {
  return (
    <AssetLoader
      assetType={AssetTypes.Rental.RentalAgreement}
      id={props.rentalAgreementId}
      render={(asset: RentalAgreement) => (
        <AssetLoader
          assetType={AssetTypes.Portfolio.Object}
          id={asset.data.objectId}
          render={(obj: OAObject) => {
            const paymentPositionsAvailable =
              obj.data.feature.immo?.accounting?.debitposition;
            const paymentPosition = asset.data.paymentPositions
              ?.filter((e) => e.gross > 0 && e.net > 0)
              .map((e) => ({
                ...e,
                name: LanguageService.translateLabel(
                  paymentPositionsAvailable.find((a) => a.id === e.id)
                    ?.displayName
                ),
              }));

            return (
              <div className={classNames(`accounting-rental-info`)}>
                <DebugDataComponent data={asset} />
                <div className={`base-data`}>
                  <BFValueDisplay
                    value={asset}
                    formatter={(asset) => (
                      <BFDetailsButton
                        appearance="link"
                        noPadding
                        data={{
                          assetType: AssetTypes.Rental.RentalAgreement,
                          assetId: asset._id,
                          type: asset.data.type,
                        }}
                      >
                        {asset.data.id} - {asset.data.displayName}
                      </BFDetailsButton>
                    )}
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.RentalAgreement",
                      "Mietvertrag"
                    )}
                  />
                  <BFValueDisplay
                    value={asset.data.tenant}
                    formatter={(value) => (
                      <AssetLoader
                        assetType={AssetTypes.Contact}
                        id={asset.data.tenant}
                        render={(tenant: Contact) => (
                          <BFDetailsButton
                            appearance="link"
                            noPadding
                            data={{
                              assetType: AssetTypes.Contact,
                              assetId: tenant._id,
                              type: tenant.data.type,
                              params: {
                                contactType: "TENANT",
                              },
                            }}
                          >
                            {tenant.data.displayName}
                          </BFDetailsButton>
                        )}
                      />
                    )}
                    label={i18n.t("cb:RentalAgreement.Labels.Tenant", "Mieter")}
                  />
                  <BFValueDisplay
                    value={asset.data.moveIn}
                    formatter={(value) => StringUtils.formatDate(value)}
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.moveIn",
                      "Mietbeginn"
                    )}
                  />
                  <BFValueDisplay
                    value={asset.data.agreementExpiration}
                    formatter={(value) => StringUtils.formatDate(value)}
                    label={i18n.t(
                      "cb:RentalAgreement.Form.Fields.agreementExpiration",
                      "Vertrag bis"
                    )}
                  />
                  <BFValueDisplay
                    value={asset.data.moveOut}
                    formatter={(value) => StringUtils.formatDate(value)}
                    label={i18n.t(
                      "cb:RentalAgreement.Form.Fields.moveOut",
                      "Auszug"
                    )}
                  />
                </div>

                {paymentPosition.length > 0 && (
                  <table className={`rental-data`}>
                    <tr>
                      <th>
                        {i18n.t(
                          "cb:RentalAgreement.Labels.rentalPositionsByAgreement",
                          "Vereinbarte Miete"
                        )}
                      </th>
                      <th className={`right`}>
                        {i18n.t("cb:RentalAgreement.Labels.net", "Netto")}
                      </th>
                      <th className={`right`}>
                        {i18n.t("cb:RentalAgreement.Labels.gross", "Brutto")}
                      </th>
                    </tr>
                    {paymentPosition.map((paymentPosition, index) => (
                      <tr>
                        <td>{paymentPosition.name}</td>
                        <td className={`right`}>
                          {StringUtils.formatCurrency(
                            paymentPosition.net,
                            true,
                            undefined,
                            props.accounting.accounting.data.currency
                          )}
                        </td>
                        <td className={`right`}>
                          {StringUtils.formatCurrency(
                            paymentPosition.gross,
                            true,
                            undefined,
                            props.accounting.accounting.data.currency
                          )}
                        </td>
                      </tr>
                    ))}
                    {/* {
                    asset.data.
                  } */}
                  </table>
                )}
              </div>
            );
          }}
        />
      )}
    />
  );
};

export default AccountingRentalInfo;
