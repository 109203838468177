import _ from "lodash";
import i18n from "../../../../../../../i18n";
import StringUtils from "../../../../../../../utils/StringUtils";
export type StackingPlanGroup = {
  name: string;
  subName?: string;
  entries: StackingPlanEntry[];
};
export type StackingPlanEntry = {
  _id: string;
  floor: number;
  quanitity: number;
  backgroundColor: string;
  bottomLeft: string;
  topLeft: string;
  topRight: string;
  center: string;
  centerSub: string;
};
const FLOOR_MIN_WIDTH = 30;
const FLOOR_HEIGHT = 65;
const FLOOR_WIDTH_DEFAULT = 140;
const OBJECT_GAP = 20;
const OBJECT_TITLE_HEIGHT = 48;
const generateStackingPlanFloor = (
  floor: number,
  y: number,
  entries: StackingPlanEntry[]
) => {
  const relevantEntries = entries.filter((e) => e.floor === floor);

  const minFloor = _.min(entries.map((e) => e.floor));
  const maxFloor = _.max(entries.map((e) => e.floor));

  const minCount = _.min(entries.map((e) => e.quanitity || 1));
  const avg = _.sum(entries.map((e) => e.quanitity || 1)) / entries.length;
  const maxCount = _.max(relevantEntries.map((e) => e.quanitity || 1));

  const widthSum = _.sum(
    relevantEntries.map((e) =>
      Math.max(
        FLOOR_MIN_WIDTH,
        ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
      )
    )
  );

  const maxFloorWidth = _.max(
    _.range(minFloor, maxFloor + 1).map((floor) => {
      const relevantEntries = entries.filter((e) => e.floor === floor);
      const widthSum = _.sum(
        relevantEntries.map((e) =>
          Math.floor(
            Math.max(
              FLOOR_MIN_WIDTH,
              ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
            )
          )
        )
      );
      return widthSum;
    })
  );

  let startX = (maxFloorWidth - widthSum) / 2;
  if (relevantEntries.length === 0) {
    return `
    <text x="${maxFloorWidth / 2}" y="${
      y + FLOOR_HEIGHT / 2
    }" fill="#7e7e7e"  alignment-baseline="middle" text-anchor="middle" font-size="10px">${createEllipsisSVG(
      maxFloorWidth,
      10,
      i18n.t(
        "StackingPlan.missingFloor",
        "Keine Mieteinheit für Ebene {{floor}}",
        { floor }
      )
    )}</text>   `;
  } else {
    return relevantEntries
      .map((e) => {
        const width = Math.floor(
          Math.max(
            FLOOR_MIN_WIDTH,
            ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
          )
        );
        const useX = startX;
        startX += width;
        return {
          x: useX,
          y,
          width,
          height: FLOOR_HEIGHT,
          entry: e,
        };
      })
      .map(
        (e) => `
    <g name="${e.entry._id}">
      <rect x="${e.x}" y="${e.y}" width="${e.width}" height="${
          e.height
        }" fill="${e.entry.backgroundColor || "#fff"}" />   
        <text x="${e.x + 5}" y="${
          e.y + 5
        }" fill="#3d3d3d"  alignment-baseline="hanging" font-size="10px">${createEllipsisSVG(
          e.width / 2,
          10,
          e.entry.topLeft
        )}</text>   
        <text x="${e.x + 5}" y="${
          e.y + FLOOR_HEIGHT - 5
        }" fill="#3d3d3d"  alignment-baseline="baseline" font-size="10px">${createEllipsisSVG(
          e.width * 0.8,
          10,
          e.entry.bottomLeft
        )}</text>   
        <text x="${
          e.x + e.width / 2
        }" text-anchor="middle" alignment-baseline="middle" y="${
          e.y + 30
        }" fill="#171616" font-size="11px">${createEllipsisSVG(
          e.width,
          11,
          e.entry.center
        )}</text>
      <text x="${
        e.x + e.width / 2
      }" text-anchor="middle" alignment-baseline="middle" y="${
          e.y + 42
        }" fill="#4d4d4d" font-size="9px">${createEllipsisSVG(
          e.width,
          9,
          e.entry.centerSub
        )}</text>
      <text x="${e.x + e.width - 5}" y="${
          e.y + 5
        }" text-anchor="end" fill="#1b1b1b"  alignment-baseline="hanging"  font-size="10px">${createEllipsisSVG(
          e.width / 2,
          10,
          e.entry.topRight
        )}</text>
      
  
    </g>
    `
      )
      .join("");
  }
};

export const generateStackingPlanSVGObject = (
  objectIndex: number,
  x: number,
  y: number,
  entries: StackingPlanEntry[],
  name: string,
  subName?: string,
  showGroundLevel: boolean = true,
  collapsed: boolean = false,
  forPdf: boolean = false
) => {
  const minFloor = _.min(entries.map((e) => e.floor));
  const maxFloor = _.max(entries.map((e) => e.floor));

  const floorCount = maxFloor - minFloor + 1;

  const minCount = _.min(entries.map((e) => e.quanitity || 1));
  const avg = _.sum(entries.map((e) => e.quanitity || 1)) / entries.length;
  const maxFloorWidth = collapsed
    ? COLLAPSED_WIDTH
    : _.max(
        _.range(minFloor, maxFloor + 1).map((floor) => {
          const relevantEntries = entries.filter((e) => e.floor === floor);
          const widthSum = _.sum(
            relevantEntries.map((e) =>
              Math.floor(
                Math.max(
                  FLOOR_MIN_WIDTH,
                  ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
                )
              )
            )
          );
          return widthSum;
        })
      );
  const height = floorCount * FLOOR_HEIGHT;

  if (collapsed) {
    return `<g transform="translate(${x},${
      y + maxFloor * FLOOR_HEIGHT
    })" preserveAspectRatio="none">
      <text dx="${
        maxFloorWidth / 2
      }" y="0" fill="#171616" text-anchor="middle" font-size="16px">${createEllipsisSVG(
      maxFloorWidth,
      16,
      name
    )}</text>
    
    ${
      forPdf
        ? ""
        : `
      <g name="collapse_${objectIndex}" transform="translate(0, 20)"  preserveAspectRatio="none">
        <rect x="${
          maxFloorWidth / 2 - 45
        }" y="0" width="${90}" height="20" fill="#fff" stroke="#008397" stroke-width="2" rx="15"/>

          <text  dx="${
            maxFloorWidth / 2
          }" y="13" fill="#008397" text-anchor="middle" font-size="12px">
          ${createEllipsisSVG(
            maxFloorWidth,
            12,
            i18n.t("StackingPlan.collapseOut", "Anzeigen")
          )}
          </text>
      </g>
      
      `
    }
    </g>`;
  } else {
    const result = `
      <g transform="translate(${x},${y})" preserveAspectRatio="none">
      <text x="0" dx="${
        maxFloorWidth / 2
      }" y="0" fill="#171616" text-anchor="middle" font-size="16px">${createEllipsisSVG(
      maxFloorWidth,
      16,
      name
    )}</text>
      ${
        subName
          ? `
      <text x="0" dx="${
        maxFloorWidth / 2
      }" y="20" fill="#4d4c4c" text-anchor="middle" font-size="14px">${createEllipsisSVG(
              maxFloorWidth,
              16,
              subName
            )}</text>
      `
          : ""
      }


      ${
        forPdf
          ? ""
          : `
      <g name="collapse_${objectIndex}" transform="translate(0, 20)"  preserveAspectRatio="none">
        <rect x="${
          maxFloorWidth / 2 - 45
        }" y="0" width="${90}" height="20" fill="#fff" stroke="#008397" stroke-width="2" rx="15"/>

          <text  dx="${
            maxFloorWidth / 2
          }" y="13" fill="#008397" text-anchor="middle" font-size="12px">
          ${createEllipsisSVG(
            maxFloorWidth,
            12,
            i18n.t("StackingPlan.collapseIn", "Verbergen")
          )}
          </text>
      </g>`
      }
              ${_.range(minFloor, maxFloor + 1)
                .map(
                  (floor) => `${generateStackingPlanFloor(
                    floor,
                    height -
                      (floor - minFloor + 1) * FLOOR_HEIGHT +
                      (floor < 0 && showGroundLevel ? 2 : 0) +
                      OBJECT_TITLE_HEIGHT,
                    entries
                  )}
                ${
                  floor === 0 && showGroundLevel
                    ? `<rect x="0" y="${
                        (maxFloor + 1) * FLOOR_HEIGHT + OBJECT_TITLE_HEIGHT
                      }" width="${maxFloorWidth}" height="2" fill="#000" />`
                    : ""
                }
                `
                )
                .join("")}
            </g>
        `;

    return result;
  }
};

const COLLAPSED_WIDTH = 200;

export const generateStackingPlanSVG = (
  groups: StackingPlanGroup[],
  collapseState: boolean[],
  forPdf?: boolean
) => {
  const canvasWidth =
    groups.reduce((prev, current, currentIndex) => {
      if (collapseState[currentIndex]) {
        return COLLAPSED_WIDTH + prev;
      } else {
        const entries = current.entries;

        const minFloor = _.min(entries.map((e) => e.floor));
        const maxFloor = _.max(entries.map((e) => e.floor));
        const floorCount = maxFloor - minFloor + 1;
        const minCount = _.min(entries.map((e) => e.quanitity || 1));
        const avg =
          _.sum(entries.map((e) => e.quanitity || 1)) / entries.length;
        const maxFloorWidth = _.max(
          _.range(minFloor, maxFloor + 1).map((floor) => {
            const relevantEntries = entries.filter((e) => e.floor === floor);
            const widthSum = _.sum(
              relevantEntries.map((e) =>
                Math.floor(
                  Math.max(
                    FLOOR_MIN_WIDTH,
                    ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
                  )
                )
              )
            );
            return widthSum;
          })
        );

        return maxFloorWidth + prev;
      }
    }, 0) +
    (groups.length + 2) * OBJECT_GAP;

  const renderGroups = [];
  groups.forEach((group, index) => {
    const isCollapsed = collapseState[index];
    const entries = group.entries;

    const minFloor = _.min(entries.map((e) => e.floor)) || 0;
    const maxFloor = _.max(entries.map((e) => e.floor)) || 0;
    const floorCount = maxFloor - minFloor + 1;
    const minCount = _.min(entries.map((e) => e.quanitity || 1));
    const avg = _.sum(entries.map((e) => e.quanitity || 1)) / entries.length;

    const height = floorCount * FLOOR_HEIGHT;
    const maxFloorWidth = isCollapsed
      ? COLLAPSED_WIDTH
      : _.max(
          _.range(minFloor, maxFloor + 1).map((floor) => {
            const relevantEntries = entries.filter((e) => e.floor === floor);
            const widthSum = _.sum(
              relevantEntries.map((e) =>
                Math.floor(
                  Math.max(
                    FLOOR_MIN_WIDTH,
                    ((e.quanitity || 1) / avg) * FLOOR_WIDTH_DEFAULT
                  )
                )
              )
            );
            return widthSum;
          })
        );

    renderGroups.push({
      x:
        (renderGroups[index - 1]?.x || 0) +
          (renderGroups[index - 1]?.width || 0) +
          OBJECT_GAP || OBJECT_GAP,
      y: 0,
      width: maxFloorWidth,
      height,
      maxFloor,
      minFloor,
      entries,
      name: group.name,
      subName: group.subName,
      collapsed: isCollapsed,
    });
  });

  const maxFloor = _.max(renderGroups.map((e) => e.maxFloor));
  const minFloor = _.min(renderGroups.map((e) => e.minFloor));

  const canvasHeight =
    OBJECT_TITLE_HEIGHT +
    (maxFloor + Math.abs(minFloor) + 1) * FLOOR_HEIGHT +
    50;

  return `
        <svg width="100%" height="100%" viewBox="0 0 ${canvasWidth} ${canvasHeight}" preserveAspectRatio="none">
        ${renderGroups.map((group, index) => {
          return generateStackingPlanSVGObject(
            index,
            group.x,
            (maxFloor - group.maxFloor) * FLOOR_HEIGHT + OBJECT_TITLE_HEIGHT,
            group.entries,
            group.name,
            group.subName,
            true,
            group.collapsed,
            forPdf
          );
        })}
        </svg>
    `;
};

function createEllipsisSVG(maxWidth, fontSize, textContent = "") {
  const ellipsis = "...";

  const text = document.createElement("span");
  document.body.appendChild(text);

  // text.style.font = "times new roman";
  text.style.fontSize = fontSize + "px";
  text.style.height = "auto";
  text.style.width = "auto";
  text.style.position = "absolute";
  text.style.whiteSpace = "no-wrap";
  text.innerHTML = textContent || "";

  let truncated = false;
  let truncatedText = textContent || "";
  while (Math.ceil(text.clientWidth) > maxWidth) {
    truncatedText = truncatedText.slice(0, -1);
    if (truncatedText.length === 0) {
      break;
    }
    text.innerHTML = truncatedText + ellipsis;
    truncated = true;
  }
  const result = (truncated ? truncatedText + ellipsis : textContent) || "";
  document.body.removeChild(text);

  return StringUtils.encodeHTML(result);
}
