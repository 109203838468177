import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { isDefined } from "@/utils/Helpers";
import classNames from "classnames";
import * as ECharts from "echarts";
import _ from "lodash";
import moment from "moment";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import ChartComponent from "../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../i18n";
import { setFlexCacheData } from "../../../../../../../redux/actions/application/application-actions";
import { useDatabus, useHttpCache } from "../../../../../../../redux/hooks";
import { store } from "../../../../../../../redux/store";
import DataBus from "../../../../../../../services/DataBus";
import LanguageService from "../../../../../../../services/LanguageService";
import { DataBusSubKeys } from "../../../../../../../utils/Constants";
import { usePrevious } from "../../../../../../../utils/Hooks";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { EnrichtedRentalUnit } from "../../TenantsInterfaces";
import "./ObjectStackingPlan.scss";
import { StackingPlanGroup, generateStackingPlanSVG } from "./StackingPlanSVG";

export type ObjectStackingPlanUnit = {
  _id: string;
  topLeft: string;
  centerSub: string;
  floor: number;
  quanitity: number;
  center: string;
  topRight: string;
  bottomLeft: string;
  backgroundColor: string;
};

export type ObjectStackingPlanUnitWithBuilding = ObjectStackingPlanUnit & {
  building?: string;
  group: "areal" | "parking" | "other";
};

export const objectStackingPlanSplitRentalUnitIntoGroups = (
  rentalUnits: EnrichtedRentalUnit[],
  additionalUnits?: ObjectStackingPlanUnitWithBuilding[]
) => {
  const groups: StackingPlanGroup[] = [];
  const groupByBuilding = _.groupBy(rentalUnits, "data.building");
  const additionalUnitsByBuilding = _.groupBy(
    additionalUnits || [],
    "building"
  );
  const buildings = _.merge({}, groupByBuilding, additionalUnitsByBuilding);

  Object.keys(buildings).forEach((building) => {
    const groupedByUnitType = _.groupBy(
      (groupByBuilding[building] || []).map((unit) => ({
        ...unit,
        group: ObjectKindStruct.getUnitTypeStackingPlanGroup(
          unit.data.unitType
        ),
      })),
      "group"
    );
    const addtionalUnitsGroup = _.groupBy(
      additionalUnitsByBuilding[building] || [],
      "group"
    );

    ["areal", "parking", "other"].forEach((unitType) => {
      const entries: ObjectStackingPlanUnit[] = [];

      if (groupedByUnitType.hasOwnProperty(unitType)) {
        const units = groupedByUnitType[unitType];
        entries.push(
          ...units.map(
            (e) =>
              ({
                _id: e._id,
                topLeft:
                  e.data.agreement?.data.id || e.data.vacant?.data.id || "-",
                centerSub: LanguageService.translateLabel(e.data.displayName),
                floor: e.data.floor || 0,
                quanitity: e.data.area || e.data.quantity || 0,
                center:
                  e.data.tenant?.data.displayName ||
                  (e.data.rentalStatus === "reserved"
                    ? i18n.t("ObjectStackingPlan.Reserved", "Reserviert")
                    : e.data.rentalStatus === "blocked"
                    ? i18n.t("ObjectStackingPlan.Blocked", "Blockiert/Umbau")
                    : i18n.t("ObjectStackingPlan.Vacant", "Leerstand")),
                topRight:
                  getConfigRentalUnitTypeGroup(
                    ObjectKindStruct.getUnitTypeBy(e.data.unitType)?.group
                  )?.areaType === "area"
                    ? `${StringUtils.formatNumber(
                        e.data.area
                      )} ${StringUtils.getAreaUnit()}`
                    : e.data.quantity.toString(),
                bottomLeft: LanguageService.translateLabel(
                  ObjectKindStruct.getUnitTypeBy(e.data.unitType)?.displayName
                ),
                backgroundColor: getConfigRentalStatus(e.data.rentalStatus)
                  ?.stackingPlanColor,
              } as ObjectStackingPlanUnit)
          )
        );
      }

      if (addtionalUnitsGroup.hasOwnProperty(unitType)) {
        const units = addtionalUnitsGroup[unitType];
        entries.push(...units.map(({ group, building, ...unit }) => unit));
      }

      if (entries.length > 0) {
        groups.push({
          name: i18n.t("ObjectStackingPlan.UnitType." + unitType),
          /**
           *  i18n.t("ObjectStackingPlan.UnitType.areal", "Vermietbare Fläche")
           *  i18n.t("ObjectStackingPlan.UnitType.parking", "Stellplätze")
           *  i18n.t("ObjectStackingPlan.UnitType.other", "Sonstiges")
           *
           */
          subName: building === "undefined" ? "" : building,
          entries: entries,
        });
      }
    });
  });

  return groups.filter((e) => e.entries.length > 0);
};

export const resetObjectStackingPlan = (objectId: string) => {
  store.dispatch(
    setFlexCacheData("httpCache", `object-stacking-plan-${objectId}`, null)
  );
};

const COLLAPSE_KEY = "object-stacking-plan-collapse-click";
interface ObjectStackingPlanProps {
  id?: string;
  objectId: string;
  time?: Date;
  markedUnits?: string[];
  selectMode?: "single" | "multiple" | false;
  onSelect?: (
    entries:
      | EnrichtedRentalUnit[]
      | EnrichtedRentalUnit
      | ObjectStackingPlanUnitWithBuilding
  ) => void;
  selected?: string[];
  onMounted?: (ident, chart) => void;

  additionalUnits?: ObjectStackingPlanUnitWithBuilding[];
  initialOpen?: boolean;
  instanceRef?: any;
}

export const getObjectStackingPlanChartOptions = (
  data: EnrichtedRentalUnit[],
  groupedData,
  mapString: string,
  markedUnits?: string[],
  selectMode?: "single" | "multiple" | false,
  forPdf?: boolean
) => ({
  animation: forPdf ? false : true,
  tooltip: {
    show: false,
  },
  geo: {
    map: mapString,
    roam: true,
    scaleLimit: {
      min: 0.8,
      max: 10,
    },
    selectedMode: selectMode,
    layoutCenter: ["50%", "50%"],
    layoutSize: "100%",
    tooltip: {
      show: true,
      // showDelay: 750,
      hideDelay: 100,
      confine: true,
      formatter: (params, ticket) => {
        const id = params.name;
        const entry = data?.find((e) => e._id === id);
        if (entry?.data?.id) {
          return `<div class="object-stacking-plan-overlay">
            <div class="overlay-title">
              <span class="id">${entry.data.id}</span>${entry.data.displayName}
            </div>
            ${[
              [
                i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
                getConfigRentalUnitTypeGroup(
                  ObjectKindStruct.getUnitTypeBy(entry.data.unitType)?.group
                )?.label,
              ],
              [
                i18n.t("cb:RentalUnit.unitType", "Art"),
                LanguageService.translateLabel(
                  ObjectKindStruct.getUnitTypeBy(entry.data.unitType)
                    ?.displayName
                ),
              ],
              [
                i18n.t("cb:RentalUnit.quantity", "Fläche/Anzahl"),
                getConfigRentalUnitTypeGroup(
                  ObjectKindStruct.getUnitTypeBy(entry.data.unitType)?.group
                )?.areaType === "area"
                  ? StringUtils.formatNumber(entry.data.area) +
                    " " +
                    StringUtils.getAreaUnit()
                  : entry.data.quantity.toString(),
              ],
              [
                i18n.t("cb:RentalUnit.rentGross", "Planmiete brutto"),
                StringUtils.formatCurrency(entry?.data?.rentGross),
              ],
              [
                i18n.t("cb:RentalUnit.rentNet"),
                StringUtils.formatCurrency(entry?.data?.rentNet),
              ],
              [
                i18n.t("cb:RentalUnit.operatingCostGross"),
                StringUtils.formatCurrency(entry?.data?.operatingCostGross),
              ],
              [
                i18n.t("cb:RentalUnit.operatingCostNet"),
                StringUtils.formatCurrency(entry?.data?.operatingCostNet),
              ],
              entry.data.tenant
                ? [
                    i18n.t("cb:RentalUnit.currentTenant", "Aktueller Mieter"),
                    entry?.data?.tenant?.data.displayName,
                  ]
                : null,
              entry.data.agreement?.data.moveIn
                ? [
                    i18n.t(
                      "cb:RentalUnit.agreement.moveIn",
                      "Mietvertrag seit"
                    ),
                    moment(entry.data.agreement?.data.moveIn).format(
                      i18n.t("Formats.dateFormat")
                    ),
                  ]
                : null,
              entry.data.agreement?.data.agreementExpiration
                ? [
                    i18n.t("cb:RentalUnit.agreement.expire", "Mietvertrag bis"),
                    moment(
                      entry.data.agreement?.data.agreementExpiration
                    ).format(i18n.t("Formats.dateFormat")),
                  ]
                : null,
              entry.data.agreement?.data.moveOut
                ? [
                    i18n.t("cb:RentalUnit.agreement.moveOut", "Auszug am"),
                    moment(entry.data.agreement?.data.moveOut).format(
                      i18n.t("Formats.dateFormat")
                    ),
                  ]
                : null,
            ]
              .filter((e) => !!e)
              .map(
                ([key, value]) => `
              <div class="entry">
                <div class="key">
                  ${key}
                </div>
                <div class="value">
                 ${value}
                </div>
              </div>
              `
              )
              .join("\n")}
            
            </div>`;
        } else {
          return null;
        }
      },
    },
    itemStyle: {
      color: null,
      opacity: markedUnits ? 0.3 : 1,
      label: {
        opacity: markedUnits ? 0.3 : 1,
      },
    },
    emphasis: {
      itemStyle: {
        color: undefined,
        borderColor: "borderWidth",
        borderWidth: 2,
      },
      label: {
        show: false,
      },
    },
    select: {
      itemStyle: {
        areaColor: "#78979831",
        borderColor: "#2a4c65",
        borderWidth: 3,
      },
      label: {
        show: false,
        textBorderColor: "#000",
        textBorderWidth: 2,
      },
    },
    //   regions: makeTakenRegions(takenSeatNames)
    regions: [
      ...(markedUnits || []).map((unit) => ({
        name: unit,
        silent: true,
        itemStyle: {
          opacity: 1,
          // areaColor: "#788f9831",
          borderColor: "#353637",
          borderWidth: 2,
          borderType: "solid",
        },
      })),
      ...groupedData.map((_group, index) => ({
        name: `collapse_${index}`,
        itemStyle: {
          borderColor: "#fff",
        },
        emphasis: {
          label: {
            fontWeigth: "bold",
          },
        },
      })),
    ],
  },
});
const ObjectStackingPlan = (props: ObjectStackingPlanProps) => {
  const additionalUnitsRef = useRef([]);
  const idRef = useRef(nanoid());
  const collapseRef = useRef<any>(null);
  const [instance, setInstance] = useState<any>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const previousSelection = usePrevious(props.selected);

  const [collapseState, setCollapseState] = useState(null);
  const [groupedData, setGroupedData] = useState<StackingPlanGroup[]>([]);

  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${props.objectId}`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: [props.objectId],
    }
  );
  useDatabus(
    COLLAPSE_KEY,
    ({ id, groupIndex }) => {
      if (id === idRef.current) {
        setCollapseState(
          collapseState.map((e, index) => (groupIndex === index ? !e : e))
        );
      }
    },
    [collapseState]
  );
  useEffect(() => {
    setTimeout(() => {
      if (props.selected && instance) {
        (previousSelection || []).forEach((e) => {
          instance.dispatchAction({
            type: "geoUnSelect",
            name: e,
          });
        });

        (props.selected || []).forEach((e) => {
          instance.dispatchAction({
            type: "geoSelect",
            name: e,
          });
        });
        // instance.dispatchAction({
        //   type: "unselect",
        //   name: previousSelection || [],
        // });
        // setTimeout(() => {
        // instance.dispatchAction({
        //   type: "geoSelect",
        //   geoId: props.selected,
        // });
        // });
      }
    });
  }, [props.selected, instance]);

  useDatabus(
    DataBusSubKeys.STACKING_PLAN_RELOAD,
    ({ objectId }) => {
      if (objectId === props.objectId) {
        data?.reload();
      }
    },
    [props.objectId, data?.reload]
  );
  useEffect(() => {
    if (data.state === "success") {
      const newGroupedData = objectStackingPlanSplitRentalUnitIntoGroups(
        data.data || [],
        props.additionalUnits || []
      );
      setGroupedData(newGroupedData);
      const newCollapseState = newGroupedData.map((e, index) =>
        isDefined(props.initialOpen)
          ? !props.initialOpen
          : index === 0
          ? false
          : true
      );

      if (
        !ECharts.getMap(
          `object-stacking-plan-${props.objectId}-${newCollapseState.join("_")}`
        )
      ) {
        ECharts.registerMap(
          `object-stacking-plan-${props.objectId}-${newCollapseState.join(
            "_"
          )}`,
          {
            svg: generateStackingPlanSVG(groupedData, newCollapseState),
          }
        );
      }

      setCollapseState(newCollapseState);
      collapseRef.current = newCollapseState;
    }
    additionalUnitsRef.current = props.additionalUnits || [];
  }, [data.state, props.additionalUnits]);

  useEffect(() => {
    if (collapseState) {
      if (
        !ECharts.getMap(
          `object-stacking-plan-${props.objectId}-${collapseState.join("_")}`
        )
      ) {
        ECharts.registerMap(
          `object-stacking-plan-${props.objectId}-${collapseState.join("_")}`,
          {
            svg: generateStackingPlanSVG(groupedData, collapseState),
          }
        );
      }
      setMapLoaded(true);

      setTimeout(() => {
        instance?.setOption(instance?.getOption(), true, true);
        instance?.resize({
          silent: false,
        });
      });
    }
    // }
  }, [collapseState, groupedData]);

  if (data.state === "success" && mapLoaded) {
    return (
      <div
        className={classNames(`object-stacking-plan`)}
        style={{ height: "100%", width: "100%" }}
      >
        <ChartComponent
          onMounted={(ident, chart) => {
            setInstance(chart);
            if (props.instanceRef) {
              props.instanceRef.current = chart;
            }
            props.onMounted?.(ident, chart);
            chart.on("geoselectchanged", (params) => {
              const selectedId = params.name;

              if (selectedId?.startsWith("collapse_")) {
                const collapseIndex = parseInt(selectedId.slice(9));
                DataBus.emit(COLLAPSE_KEY, {
                  id: idRef.current,
                  groupIndex: collapseIndex,
                });
                return;
              } else {
                if (props.selectMode === "single") {
                  props.onSelect?.(
                    data.data.find((e) => e._id === selectedId) ||
                      (additionalUnitsRef.current || []).find(
                        (e) => e._id == selectedId
                      )
                  );
                } else if (props.selectMode === "multiple") {
                  props.onSelect?.(
                    data.data.filter((e) =>
                      params.allSelected[0]?.name?.find((a) => a === e._id)
                    )
                  );
                }

                return selectedId;
              }
            });
          }}
          ignoreDelay={true}
          clearOnChange={false}
          renderer="canvas"
          options={getObjectStackingPlanChartOptions(
            data.data,
            groupedData,
            `object-stacking-plan-${props.objectId}-${collapseState.join("_")}`,
            props.markedUnits,
            props.selectMode
          )}
          // identifier={`object-stacking-plan-${props.objectId}-${
          //   props.id || ""
          // }`}
        />

        {/* <div
          dangerouslySetInnerHTML={{
            __html: generateStackingPlanSVG(
              data.data?.data.map((e) => {
                return {
                  _id: e._id,
                  id: e.data.id,
                  name: e.data.displayName,
                  floor: e.data.floor,
                  quanitity: e.data.quantity,
                  quantityUnit: "qm",
                  unitType: e.data.unitType,
                  rentalStatus: e.data.rentalStatus,
                } as StackingPlanEntry;
              })
            ),
          }}
        /> */}
      </div>
    );
  } else {
    return <LoadPage />;
  }
};

export default ObjectStackingPlan;
