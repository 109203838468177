import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import CSSUtils from "@/utils/CSSUtils";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import VirtualizedTableExportButton from "../../../../../../../configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import TablePage from "../../../../../../../configurable/layouts/TablePageLayout/TablePage";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import { ColumnConfig } from "../../../../../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { DocumentStoreAssetParams } from "../../../../../../../modules/document-store/DSInterfaces";
import DSService from "../../../../../../../modules/document-store/DSService";
import DSUnmentRequirementsColumn from "../../../../../../../modules/document-store/components/DSUnmetRequirementsColumn/DSUnmetRequirementsColumn";
import {
  getDefaultAreaNumberFormat,
  getDefaultCurrencyNumberFormat,
} from "../../../../../../../modules/export/export.model";
import { setFlexCacheData } from "../../../../../../../redux/actions/application/application-actions";
import { selectFlexConfig } from "../../../../../../../redux/actions/application/application-selectors";
import {
  useAggregationTableQuery,
  useTypedSelector,
} from "../../../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../../../redux/model";
import DataBus from "../../../../../../../services/DataBus";
import LanguageService from "../../../../../../../services/LanguageService";
import PermissionService from "../../../../../../../services/PermissionService";
import { useRestrictionsMatchQuery } from "../../../../../../../services/RestrictionService";
import { DataBusSubKeys } from "../../../../../../../utils/Constants";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  CB_RENTAL_AGREEMENT_LIST,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalAgreement } from "../../TenantsInterfaces";
import CBRentalAgreementForm from "./CBRentalAgreementForm";
import { CBRentalAgreementsFilterOptions } from "./CBRentalAgreementsFilterOptions";
import "./CBRentalAgreementsList.scss";

export const RENTAL_AGREEMENT_STATIC_SELECTORS: AggregationStatisticQuerySelector[] =
  [
    {
      name: "general",
      op: [
        { key: "count", op: "count" },
        {
          key: "deposit",
          op: "sum",
          field: "data.deposit.depositValue",
        },
        {
          key: "balance",
          op: "sum",
          field: "data.balance",
        },
        {
          key: "rentGross",
          op: "sum",
          field: "data.rentGross",
        },
        {
          key: "rentNet",
          op: "sum",
          field: "data.rentNet",
        },
        {
          key: "operatingCostGross",
          op: "sum",
          field: "data.operatingCostGross",
        },
        {
          key: "operatingCostNet",
          op: "sum",
          field: "data.operatingCostNet",
        },
      ],
      query: {},
    },
  ];

const filterData = () => {
  return [
    {
      label: i18n.t("cb:RentalAgreement.FilterBy.all", "Alle Mietverträge"),
      value: "all",
      query: null,
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.FilterBy.active",
        "Aktuelle Mietverträge"
      ),
      value: "active",
      query: MQ.and(
        MQ.lte("data.moveIn", moment().startOf("day")),
        MQ.or(
          MQ.gte("data.moveOut", moment().startOf("day")),
          MQ.isNull("data.moveOut")
        )
      ),
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.FilterBy.inactive",
        "Abgelaufene Mietverträge"
      ),
      value: "inactive",
      query: MQ.and(MQ.lte("data.moveOut", moment().endOf("day"))),
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.FilterBy.future",
        "Zukünftige Mietverträge"
      ),
      value: "future",
      query: MQ.and(MQ.gt("data.moveIn", moment().startOf("day"))),
    },
  ];
};
const FlexKey = "cb_rentalAgreementList";
interface RentersViewProps {
  kind: ObjectKind;
}
const CBRentalAgreementsList = (props: RentersViewProps) => {
  const tableCache = useTypedSelector(
    (state) => state.application.infiniteTables[CB_RENTAL_AGREEMENT_LIST]
  );
  const dispatch = useDispatch();
  const restrictions = useRestrictionsMatchQuery();
  const [initialState, setInitialState] =
    useState<FilterComponentInitialState>(undefined);
  const aggregated = useAggregationTableQuery(
    CB_RENTAL_AGREEMENT_LIST,
    RENTAL_AGREEMENT_STATIC_SELECTORS
  );
  const { filterValue } = useTypedSelector((state) =>
    selectFlexConfig(state, FlexKey)
  ) || { filterValue: "active" };

  const filterOptions = filterData();
  const additionalMatchQuery = filterOptions.find(
    (e) => e.value === filterValue
  )?.query;
  return (
    <TablePage
      layout={{
        className: "cb-rental-agreements-list",
        cardHeader: (
          <div className={`agreements-header`}>
            <div className={`margin-bottom-10 __h1 `}>
              {i18n.t("cb:RentalAgreement.title", "Mietverträge")}
            </div>
            <div>
              <BFSelect
                onChange={(value) => {
                  dispatch(setFlexCacheData(FlexKey, "filterValue", value));
                }}
                cleanable={false}
                value={filterValue}
                data={filterOptions.map((e) => ({
                  label: e.label,
                  value: e.value,
                }))}
              />
            </div>
            <VirtualizedTableExportButton
              identifier={CB_RENTAL_AGREEMENT_LIST}
            />
          </div>
        ),
        pageHeader: (
          <>
            <InfiniteTableFilter
              initialState={initialState}
              identifier={CB_RENTAL_AGREEMENT_LIST}
              filterOptions={CBRentalAgreementsFilterOptions(
                props.kind,
                PermissionService.hasBusinessUnitRole("cb_rentalView")
              )}
            />
            {PermissionService.hasObjectKindPermission(
              props.kind,
              "rental.agreements.createEdit"
            ) && (
              <BFButton
                onClick={() => {
                  ModalManager.show({
                    backdrop: "static",
                    size: "xxl",
                    noPadding: true,
                    content: (state, setState, onClose) => (
                      <CBRentalAgreementForm
                        kind={props.kind}
                        onClose={onClose}
                      />
                    ),
                  });
                }}
              >
                {i18n.t("cb:RentalAgreement.add", "Mietvertrag hinzufügen")}
              </BFButton>
            )}
          </>
        ),
      }}
      table={{
        onRowDoubleClick: (asset: RentalAgreement, index) => {
          DataBus.emit(DataBusSubKeys.ROUTE, {
            append: true,
            route: `${asset._id}`,
          });
        },
        additionalMatchQuery: MQ.and(restrictions, additionalMatchQuery),
        identifier: CB_RENTAL_AGREEMENT_LIST,
        dataUrl: `/api/asset/list/${AssetTypes.Rental.RentalAgreement}`,
        hover: true,
        selection: "single",
        asPost: true,
        onRowClick: (node: RentalAgreement) => {},
        params: { aggregated },
        exportOptions: {
          filename: `rental-agreements_${moment().format("YYYY-MM-DD")}`,
        },
        columns: {
          "data.type": {
            label: i18n.t("Base.Unit"),
            sortable: true,
            flexWidth: 120,
            resizableOptions: {
              min: 40,
              max: 200,
            },
            resizable: true,
            render: (node: RentalAgreement, index, params) =>
              renderCellValue(
                node?.data?.type,
                "-",
                (value: string) => UnitStruct.getUnit(value)?.data.label
              ),
            renderFooter: (params) =>
              renderCellValue(
                `${i18n.t("cb:RentalAgreement.count", "Anz.")}: ${
                  params?.aggregated?.data?.["general"]?.count || "-"
                }`
              ),
            export: {
              width: 20,
              label: i18n.t("Base.Unit"),
              type: "string",
              selector: (node: RentalAgreement) =>
                LanguageService.translateLabel(
                  UnitStruct.getUnit(node?.data?.type)?.data.label
                ),
            },
          },
          "nested.entity": {
            label: i18n.t("Base.Entity"),
            // sortKey: "",
            flexWidth: 120,
            sortable: true,
            resizable: true,
            render: (node: RentalAgreement, index, params) =>
              renderCellValue(node?.nested?.entity, "-"),
            export: {
              width: 20,
              label: i18n.t("Base.Entity"),
              type: "string",
              selector: (node: RentalAgreement) => node?.nested?.entity,
            },
          },
          "nested.objectNumber": {
            label: i18n.t("Base.Object"),
            flexWidth: 200,
            resizable: true,
            sortable: true,
            render: (node: RentalAgreement, index, params) => (
              <LinkCell
                assetType={AssetTypes.Portfolio.Object}
                id={node?.data.objectId}
                text={`${node?.nested?.objectNumber} - ${node?.nested?.objectDisplayName}`}
                type={node?.data.type}
              />
            ),
            export: {
              width: 20,
              label: i18n.t("Base.Object"),
              type: "string",
              selector: (node: RentalAgreement) =>
                `${node?.nested?.objectNumber} - ${node?.nested?.objectDisplayName}`,
            },
          },
          ...RENTAL_AGREEMENT_FIELDS(),
        },
      }}
    />
  );
};

export default CBRentalAgreementsList;

export const RENTAL_AGREEMENT_FIELDS = (withObject = false) =>
  ({
    ...(withObject
      ? {
          "data.objectId": {
            label: i18n.t("cb:RentalVacancy.object", "Objekt"),
            // sortKey: "",
            flexWidth: 120,
            sortable: true,
            resizable: true,
            render: (node: RentalAgreement, index, params) => (
              <>
                {renderCellValue(
                  node?.data?.objectId,
                  "-",
                  (id: string) =>
                    `${OrgaStruct.getObject(id)?.id} - ${
                      OrgaStruct.getObject(id)?.displayName
                    }`
                )}
              </>
            ),
            export: {
              width: 20,
              label: i18n.t("cb:RentalVacancy.object", "Objekt"),
              type: "string",
              selector: (node: RentalAgreement) =>
                node?.data?.objectId
                  ? `${OrgaStruct.getObject(node?.data?.objectId)?.id} - ${
                      OrgaStruct.getObject(node?.data?.objectId)?.displayName
                    }`
                  : "-",
            },
          },
        }
      : {}),
    "data.id": {
      label: i18n.t("cb:RentalAgreement.id", "ID"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) => (
        <>
          <DebugDataComponent data={node} />
          {renderCellValue(node?.data?.id, "-")}
        </>
      ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.id", "ID"),
        type: "string",
        selector: (node: RentalAgreement) => node?.data?.id,
      },
    },
    "nested.tenantDisplayName": {
      label: i18n.t("cb:RentalAgreement.tenant", "Mieter"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) => (
        <LinkCell
          assetType={AssetTypes.Contact}
          id={node?.data.tenant}
          text={node?.nested?.tenantDisplayName || node?.data.tenant}
          type={node?.data.type}
        />
      ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.tenant", "Mieter"),
        type: "string",
        selector: (node: RentalAgreement) =>
          node?.nested?.tenantDisplayName || node?.data.tenant,
      },
      // renderCellValue(node?.nested?.tenantDisplayName, "-"),
    },
    "data.displayName": {
      label: i18n.t("cb:RentalAgreement.displayName", "Name"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.displayName, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.displayName", "Name"),
        type: "string",
        selector: (node: RentalAgreement) => node?.data?.displayName,
      },
    },

    "nested.unitTypeGroup": {
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      // sortKey: "",
      flexWidth: 200,
      sortable: false,
      resizable: true,
      render: (node: RentalAgreement, index, params) => {
        return (
          <div
            className={`unit-type-group-container`}
            style={{ maxWidth: "100%", overflow: "hidden" }}
          >
            {_.uniq(
              node.nested?.unitUnitType
                ?.map(
                  (unitType) => ObjectKindStruct.getUnitTypeBy(unitType)?.group
                )
                .filter((e) => e)
            ).map((group) => {
              const status = getConfigRentalUnitTypeGroup(group);
              if (status) {
                return (
                  <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                    <BFStatus
                      size="sm"
                      color={status.color}
                      label={status.label}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
        type: "string",
        selector: (node: RentalAgreement) =>
          _.uniq(
            node.nested?.unitUnitType
              ?.map(
                (unitType) => ObjectKindStruct.getUnitTypeBy(unitType)?.group
              )
              .filter((e) => e)
          ).join(", "),
      },
    },
    "nested.unitUnitType": {
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node.nested?.unitUnitType, "-", (value: string[]) =>
          _.uniq(
            value.map((e) =>
              LanguageService.translateLabel(
                ObjectKindStruct.getUnitTypeBy(e)?.displayName
              )
            )
          ).join(", ")
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitType", "Art"),
        type: "string",
        selector: (node: RentalAgreement) =>
          _.uniq(
            node.nested?.unitUnitType
              ?.map((unitType) =>
                LanguageService.translateLabel(
                  ObjectKindStruct.getUnitTypeBy(unitType)?.displayName
                )
              )
              .filter((e) => e)
          ).join(", "),
      },
    },

    "data.balance": {
      label: i18n.t("cb:RentalAgreement.balance", "Kontostand"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(
          node?.data?.balance,
          "-",
          (value: number) => StringUtils.formatCurrency(value * -1),
          false,
          undefined,
          CSSUtils.getColorClassForNumber(node?.data?.balance * -1)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.balance,
          "-",
          (value: number) => StringUtils.formatCurrency(value * -1),
          false,
          undefined,
          CSSUtils.getColorClassForNumber(
            params?.aggregated?.data?.["general"]?.balance * -1
          )
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.balance", "Kontostand"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node?.data?.balance * -1;
        },
      },
    },

    "data.rentNet": {
      label: i18n.t("cb:RentalAgreement.rentNet", "NKM netto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.rentNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.rentNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.rentNet", "NKM netto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node?.data?.rentNet;
        },
      },
    },
    "data.rentGross": {
      label: i18n.t("cb:RentalAgreement.rentGross", "NKM brutto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.rentGross, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.rentGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.rentGross", "NKM brutto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node?.data?.rentGross;
        },
      },
    },
    "data.operatingCostNet": {
      label: i18n.t("cb:RentalAgreement.operatingCostNet", "Nebenkosten netto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.operatingCostNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.operatingCostNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalAgreement.operatingCostNet",
          "Nebenkosten netto"
        ),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node?.data?.operatingCostNet;
        },
      },
    },
    "data.operatingCostGross": {
      label: i18n.t(
        "cb:RentalAgreement.operatingCostGross",
        "Nebenkosten brutto"
      ),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.operatingCostGross, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.operatingCostGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalAgreement.operatingCostGross",
          "Nebenkosten brutto"
        ),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node?.data?.operatingCostGross;
        },
      },
    },
    "data.deposit": {
      label: i18n.t("cb:RentalAgreement.deposit", "Kaution"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(
          _.sum((node?.data?.deposit || []).map((e) => e.depositValue)),
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.deposit,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.deposit", "Kaution"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return _.sum((node?.data?.deposit || []).map((e) => e.depositValue));
        },
      },
    },
    "nested.unitArea": {
      label: i18n.t("cb:RentalAgreement.unitArea", "Flache"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node.nested?.unitArea, "-", (value: number) =>
          StringUtils.formatArea(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.unitArea", "Flache"),
        type: "number",
        style: {
          numFmt: getDefaultAreaNumberFormat(),
        },
        selector: (node: RentalAgreement) => {
          return node.nested?.unitArea;
        },
      },
    },
    "nested.unitQuantity": {
      label: i18n.t("cb:RentalAgreement.unitQuantity", "Stellplätze/Sonstiges"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node.nested?.unitQuantity, "-"),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalAgreement.unitQuantity",
          "Stellplätze/Sonstiges"
        ),
        type: "number",
        selector: (node: RentalAgreement) => {
          return node.nested?.unitQuantity;
        },
      },
    },
    "data.moveIn": {
      label: i18n.t("cb:RentalAgreement.moveIn", "Einzug"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.moveIn, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.moveIn", "Einzug"),
        type: "date",
        selector: (node: RentalAgreement) => {
          return node?.data?.moveIn ? new Date(node?.data?.moveIn) : null;
        },
      },
    },
    "data.agreementExpiration": {
      label: i18n.t("cb:RentalAgreement.agreementExpiration", "Vertrag bis"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.agreementExpiration, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.agreementExpiration", "Vertrag bis"),
        type: "date",
        selector: (node: RentalAgreement) => {
          return node?.data?.agreementExpiration
            ? new Date(node?.data?.agreementExpiration)
            : null;
        },
      },
    },
    "data.moveOut": {
      label: i18n.t("cb:RentalAgreement.moveOut", "Auszug"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.moveOut, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.moveOut", "Auszug"),
        type: "date",
        selector: (node: RentalAgreement) => {
          return node?.data?.moveOut ? new Date(node?.data?.moveOut) : null;
        },
      },
    },
    "data.currentState": {
      label: i18n.t("cb:RentalAgreement.currentState", "Aktueller Status"),
      // sortKey: "",
      flexWidth: 220,
      sortable: true,
      resizable: true,
      render: (node: RentalAgreement, index, params) =>
        renderCellValue(node?.data?.currentState, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.currentState", "Aktueller Status"),
        type: "string",
        selector: (node: RentalAgreement) => {
          return node?.data?.currentState;
        },
      },
    },

    documentUnmetRequirements: {
      label: i18n.t(
        "cb:RentalAgreement.UnmetRequirements",
        "Fehlende Dokumente"
      ),
      flexWidth: 120,
      render: (node: RentalAgreement, key, columnConf) => {
        const assetParams: DocumentStoreAssetParams = {
          asset: node,
          type: node.data.type,
          assetType: AssetTypes.Rental.RentalAgreement,
          documentsFieldPath: "data.attachments",
        };
        const dsDocumentStoreDirectory =
          DSService.getDirectoryConfigurationForAsset(
            node.data.type,
            AssetTypes.Rental.RentalAgreement,
            "data.attachments"
          );
        const flattenDirectoryMap = DSService.getFlattenDirectoryMap(
          dsDocumentStoreDirectory
        );
        return renderCellValue(
          <DSUnmentRequirementsColumn
            onClick={(node: RentalAgreement) => {
              DataBus.emit(DataBusSubKeys.ROUTE, {
                append: true,
                route: `${node._id}/attachments`,
              });
            }}
            assetParams={assetParams}
            flattenDirectoryMap={flattenDirectoryMap}
            directory={dsDocumentStoreDirectory}
          />
        );
      },
    },
  } as { [key: string]: ColumnConfig });
