import pica from "pica";

class ImageUtilsClass {
  resizeBase64Image(sourceBase64: string, maxWidth: number, maxHeight: number) {
    return new Promise<string>((resolve, reject) => {
      var img = document.createElement("img");
      img.setAttribute("src", sourceBase64);
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var destCanvas = document.createElement("canvas");

        // reduce image size if greater than max size
        if (img.width > maxWidth || img.height > maxHeight) {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, img.width, img.height);

          var ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
          destCanvas.width = img.width * ratio;
          destCanvas.height = img.height * ratio;
          pica()
            .resize(canvas, destCanvas, {})
            .then((result) => {
              resolve(result.toDataURL());
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve(sourceBase64);
        }
      };
    });
  }
}
const ImageUtils = new ImageUtilsClass();
export default ImageUtils;
(window as any).ImageUtils = ImageUtils;
