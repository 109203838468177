import DataBus from "@/services/DataBus";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFOverlay from "../../general/whisper/BFOverlay";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import LabeledInput from "../LabeledInput";
import BFChooser, { BFChooserOption } from "./BFChooser";
import "./BFChooserSelect.scss";

interface BFChooserSelectProps {
  value: string;
  onChange: (value: string) => void;
  data: BFChooserOption[];
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  placeholder?: string;
  hideSubLabel?: boolean;
  overlayTopComponent?: (close: () => void) => React.ReactNode;
  overlayBottomComponent?: (close: () => void) => React.ReactNode;
  overlayClassName?: string;
  disabled?: boolean;
  groupSort?: (a: string, b: string) => number;
}
const BFChooserSelect = (props: BFChooserSelectProps) => {
  const [id] = useState(nanoid());
  const selectedValue = props.data.find((e) => e.value === props.value);
  return (
    <div className={classNames(`bf-chooser-select`)}>
      <LabeledInput
        label={props.label}
        info={props.info}
        labelPosition={props.labelPosition}
        suffix={props.labelSuffix}
        error={!!props.validation?.message}
      >
        <ValidationPopover
          validatorStyle={props.validatorStyle}
          level={props.validation ? props.validation.level : "error"}
          message={props.validation ? props.validation.message : null}
          marginTop={0}
        >
          <BFOverlay
            identifier={id}
            trigger="click"
            placement="bottomStart"
            speaker={
              <div
                className={`bf-chooser-select-overlay ${
                  props.overlayClassName || ""
                }`}
              >
                {props.overlayTopComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
                <BFChooser
                  groupSort={props.groupSort}
                  focusOnMount
                  maxHeight={"60vh"}
                  onChange={(value) => {
                    DataBus.emit("WHISPER", {
                      identifier: id,
                      type: "CLOSE",
                    });
                    props.onChange(value);
                  }}
                  options={props.data}
                />
                {props.overlayBottomComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
              </div>
            }
          >
            <div>
              <BFButton
                disabled={props.disabled}
                className={`bf-chooser-select-button`}
              >
                <div
                  className={classNames({
                    "select-value": true,
                    placeholder: !props.value,
                  })}
                >
                  {selectedValue && (
                    <>
                      {!props.hideSubLabel && (
                        <>
                          {selectedValue.subLabel && (
                            <span className={`sub-label`}>
                              {selectedValue.subLabel}
                            </span>
                          )}
                        </>
                      )}
                      {selectedValue.label}
                    </>
                  )}
                  {!selectedValue && (props.placeholder || "-")}
                </div>
                <BfIcon size="xxs" {...DefaultIcons.CHEVRON} />
              </BFButton>
            </div>
          </BFOverlay>
        </ValidationPopover>
      </LabeledInput>
    </div>
  );
};

export default BFChooserSelect;
