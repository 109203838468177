import { StructAction } from "../../actions/struct/struct-action-types";
import { AppState } from "../../store";
import { StructReducer } from "./StructInterface";

const initialState: StructReducer = {
  category: null,
  damageClaim: null,
  invoice: null,
  orderingProcess: null,
  project: null,
  unit: null,
  technicalUnit: null,
  maintenance: null,
  supplyContract: null,
  supplyUnit: null,
  insurance: null,
  orga: null,
  objectKind: null,
  vacancy: null,
};

export default function (
  state = initialState,
  action: StructAction,
  root: AppState
): StructReducer {
  switch (action.type) {
    case "STRUCT_CLEAR_CACHE": {
      if (action.structType) {
        return {
          ...state,
          [action.structType]: undefined,
        };
      } else {
        return initialState;
      }
    }
    case "STRUCT_SET_DATA": {
      const { structType, unitType, data } = action;
      return {
        ...state,
        [structType]: {
          ...state[structType],
          [unitType]: {
            state: "success",
            data: data,
          },
        },
      };
    }
    case "STRUCT_SET_ERROR": {
      const { structType, unitType, error } = action;
      return {
        ...state,
        [structType]: {
          ...state[structType],
          [unitType]: {
            state: "error",
            error: error,
          },
        },
      };
    }
    case "STRUCT_SET_LOADING": {
      const { structType, unitType } = action;
      return {
        ...state,
        [structType]: {
          ...state[structType],
          [unitType]: {
            state: "loading",
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
