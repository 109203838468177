import i18n from "@/i18n";
import { BFChooserOption } from "@/modules/abstract-ui/forms/chooser/BFChooser";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { reloadStructData } from "@/redux/actions/struct/struct-actions";
import { store } from "@/redux/store";
import CacheService from "@/services/CacheService";
import LanguageService, { TranslatedLabel } from "@/services/LanguageService";
import ServiceUtils from "@/services/ServiceUtils";
import { HTTP } from "@/utils/Http";
import { AccountingData } from "./AccountingLoader";
import {
  AccountType,
  AccountingAccountDataAccount,
  AccountingBooking,
  AccountingBookingFormValue,
  AccountingBookingType,
} from "./interfaces/account.interface";

export type AccountingSheetFormValue = {
  displayName: TranslatedLabel;
  entity: string;
  id: string;
  thirdPartyId: string;
};

export type AccountBookingEntry = {
  value: {
    amount: number;
    currency: string;
  };

  description: string;

  contraAccount: string;
  bookingType: "S" | "H";

  linkedCountraBooking: string | null;

  note: string | null;
};
export type AccountingBookingFormValueOld = {
  groupDisplayName: string;
  entity: string;
  date: Date;
  account: string;
  objectId: string | null;
  bookings: AccountBookingEntry[];

  // account: string; // x
  // contraAccount: string; // x
  // // entity: string; // x
  // bookingDate: Date;
  // performanceDate: Date | null;
  // invoiceDate: Date | null;
  // description: string;
  // bookingType: "S" | "H";
  // BU: number;

  // invoiceField1?: string;
  // invoiceField2?: string;
  // costCenter1?: string;
  // costCenter2?: string;
  // note: string;
};

class AccountingServiceClass {
  async createBooking(value: AccountingBookingFormValue, lqBookingId?: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: lqBookingId
          ? `/api/accounting/createBookingFromBank`
          : `/api/accounting/createBooking`,
        bodyParams: {
          lqBookingId: lqBookingId,
          groupDisplayName: value.groupDisplayName,
          entity: value.entity,
          date: value.date,
          account: value.account,
          note: value.note || "",
          linkedAsset: value.linkedAsset || [],
          bookings: value.frames
            .map((frame) =>
              frame.bookings.map(({ costAccount, ...booking }) => ({
                ...booking,
                objectId: frame.objectId,
                contraAccount: frame.contraAccount,
                bookingType: booking.bookingType === "S" ? "H" : "S",
              }))
            )
            .flat(),
        },
      });
    });
  }

  getAccountByObjectAndType(
    accounting: AccountingData,
    objectId: string,
    type: AccountType,
    additionalFilter?: (e: AccountingAccountDataAccount) => boolean
  ) {
    return accounting.accounting.data.accounts.find(
      (e) =>
        e.objectId === objectId &&
        e.accountType === type &&
        (additionalFilter ? additionalFilter(e) : true)
    );
  }
  getAccountByType(
    accounting: AccountingData,
    type: AccountType,
    additionalFilter?: (e: AccountingAccountDataAccount) => boolean
  ) {
    return accounting.accounting.data.accounts.find(
      (e) =>
        e.accountType === type &&
        (additionalFilter ? additionalFilter(e) : true)
    );
  }

  submitBooking = async (booking: AccountingBookingFormValueOld) => {
    const result = (await HTTP.post({
      url: `/api/accounting/createBooking`,
      target: "EMPTY",
      bodyParams: {
        ...booking,
      },
    })) as AccountingBooking;

    CacheService.update(result);

    return result;
  };
  getCorrectedBooking = (forAccount: string, booking: AccountingBooking) => {
    if (booking.data.contraAccount === forAccount) {
      return {
        ...booking,
        data: {
          ...booking.data,
          account: booking.data.contraAccount,
          contraAccount: booking.data.account,
          bookingType:
            booking.data.bookingType === AccountingBookingType.SOLL
              ? AccountingBookingType.HABEN
              : AccountingBookingType.SOLL,
        },
      };
    }
    return booking;
  };
  getAccountTypeLabel = (accountType: AccountType) => {
    switch (accountType) {
      case AccountType.debitor_deposit:
        return i18n.t("acc:Nav.AccountSheetDeposit", "Kaution");
      case AccountType.debitor_loss:
        return i18n.t("acc:Nav.AccountSheetLoss", "Umsatzausfall");
      case AccountType.debitor_rentalagreement:
        return i18n.t("acc:Nav.AccountSheetRentalAgreements", "Mieter");
      case AccountType.debitor_rentalposition:
        return i18n.t("acc:Nav.AccountSheetRentalPosition", "Sollstellung");
      case AccountType.debitor_other:
        return i18n.t("acc:Nav.AccountSheetOther", "Weitere");
      case AccountType.bank:
        return i18n.t("acc:Nav.AccountBank", "Bank");
      default:
        return (accountType as any).toString();
    }
  };
  getAccountsOfEntityGrouped = (
    accounting: AccountingData,
    ignoreAccounts?: string[],
    filterToTypes?: AccountType[]
  ) => {
    return accounting.accounting.data.accounts
      .filter((e) => !(ignoreAccounts || []).includes(e.id))
      .filter((e) =>
        filterToTypes ? filterToTypes.includes(e.accountType) : true
      )
      .map(
        (account) =>
          ({
            label: LanguageService.translateLabel(account.displayName),
            subLabel: account.internalId,
            value: account.id,
            group: this.getAccountTypeLabel(account.accountType),
          } as BFChooserOption)
      );
  };

  getEntitySelectOptions = (unitTypes: string[], usesAccounting: boolean) =>
    OrgaStruct.getEntities(unitTypes)
      .filter((entity) => entity.usesAccounting === usesAccounting)
      .map((entity) => ({
        label: LanguageService.translateLabel(entity.displayName),
        value: entity._id,
        group: UnitStruct.getUnitLabel(entity.type),
        subLabel:
          entity.objects?.length > 0
            ? entity.objects
                ?.map((obj) => LanguageService.translateLabel(obj.displayName))
                .join(", ")
            : undefined,
      }));

  async createAccountForEntity(
    entity: string,
    startOfAccounting: Date,
    currency: string
  ) {
    const result = await HTTP.post({
      url: `/api/accounting/createAccountingForEntity`,
      target: "EMPTY",
      bodyParams: {
        entity,
        startOfAccounting,
        currency,
      },
    });
    await store.dispatch(reloadStructData("orga"));
  }

  async submitAccountingSheet(
    form: AccountingSheetFormValue,
    accountType: AccountType,
    accounting: AccountingData
  ) {
    return await ServiceUtils.toastError(async () => {
      let url;
      if (accountType === AccountType.debitor_other) {
        url = "/api/accounting/createDebitorAccountOther";
      }

      const result = await HTTP.post({
        url: url || "/api/accounting/createDebitorAccount",
        target: "EMPTY",
        bodyParams: {
          ...form,
          entity: accounting.accounting.data.entity,
        },
      });

      return result;
    });
  }
}
const AccountingService = new AccountingServiceClass();
export default AccountingService;
