import { CSSProperties } from "@emotion/serialize";
import autosize from "autosize";
import { css } from "emotion";
import React, { SyntheticEvent } from "react";
import MaskedInput from "react-text-mask";
import { Input, InputGroup } from "rsuite";
import * as TextMaskAddons from "text-mask-addons";
import { IComponent } from "../../../../configurable/layouts/IComponent";
import IBAN from "../../../../lib-overwrites/iban/iban";
import { DataBusSubKeys } from "../../../../utils/Constants";
import StringUtils from "../../../../utils/StringUtils";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates,
} from "../../../../utils/abstracts/AbstractComponent";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";
import "./BFInput.scss";

const DEFAULT_IBAN_MASK = [
  /[A-Za-z]/,
  /[A-Za-z]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
  " ",
  /[a-za-z0-9]/,
  /[a-za-z0-9]/,
];

export type BFInputAppearance = "default" | "clear";
export type BFInputProps = {
  step?: number;
  mask?: any;
  appearance?: BFInputAppearance;
  label?: string;
  labelSuffix?: React.ReactNode;
  labelPosition?: "top" | "left";
  tabIndex?: number;
  name?: string;
  type?: string;
  size?: "lg" | "md" | "sm" | "xs";
  onChange?: (value: string | number, ev?: Event) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode | string | IComponent;
  suffix?: React.ReactNode | string | IComponent;
  prefixOnClick?: () => void;
  suffixOnClick?: () => void;
  addonsInside?: boolean;
  currencySuffix?: string;
  value?: string | number;
  decimalFixed?: number;
  max?: number;
  min?: number;
  rows?: number;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  info?: React.ReactNode;
  validatorStyle?: ValidatorPopoverStyle;
  style?: CSSProperties;
  focusOnMount?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  spellCheck?: boolean;
  autoResize?: boolean;
  textAlign: "left" | "center" | "right";
  maskOverwrite?: any;
  allowNull?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  useTempValue?: boolean;
  readonly?: boolean;
  removeSuffixPadding?: boolean;
  removePrefixPadding?: boolean;
  hint?: string;
  autosize?: boolean;
} & AbstractProps;

type States = {
  tempValue: any;
} & AbstractStates;

export const DEFAULT_MASKS = {
  priceInput: "priceInput",
  ibanInput: "ibanInput",
};

const DEFAULT_MASK_OPTIONS = () => ({
  [DEFAULT_MASKS.priceInput]: {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: StringUtils.getThousandsSeparator(),
    allowDecimal: true,
    decimalSymbol: StringUtils.getDecimalSeparator(),
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 14, // limit length of integer numbers
    allowNegative: true,
    allowLeadingZeroes: false,
  },
});
class BFInput extends AbstractComponent<BFInputProps, States> {
  static defaultProps = {
    appereance: "default",
    autoComplete: "off",
    textAlign: "left",
  };
  readonly state: States = {
    tempValue: this.props.value || "",
  };

  inputRef;

  DEFAULT_MASK_PROPS_OVERRIDE = {
    [DEFAULT_MASKS.priceInput]: (props: BFInputProps) => {
      return {
        useTempValue: true,
        onFocus: (event: any) => {
          props.onFocus?.();
          (event?.target as HTMLInputElement)?.select();
        },
        onBlur: (event: any) => {
          const value = event.target.value;
          if (value === "-_") {
            props.onChange?.(null, event);
            this.setState({ tempValue: null });
          } else {
            props.onChange?.(StringUtils.parseNumber(value) || 0, event);
            setTimeout(() => {
              this.setState({ tempValue: this.props.value }); //)StringUtils.parseNumber(value) || 0 });
            });
          }
          if (props.onBlur) {
            props.onBlur();
          }

          event.preventDefault();
        },
        onKeyDown: (event: any) => {
          const value = event.target.value;
          if (event.key === "Enter") {
            this.setState({ tempValue: null });
            if (value === "-_") {
              props.onChange?.(null, event);
              this.setState({ tempValue: null });
            } else {
              props.onChange?.(StringUtils.parseNumber(value) || 0, event);
              this.setState({ tempValue: StringUtils.parseNumber(value) || 0 });
            }
            if (props.onBlur) {
              props.onBlur();
            }
          }
          if (props.onKeyDown) {
            props.onKeyDown(event);
          }
        },
        onChange: (event: any) => {
          const value = event.target.value;

          try {
            if (props.allowNull && value === "") {
              // props.onChange?.(null, event);
              this.setState({ tempValue: null });
            } else {
              if (value === "" || value === "0") {
                setTimeout(() => {
                  event.target.select();
                }, 10);
              }
              this.setState({ tempValue: value });
            }
          } catch (err) {
            if (
              err.message ===
              "Cannot read properties of undefined (reading 'updater')"
            ) {
              //FIXME dirty fix for ungood usage of setState
              return;
            }
            throw err;
          }
        },
        value:
          // undefined,
          props.allowNull && this.state.tempValue === null
            ? null
            : typeof this.state.tempValue === "string"
            ? this.state.tempValue
            : StringUtils.formatNumber(
                this.state.tempValue,
                undefined,
                undefined,
                2
              ),
        mask: TextMaskAddons.createNumberMask({
          ...DEFAULT_MASK_OPTIONS()[DEFAULT_MASKS.priceInput],
          ...(props.maskOverwrite ? props.maskOverwrite : {}),
          suffix: props.currencySuffix ? ` ${props.currencySuffix}` : "",
        }),
      };
    },
    [DEFAULT_MASKS.ibanInput]: (props: BFInputProps) => {
      const value = props.value as string;
      let ibanMaskArr = DEFAULT_IBAN_MASK;

      if (value?.length > 2) {
        ibanMaskArr = [
          /[A-Za-z]/,
          /[A-Za-z]/,
          /[a-za-z0-9]/,
          /[a-za-z0-9]/,
          " ",
        ];
        const countryCode = value.slice(0, 2).toUpperCase();

        const additionalStructure = IBAN.getIbanStructureByCountry(countryCode);
        if (additionalStructure) {
          const additionalStructureLength = additionalStructure.length;
          for (let i = 0; i < additionalStructureLength; i++) {
            if (i % 4 === 0 && i !== 0) {
              ibanMaskArr.push(" ");
            }
            ibanMaskArr.push(additionalStructure[i]);
          }
        }
      }

      return {
        onChange: (event: any) => {
          const value = event.target.value
            ?.toUpperCase()
            .replace(/_/g, "")
            .trim();
          props.onChange?.(StringUtils.ibanToISO(value) || value, event);
        },
        value: StringUtils.formatIban(props.value as string),
        mask: ibanMaskArr,
      };
    },
  };
  componentDidMount() {
    if (this.props.focusOnMount) {
      this.focus();
    }
    if (this.props.useTempValue || this.props.type === "priceInput") {
      this.setState({
        tempValue: this.props.value,
      });
    }

    if (this.props.autoResize) {
      this.inputRef.style.cssText = "height:auto; padding:14px";
      this.inputRef.style.cssText = `height:${this.inputRef.scrollHeight}px`;
    }

    this.subscribe(DataBusSubKeys.FOCUS, (data) =>
      data.id === this.props.identifier ? this.focus() : undefined
    );

    if (this.props.type === "textarea" && this.props.autosize) {
      if (this.inputRef) {
        autosize(this.inputRef);
      }
    }
  }
  componentDidUpdate(prevProps: BFInputProps) {
    if (
      (this.props.useTempValue || this.props.type === "priceInput") &&
      this.props.value !== prevProps.value
    ) {
      this.setState({
        tempValue: this.props.value,
      });
    }
    if (this.props.autoResize) {
      if (this.props.autoResize) {
        this.inputRef.style.cssText = "height:auto; padding:14";
        this.inputRef.style.cssText = `height:${this.inputRef.scrollHeight}px`;
      }
    }
  }
  shouldComponentUpdate(nextProps: BFInputProps, nextStates: States) {
    if (this.props.value !== nextProps.value) {
      return true;
    }
    return super.shouldComponentUpdate(nextProps, nextStates);
  }
  focus() {
    if (this.inputRef) {
      this.inputRef.focus();
      setTimeout(() => {
        this.inputRef?.select();
      });
    }
  }

  render() {
    const {
      mask,
      validatorStyle,
      label,
      labelPosition,
      type,
      size,
      onChange,
      disabled,
      defaultValue,
      className,
      placeholder,
      prefix,
      suffix,
      suffixOnClick,
      prefixOnClick,
      addonsInside,
      value,
      decimalFixed,
      min,
      max,
      validation,
      onBlur,
      onFocus,
      tabIndex,
      style,
      autoComplete,
      name,
      textAlign,
      autoCorrect,
      autoCapitalize,
      spellCheck,
      appearance,
      onKeyDown,
      onKeyUp,
      onKeyPress,
      step,
      readonly,
    } = this.props;

    let useStep = step;
    if (decimalFixed) {
      useStep = Math.pow(0.1, decimalFixed);
    }

    let labelTranslated;
    if (label) {
      labelTranslated = (window as any).translate(label);
    }
    let placeholderTranslated;
    if (placeholder) {
      placeholderTranslated = (window as any).translate(placeholder);
    }

    let usePrefix;
    let prefixComponent = false;
    if (prefix) {
      if (typeof prefix === "string") {
        usePrefix = (window as any).translate(prefix);
      } else if ((prefix as any)._component) {
        prefixComponent = true;
        usePrefix = (window as any).ComponentsMapper.createElement(prefix, {
          value: this.props.value,
          focused: this.state.params ? this.state.params.focused : false,
          ...(this.props.params ? this.props.params : {}),
        });
      } else {
        usePrefix = prefix;
      }
    }
    let useSuffix;
    let suffixComponent = false;
    if (suffix) {
      if (typeof suffix === "string") {
        useSuffix = (window as any).translate(suffix);
      } else if ((suffix as any)._component) {
        suffixComponent = true;
        useSuffix = (window as any).ComponentsMapper.createElement(suffix, {
          value: this.props.value,
          focused: this.state.params ? this.state.params.focused : false,
          ...(this.props.params ? this.props.params : {}),
        });
      } else {
        useSuffix = suffix;
      }
    }

    const isMask = Object.keys(DEFAULT_MASKS).indexOf(type) !== -1 || mask;

    return (
      <LabeledInput
        label={labelTranslated}
        info={this.props.info}
        labelPosition={labelPosition}
        suffix={this.props.labelSuffix}
        error={!!validation?.message}
      >
        <ValidationPopover
          validatorStyle={validatorStyle}
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
          marginTop={0}
        >
          <InputGroup
            className={`bf-input ${readonly ? "readonly" : ""} ${
              validation?.message ? "error" : ""
            } ${className ? className : ""} appearance-${
              appearance ? appearance : "default"
            } ${
              prefixComponent || this.props.removePrefixPadding
                ? "remove-prefix-padding"
                : ""
            } ${
              suffixComponent || this.props.removeSuffixPadding
                ? "remove-suffix-padding"
                : ""
            } `}
            size={size}
            disabled={disabled}
            inside={addonsInside}
          >
            {usePrefix && prefixOnClick ? (
              <InputGroup.Button onClick={prefixOnClick} className={`prefix`}>
                {usePrefix}
              </InputGroup.Button>
            ) : usePrefix && !prefixOnClick ? (
              <InputGroup.Addon className={`prefix`}>
                {usePrefix}
              </InputGroup.Addon>
            ) : null}
            {!isMask ? (
              <Input
                onCopy={(e) => {
                  // console.log("copy input", document.getSelection().toString());
                }}
                readOnly={readonly}
                className={`${type} text-align-${textAlign} ${
                  readonly ? "readonly" : ""
                } ${style ? css(style as any) : ""}`}
                autoComplete={autoComplete}
                autoCorrect={autoCorrect}
                autoCapitalize={autoCapitalize}
                spellCheck={spellCheck}
                inputRef={(input) => (this.inputRef = input)}
                tabIndex={tabIndex}
                type={type}
                rows={this.props.rows}
                as={type === "textarea" ? "textarea" : undefined}
                name={name}
                placeholder={placeholderTranslated}
                value={this.props.useTempValue ? this.state.tempValue : value}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onChange={(
                  value: string,
                  event: SyntheticEvent<HTMLElement, Event>
                ) => {
                  if (this.props.useTempValue) {
                    this.setState({ tempValue: value });
                  } else {
                    if (type === "number") {
                      if (value === "") {
                        onChange("", event.nativeEvent);
                      } else {
                        let number = Number(value);
                        if (decimalFixed !== undefined) {
                          number = Number(number.toFixed(decimalFixed));
                        }
                        onChange(number, event.nativeEvent);
                      }
                    } else {
                      onChange(value, event.nativeEvent);
                    }
                  }
                  // onChange ? onChange(Number(value), event.nativeEvent) : null
                }}
                disabled={disabled}
                defaultValue={defaultValue}
                step={useStep}
                min={min}
                max={max}
                onBlur={(e) => {
                  if (this.props.useTempValue) {
                    this.props.onChange?.(this.state.tempValue);
                  }
                  if (onBlur) {
                    onBlur();
                  }
                  this.setState({
                    params: { ...this.state.params, focused: false },
                  });
                }}
                onFocus={() => {
                  if (onFocus) {
                    onFocus();
                  }
                  this.setState({
                    params: { ...this.state.params, focused: true },
                  });
                }}
              />
            ) : (
              <MaskedInput
                onCopy={(e) => {
                  const copyValue = document.getSelection().toString();
                  const asNumber = StringUtils.parseNumber(copyValue);
                  if (asNumber) {
                    e.preventDefault();
                    e.stopPropagation();
                    navigator.clipboard.writeText(
                      StringUtils.formatNumberSimple(
                        Number(asNumber)
                      ).toString()
                    );
                  }
                }}
                type={"text"}
                name={name}
                autoComplete={autoComplete}
                autoCorrect={autoCorrect}
                autoCapitalize={autoCapitalize}
                value={value}
                placeholder={placeholderTranslated}
                onChange={(e) => onChange(e.target.value, e as any)}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                onBlur={(e) => {
                  if (onBlur) {
                    onBlur();
                  }
                  this.setState({
                    params: { ...this.state.params, focused: false },
                  });
                }}
                onFocus={() => {
                  if (onFocus) {
                    onFocus();
                  }
                  this.setState({
                    params: { ...this.state.params, focused: true },
                  });
                }}
                disabled={disabled}
                defaultValue={defaultValue}
                step={step}
                min={min}
                max={max}
                ref={(input) => {
                  if (input && input.inputElement) {
                    this.inputRef = input.inputElement;
                  }
                }}
                tabIndex={tabIndex}
                className={`rs-input text-align-${textAlign} ${type} ${
                  readonly ? "readonly" : ""
                } ${style ? css(style as any) : ""}`}
                mask={this.props.mask}
                {...(this.DEFAULT_MASK_PROPS_OVERRIDE[this.props.type]
                  ? this.DEFAULT_MASK_PROPS_OVERRIDE[this.props.type](
                      this.props
                    )
                  : {})}
                readOnly={readonly}
              />
            )}
            {useSuffix && suffixOnClick ? (
              <InputGroup.Button onClick={suffixOnClick} className={`suffix`}>
                {useSuffix}
              </InputGroup.Button>
            ) : useSuffix && !suffixOnClick ? (
              <InputGroup.Addon className={`suffix`}>
                {useSuffix}
              </InputGroup.Addon>
            ) : null}
          </InputGroup>
          {this.props.hint && (
            <div className={`input-hint`}>{this.props.hint}</div>
          )}
        </ValidationPopover>
      </LabeledInput>
    );
  }
}

export default BFInput;
