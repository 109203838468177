import { TypedBaseAsset } from "@/model/general-assets/BaseAsset";
import { CurrencyValueWithConversion } from "@/modules/abstract-ui/forms/input/BFCurrency";
import { DocumentStoreDocument } from "@/modules/document-store/DSInterfaces";
import { TranslatedLabel } from "@/services/LanguageService";
import { LinkedAsset } from "../../cashBudget/model/CashBudgetEntry";
import { OpenDebitPositionValue } from "../../cashBudget/views/tenants/components/rental-agreements/accounting/CBRentalAgreementOpenDebitPositions";

export enum AccountingBookingType {
  SOLL = "S",
  HABEN = "H",
}

export type AccountingBookingData = {
  linkedObject: string | null; // linked portfolio object
  costId: string | null; // linked gegenbuchung w/e

  notes: {
    text: string;
    userId: string;
    date: Date;
  }[];

  value: CurrencyValueWithConversion;
  date: Date; // this is bookingdate if performanceDate is not set
  // bookingDate: Date; // bookingDate
  performanceDate: Date; // Leistungsdatum
  description: string;
  account: string;
  contraAccount: string;
  bookingType: AccountingBookingType;

  // dont we need that?
  // exportObjectId: string;

  // BU: number; // Buchungsschlüssel
  // GU: boolean; // Generalumkehr
  // invoice: {
  //   date: Date;
  //   field1: string;
  //   field2: string;
  // };

  // costCenter1: string;
  // costCenter2: string;

  groupId: string | null; // wenn eine Buchung weitere Buchungungen auslöst, wie Ust, hat man dieselbe groupId

  entity: string;
  type: string;
  attachments: DocumentStoreDocument[];

  //iberio links
};

export type AccountingAccountDataAccount = {
  id: string;
  internalId: string;
  displayName: TranslatedLabel;
  accountType: AccountType;
  priority?: number;
  taxAccount?: {
    taxRate: number;
  };
  objectId?: string;
  status: "active" | "archived";
  assetLink?: LinkedAsset;
  thirdPartyId: string;
  sumHaben: number;
  sumSoll: number;
  sumBalance: number;
  detailBalance: {
    [accountNumber: string]: {
      sumSoll: number;
      sumHaben: number;
      sumBalance: number;
    };
  };
};

export type AccountingAccountData = {
  startOfAccounting: Date;
  entity: string;
  type: string;
  currency: string;
  status: "active" | "archived";
  accounts: AccountingAccountDataAccount[];
};

export type AccountingTemplateData = {
  displayName: TranslatedLabel;
  status: "active" | "archived";
  accounts: AccountingAccountData[];
};

export type AccountingBookingGroupData = {
  account: string;
  balance: number;
  booked: {
    soll: number;
    haben: number;
    balance: number;
  };
  sum: {
    soll: number;
    haben: number;
    balance: number;
  };
  date: Date;
  displayName: string;
  entity: string;
  objectId: string;
  note: {
    date: Date;
    text: string;
    userId: string;
  };
  linkedAsset: LinkedAsset[];
};
export type AccountingAccount = TypedBaseAsset<AccountingAccountData>;

export type AccountingTemplate = TypedBaseAsset<AccountingTemplateData>;
export type AccountingBooking = TypedBaseAsset<AccountingBookingData>;
export type AccountingBookingGroup = TypedBaseAsset<AccountingBookingGroupData>;

export enum AccountType {
  bank = "bank",
  debitor_rentalagreement = "debitor_rental",
  debitor_rentalposition = "debitor_rentalposition",
  debitor_deposit = "debitor_deposit",
  debitor_loss = "debitor_loss",
  debitor_other = "debitor_other",
  debitor_rent_reduction = "debitor_rent_reduction",
  debitor_rent_loss = "debitor_rent_loss",
}

export const isAccountTypeGenerated = (accountType: AccountType): boolean => {
  return [
    AccountType.debitor_rentalagreement,
    AccountType.debitor_rentalposition,
    AccountType.bank,
  ].includes(accountType);
};

export type AccountingBookingFormValue = {
  groupDisplayName: string;
  date: Date;
  entity: string;
  account: string;
  fullAmount: number;
  note: string;
  linkedAsset: {
    assetType: string;
    assetId: string;
    extra?: any;
  }[];
  frames: {
    objectId?: string;
    contraAccount: string;
    bookings: OpenDebitPositionValue[];
  }[];
};
