import i18n from "@/i18n";
import ServiceUtils from "@/services/ServiceUtils";
import _ from "lodash";
import moment from "moment";
import { nanoid } from "nanoid";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import CacheService from "../../../../../services/CacheService";
import ContactService from "../../../../../services/ContactService";
import DataBus from "../../../../../services/DataBus";
import SubmitService from "../../../../../services/SubmitService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { HTTP } from "../../../../../utils/Http";
import {
  PaymentPosition,
  RentalAgreement,
  RentalAgreementTimePeriod,
  RentalAgreementUnit,
  RentalUnit,
  RentalUnitFormValue,
  RentalVacancy,
  RentalVacancyAdvertisement,
  SubmitObjectPlanData,
} from "./TenantsInterfaces";
import { RentalOpos } from "./components/rental-opos/OposInterfaces";

class CBRentalServiceClass {
  async updateVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    id: string,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).map(
            (advertismement) =>
              advertismement.id === id
                ? { ...advertismement, ...data }
                : advertismement
          ),

          // [
          //   ...rentalVacancy.data.advertisements,
          //   { ...data, id: nanoid() },
          // ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async addVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: [
            ...(rentalVacancy.data.advertisements || []),
            { ...data, id: nanoid() },
          ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async removeVacantAdvertisement(rentalVacancy: RentalVacancy, id: string) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).filter(
            (e) => e.id !== id
          ),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }

  async addTimePeriodToAgreement(
    agreementId: string,
    data: {
      from: Date;
      to?: Date;
      paymentPositions: PaymentPosition[];
      reason: string;
      comment?: string;
      rentalUnits: string[];
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/addTimePeriod`,
      bodyParams: {
        rentalUnitIds: data.rentalUnits,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        paymentPositions: data.paymentPositions,
        reason: data.reason,
        comment: data.comment,
      },
      target: "EMPTY",
    });
    return result;
  }

  async updateRentOfAgreeement(
    agreementId,
    data: {
      rentNet: number;
      rentGross: number;
      operatingCostNet: number;
      operatingCostGross: number;
      from: Date;
      to: Date;
      reason: string;
      comment: string;
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRent`,
      bodyParams: {
        rentNet: data.rentNet,
        rentGross: data.rentGross,
        operatingCostNet: data.operatingCostNet,
        operatingCostGross: data.operatingCostGross,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        // reason: data.reason,
        // comment: data.comment,
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async updateRentalUnitsOfAgreement(
    agreementId: string,
    rentalUnits: RentalAgreementUnit[]
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRentalUnit`,
      bodyParams: {
        rentalUnitsInfo: rentalUnits.map((e) => ({
          rentalUnitId: e.rentalUnitId,
          from: moment(e.from).toISOString(),
          to: e.to ? moment(e.to).toISOString() : undefined,
        })),
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async submitRentalAgreement(values: any, rentalAgreement?: RentalAgreement) {
    return await ServiceUtils.toastError(
      async () => {
        const data = {
          type: values.type,
          rentalUnits: values.rentalUnits.map((e) => e.assetId),
          tenant: values.tenant,
          id: values.id,
          displayName: values.displayName,
          moveIn: moment(values.moveIn).toISOString(),
          moveOut: values.moveOut ? moment(values.moveOut).toISOString() : null,
          agreementExpiration: values.agreementExpiration
            ? moment(values.agreementExpiration).toISOString()
            : null,
          paymentPositions: values.paymentPositions?.map((position) => ({
            id: position.type,
            net: position.net,
            gross: values.taxable ? position.gross : 0,
          })),
          rentPaymentTimingOptions: values.rentPaymentTimingOptions,
          // rentNet: values.rentNet,
          // rentGross: values.taxable ? values.rentGross : 0,
          // operatingCostNet: values.operatingCostNet,
          // operatingCostGross: values.taxable ? values.operatingCostGross : 0,
          taxable: values.taxable === "taxable" ? true : false,
          deposit: values.deposit || [],
          note: values.note,
        };

        // let newStatus: RentalStatus = null;
        // if (moment(values.moveIn).isBefore(moment())) {
        //   newStatus = "occupied";
        // } else {
        //   newStatus = "reserved";
        // }
        // for (const rentalUnit of values.rentalUnits) {
        //   await SubmitService.submitDataAsync({
        //     type: "asset",
        //     assetType: AssetTypes.Rental.RentalUnit,
        //     data: {
        //       _id: rentalUnit.assetId,
        //       data: {
        //         rentalStatus: newStatus,
        //       },
        //     },
        //     pushToCache: true,
        //     ignorePropChecks: true,
        //     ignoreSubmitValidation: true,
        //   });
        // }
        let result;
        if (rentalAgreement) {
          throw new Error("not implemented");
        } else {
          result = await HTTP.post({
            url: `/rental/${values.objectId}/createRentalAgreement`,
            target: "EMPTY",
            bodyParams: data,
          });
        }

        DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
          objectId: result.data.objectId,
        });

        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_OCCUPIED",
          "RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_OCCUPIED") {
            const periodIds = errData.message;
            const timePeriods: RentalAgreementTimePeriod[] = await Promise.all(
              periodIds.map((periodId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalAgreementPeriod,
                  id: periodId,
                })
              )
            );

            const affectedTimeperiods = timePeriods
              .map((period) => period.data.rentalUnitIds)
              .flat();
            const overlappingRentalUnitIds = _.intersection(
              affectedTimeperiods,
              values.rentalUnits
            );

            const units: RentalUnit[] = await Promise.all(
              overlappingRentalUnitIds.map((unitId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalUnit,
                  id: unitId,
                })
              )
            );
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_OCCUPIED",
              "Folgende Mieteinheiten sind bereits vermietet:"
            )}\n${units
              .map((unit) => `${unit.data.id} - ${unit.data.displayName}`)
              .join("\n")}`;
          }
          if (errData.code === "RENTAL_AGREEMENT_ID_ALREADY_EXISTS") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
              "Mietvertrag mit der ID bereits vorhanden"
            )}`;
          }
        }
        return null;
      }
    );
  }
  async submitTenant(values: any, tenant?: Contact) {
    const { helpers, ...formValues } = values;
    return await ContactService.submitContact(
      { contactType: ["TENANT"], ...formValues },
      tenant?._id
    );
  }
  async submitRentalUnit(values: any, rentalUnit?: RentalUnit) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalUnit,
      data: {
        _id: rentalUnit?._id,
        data: {
          ...values,
          ...(rentalUnit
            ? {}
            : {
                // initial value on creation
                rentalStatus: "vacant",
                status: "active",
              }),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalUnit;

    DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
      objectId: result.data.objectId,
    });
    return result;
  }

  async submitRentalOpos(values: any, rentalOpos: RentalOpos) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: rentalOpos?._id,
        data: {
          ...values,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async closeRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "archived",
          closed: moment().toISOString(),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async reopenRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "active",
          closed: null,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }

  async submitRentalUnitPlanData(
    data: SubmitObjectPlanData[],
    reload?: { assetType: string; id: string }
  ) {
    const result = await HTTP.post({
      url: `/rental/changeMultipleRentalUnits`,
      bodyParams: {
        data: data,
      },
      target: "EMPTY",
    });

    if (reload) {
      CacheService.getData({
        assetType: reload.assetType,
        oType: "asset",
        id: reload.id,
        forceReload: true,
        ignoreDelay: true,
      });
    }
  }

  async submitRentalUnits(
    objectId: string,
    rentalUnits: RentalUnitFormValue[]
  ) {
    const unitsCleared = rentalUnits.map(({ unitGroup, ...unit }) => ({
      ...unit,
      building: unit.building || "",
    }));
    const result = await HTTP.post({
      url: `/rental/${objectId}/createNewRentalUnits`,
      bodyParams: {
        rentalUnits: unitsCleared,
      },
      target: "EMPTY",
    });

    return result;
  }
}
const CBRentalService = new CBRentalServiceClass();
export default CBRentalService;
