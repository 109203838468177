import { Contact } from "../../../../../model/db/Contact";
import BaseAsset from "../../../../../model/general-assets/BaseAsset";
import { DocumentStoreDocument } from "../../../../../modules/document-store/DSInterfaces";
import { TranslatedLabel } from "../../../../../services/LanguageService";

export type RentalUnitTypeGroup =
  | "commercial"
  | "residental"
  | "parking"
  | "storage"
  | "other";
export const allRentalUnitTypeGroups: RentalUnitTypeGroup[] = [
  "commercial",
  "residental",
  "parking",
  "storage",
  "other",
];

export type RentalStatus = "occupied" | "vacant" | "reserved" | "blocked";
export const allRentalStatus: RentalStatus[] = [
  "occupied",
  "vacant",
  "reserved",
  "blocked",
];
type RentalUnitData = {
  id: string;
  displayName: TranslatedLabel;
  entity: string;
  objectId: string;
  type: string;
  unitType: string; // id RentalUnitType
  floor?: number;
  building?: string;
  note?: string;
  // if unittype group is parking or other
  quantity: number;

  // if unittype group is commercial or residental
  area: number;

  lastPlandataChangeDate?: Date;
  lastPlandataChangeUser?: string;

  rentGross: number;
  rentNet: number;
  operatingCostGross: number;
  operatingCostNet: number;

  rentalStatus: RentalStatus;
  status: "active" | "archived";
  administratedTo: Date;
  administratedFrom?: Date;
  attachments: DocumentStoreDocument[];
};
export interface RentalUnit extends BaseAsset {
  data: RentalUnitData;
}

export interface EnrichtedRentalUnit extends RentalUnit {
  // extends the data of the rental unit with additional data
  data: RentalUnitData & {
    agreement?: RentalAgreement;
    tenant?: Contact;
    vacant?: RentalVacancy;
  };
}

export interface EnrichtedRentalUnitKPISGathered extends EnrichtedRentalUnit {
  kpis: {
    rentPerPlan: number;
    rentPerCurrent: number;
    rentPerAreaPlan: number;
    rentPerAreaCurrent: number;
    rentPerAreaDeficit: number;
    rentPerQuantityPlan: number;
    rentPerQuantityCurrent: number;
    rentPerQuantityDeficit: number;
    rentNetCurrent: number;
    rentGrossCurrent: number;
    rentNetDeficitCurrent: number;
    rentGrossDeficitCurrent: number;
    operatingCostNetCurrent: number;
    operatingCostGrossCurrent: number;
    operatingCostNetDeficitCurrent: number;
    operatingCostGrossDeficitCurrent: number;
  };
}

export const convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered = (
  data: EnrichtedRentalUnit[]
) => {
  return data.map((rentalUnit) => {
    const rentNetCurrent = rentalUnit.data.agreement?.data.rentNet || 0;
    const rentGrossCurrent = rentalUnit.data.agreement?.data.rentGross || 0;
    const rentNetDeficitCurrent = rentNetCurrent - rentalUnit.data.rentNet;
    const rentGrossDeficitCurrent =
      rentGrossCurrent - rentalUnit.data.rentGross;
    const operatingCostNetCurrent =
      rentalUnit.data.agreement?.data.operatingCostNet || 0;
    const operatingCostGrossCurrent =
      rentalUnit.data.agreement?.data.operatingCostGross || 0;
    const operatingCostNetDeficitCurrent =
      operatingCostNetCurrent - rentalUnit.data.operatingCostNet;
    const operatingCostGrossDeficitCurrent =
      operatingCostGrossCurrent - rentalUnit.data.operatingCostGross;

    const rentPerAreaPlan =
      rentalUnit.data.area > 0
        ? rentalUnit.data.rentNet / rentalUnit.data.area
        : 0;
    const rentPerAreaCurrent =
      rentalUnit.data.area > 0 ? rentNetCurrent / rentalUnit.data.area : 0;
    const rentPerAreaDeficit =
      rentalUnit.data.area > 0 ? rentPerAreaPlan - rentPerAreaCurrent : 0;
    const rentPerQuantityPlan =
      rentalUnit.data.quantity > 0
        ? rentalUnit.data.rentNet / rentalUnit.data.quantity
        : 0;
    const rentPerQuantityCurrent =
      rentalUnit.data.quantity > 0
        ? rentNetCurrent / rentalUnit.data.quantity
        : 0;
    const rentPerQuantityDeficit =
      rentalUnit.data.quantity > 0
        ? rentPerQuantityPlan - rentPerQuantityCurrent
        : 0;
    return {
      ...rentalUnit,
      kpis: {
        rentPerPlan: rentPerAreaPlan || rentPerQuantityPlan,
        rentPerCurrent: rentPerAreaCurrent || rentPerQuantityCurrent,
        rentPerAreaPlan,
        rentPerAreaCurrent,
        rentPerAreaDeficit,
        rentPerQuantityPlan,
        rentPerQuantityCurrent,
        rentPerQuantityDeficit,
        rentNetCurrent,
        rentGrossCurrent,
        rentNetDeficitCurrent,
        rentGrossDeficitCurrent,
        operatingCostNetCurrent,
        operatingCostGrossCurrent,
        operatingCostNetDeficitCurrent,
        operatingCostGrossDeficitCurrent,
      },
    };
  });
};

export type RentalAgreementUnit = {
  rentalUnitId: string;
  from: Date;
  to?: Date;
};

export interface RentalAgreementTimePeriod extends BaseAsset {
  data: {
    type: string;
    objectId: string;
    entity: string;
    rentalAgreement: string;
    isPlanned: boolean;

    isVacantPeriod: boolean;
    rentalUnitIds: string[];
    from: Date;
    to?: Date;
    rentGross: number;
    rentNet: number;
    operatingCostGross: number;
    operatingCostNet: number;
    paymentPositions: PaymentPosition[];
    comment?: string;
    reason: string;
  };
}

export type PaymentPosition = {
  id: string;
  net: number;
  gross: number;
};

export type DepositPosition = {
  identifier: string;
  depositValue: number;
  depositStart: Date;
  depositPayedOn?: Date;
  depositPayedBackOn?: Date;
  comment?: string;
};

export type RentPaymentTimingOption =
  | "startOfMonth"
  | "endOfMonth"
  | "middleOfMonth";
export interface RentalAgreement extends BaseAsset {
  nested: {
    tenantDisplayName?: string;
    entity: string;
    objectDisplayName: string;
    objectNumber: number;
    unitUnitType: string[];
    unitQuantity: number;
    unitArea: number;
  };
  data: {
    type: string;
    entity: string;
    objectId: string;
    rentalUnits: string[];

    rentPaymentTimingOptions: RentPaymentTimingOption;

    balance?: number;
    currentState?: string;
    id: string;
    displayName: string;

    tenant: string; // id Tenant

    moveIn: Date;
    agreementExpiration: Date | null;
    moveOut: Date | null;

    // from: Date;
    // to?: Date;
    status: "active" | "archived";
    attachments: DocumentStoreDocument[];
    note?: string;

    // deposit?: number;
    taxable?: boolean;

    //todo: add custom fields
    customFields?: {
      [key: string]: any; // todo: define custom fields
    };

    rentGross: number;
    rentNet: number;
    operatingCostGross: number;
    operatingCostNet: number;
    rentGrossTotal: number;
    rentNetTotal: number;
    paymentPositions: PaymentPosition[];
    lastRentChangeDate: Date;

    calculated: {
      rentWithOperatingCostsGross: number;
      rentWithOperatingCostsNet: number;
    };

    deposit: DepositPosition[];
    // mietart staffelmiete, indexmiete, anpassung, andere
    // optionsrechte -
    // verwaltergebühr
    //
    //
    //
    //
  };
}

type RentalAgreementPlanned = RentalAgreementPlannedRent;

interface RentalAgreementPlannedRent {
  type: "RENT";
  user: string | "system";

  from: Date;
  to: Date | null;

  rentGross: number;
  rentNet: number;

  operatingCostGross: number;
  operatingCostNet: number;
}

export interface RentalVacancy extends BaseAsset {
  nested: {
    entity: string;
    objectName: string;
    objectNumber: number;
    unitUnitType: string;
    unitQuantity: number;
    unitArea: number;
  };
  expand?: {
    rentalUnit: RentalUnit;
  };
  data: {
    id?: string;

    type: string;
    entity: string;
    objectId: string;
    rentalUnit: string;

    displayName: string;
    moveIn: Date;
    moveOut: Date | null;
    currentState?: string;
    note?: string;

    rentGross: number;
    rentNet: number;

    advertisements: RentalVacancyAdvertisement[];
    tags?: string[];
  };
}

export interface RentalVacancyAdvertisement {
  id: string;
  link: string;
  platform: string;
  from?: Date;
  to?: Date;
  note?: string;
  status: "active" | "closed";
}

export interface SubmitObjectPlanData {
  rentalUnitId: string;
  rentGross: number;
  rentNet: number;
  operatingCostGross: number;
  operatingCostNet: number;
}

export type RentalUnitFormValue = {
  displayName: string;
  unitType: string;
  unitGroup: string;
  quantity: number;
  area: number;
  building: string;
  floor: number;
  rentGross: number;
  rentNet: number;
  operatingCostGross: number;
  operatingCostNet: number;
  administratedFrom: Date;
  administratedTo: Date;
};
