import { useDispatch, useSelector } from "react-redux";
import { Modal } from "rsuite";
import i18n from "../../i18n";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { closeDocumentViewer } from "../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../redux/store";
import FileUtils from "../../utils/FileUtils";
import "./DocumentViewer.scss";

interface Props {}
const DocumentViewer = (props: Props) => {
  const dispatch = useDispatch();
  const document = useSelector(
    (state: AppState) => state.uiConfig.documentViewDocument
  );

  if (!document) {
    return null;
  }

  return (
    <Modal
      backdropClassName="document-viewer-backdrop"
      className="document-viewer-modal"
      size="full"
      // backdrop
      open
      onClose={() => dispatch(closeDocumentViewer())}
    >
      <div className="action-bar">
        <BFButton
          appearance="default"
          onClick={() =>
            FileUtils.downloadFile(
              document.url,
              document.fileName || "file.pdf",
              true
            )
          }
        >
          {i18n.t("DocumentViewer.download", "Herunterladen")}
        </BFButton>
        <BFButton
          appearance="default"
          onClick={() => dispatch(closeDocumentViewer())}
        >
          {i18n.t("DocumentViewer.close", "Schließen")}
        </BFButton>
      </div>
      {document.viewerType === "pdf" && (
        <div className={`pdf-viewer-container`}>
          <iframe
            src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(
              document.url + (document.fileName ? `#${document.fileName}` : "")
            )}`}
            title={"pdf"}
            frameBorder="0"
          />
        </div>
      )}
      {document.viewerType === "img" && (
        <div className={`img-viewer-container`}>
          <img src={document.url} />
        </div>
      )}
    </Modal>
  );
};
export default DocumentViewer;
