import React from "react";
import { FormSpy } from "react-final-form";
import ApplicationAdmin from "../apps/ApplicationAdmin/ApplicationAdmin";
import ApplicationDemo from "../apps/application-demo/ApplicationDemo";
import PermissionChecker from "../components/PermissionChecker/PermissionChecker";
import TooltipComponent from "../components/TooltipComponent/TooltipComponent";
import ActionComponent from "../configurable/components/ActionComponent/ActionComponent";
import BusinessUnitSelectionComponent from "../configurable/components/BusinessUnitSelectionComponent/BusinessUnitSelectionComponent";
import CardComponent from "../configurable/components/CardComponent/CardComponent";
import ChartComponent from "../configurable/components/ChartComponent/ChartComponent";
import ConfAvatarComponent from "../configurable/components/ConfAvatarComponent/ConfAvatarComponent";
import ConfigurableContextContainerComponent from "../configurable/components/ContextComponent/ConfigurableContextContainerComponent";
import DropdownComponent from "../configurable/components/DropdownComponent/DropdownComponent";
import IconComponent from "../configurable/components/IconComponent/IconComponent";
import LogComponent from "../configurable/components/LogComponent/LogComponent";
import RedirectComponent from "../configurable/components/RoutesComponent/RedirectComponent";
import RoutesComponent from "../configurable/components/RoutesComponent/RoutesComponent";
import SearchField from "../configurable/components/SearchField/SearchField";
import TableSearchField from "../configurable/components/SearchField/TableSearchField";
import Separator from "../configurable/components/Separator/Separator";
import TableComponent from "../configurable/components/TableComponent/TableComponent";
import TextComponent from "../configurable/components/TextComponent/TextComponent";
import AssetLoaderRoute from "../configurable/data/AssetLoaderRoute";
import ApplicationSidenavLayoutContent from "../configurable/layouts/ApplicationSidenavLayouts/ApplicationSidenavLayoutContent";
import ApplicationSidenavLayoutRoutes from "../configurable/layouts/ApplicationSidenavLayouts/ApplicationSidenavLayoutRoutes";
import DetailsRouteComponent from "../configurable/layouts/DetailsRouteComponent/DetailsRouteComponent";
import DivLayout from "../configurable/layouts/DivLayout/DivLayout";
import FlexLayout from "../configurable/layouts/FlexLayout/FlexLayout";
import GridLayout from "../configurable/layouts/GridLayout/GridLayout";
import {
  IComponent,
  instanceOfIComponent,
} from "../configurable/layouts/IComponent";
import ModalContainer from "../configurable/layouts/ModalContainer/ModalContainer";
import NavLayout from "../configurable/layouts/NavLayout/NavLayout";
import ScrollPanel from "../configurable/layouts/ScrollPanel/ScrollPanel";
import SpanLayout from "../configurable/layouts/SpanLayout/SpanLayout";
import SplitPageLayout from "../configurable/layouts/SplitPageLayout/SplitPageLayout";
import TabLayout from "../configurable/layouts/TabLayout/TabLayout";
import CardSection from "../configurable/wrapped/CardSection";
import Fill from "../configurable/wrapped/Fill";
import MetaRow from "../configurable/wrapped/MetaRow";
import ScrollCard from "../configurable/wrapped/ScrollCard";
import SidenavAction from "../configurable/wrapped/SidenavAction";
import TabButtonDefault from "../configurable/wrapped/TabButtonDefault";
import { DashboardMapper } from "../dashboard/DashboardMapper";
import Log from "../debug/Log";
import FormWrapper from "../modules/generic-forms-impl/FormWrapper";
import TrustedDevices from "../modules/user-management/trusted-devices/TrustedDevices";

const TodoInit = React.lazy(() => import("../apps/todo/TodoInit"));
const KFCDataSurveyInit = React.lazy(
  () => import("../apps/kfc/KfcDataSurveyInit")
);
const KFCDataEvaluationInit = React.lazy(
  () => import("../apps/kfc/KfcDataEvaluationInit")
);
const TatarCashBudget = React.lazy(
  () => import("./../apps/tatar/cashBudget/CashBudgetInit")
);
const UploadApp = React.lazy(
  () => import("./../apps/tatar/uploadApp/UploadApp")
);
const FAInit = React.lazy(
  () => import("./../apps/franchise/franchise-analytics-web/FAInit")
);

const TatarInvoiceApp = React.lazy(
  () => import("../apps/tatar/invoiceApp/InvoiceApp")
);
const TatarInvoiceConfigApp = React.lazy(
  () => import("../apps/tatar/invoiceConfigApp/RACApp")
);

const ActivityApp = React.lazy(
  () => import("../apps/tatar/activityApp/ActivityApp")
);

const Administration = React.lazy(
  () => import("../apps/administration/AdministrationApp")
);

const ActivityConfigApp = React.lazy(
  () => import("../apps/tatar/activityConfigApp/ActivityConfigApp")
);

const ObjectsApp = React.lazy(
  () => import("../apps/tatar/objectsApp/ObjectsApp")
);

const AccountingApp = React.lazy(
  () => import("../apps/tatar/accounting/AccountingApp")
);
const KanbanApp = React.lazy(() => import("../apps/tatar/kanban/KanbanApp"));
export const ComponentsMapper = {
  init() {
    Object.entries(DashboardMapper).forEach(([key, value]) => {
      this.registerComponent(key, value);
    });
  },
  createElement(
    comp: IComponent | React.ReactNode | ((params: any) => React.ReactNode),
    params: any,
    key?: any
  ) {
    /**
     * Really important note: NEVER EVER change comp attributes (are set to readonly now),
     * they should represent the configuration in the application config
     */
    if (!comp) {
      Log.error("#### no comp set");
      return null;
    }
    let useParams = undefined;

    if (instanceOfIComponent(comp)) {
      if (params) {
        if (comp.params) {
          useParams = params;
          Object.entries(comp.params).forEach(
            ([key, value]) => (useParams[key] = value)
          );
        } else {
          useParams = params;
        }
      }

      if (comp._component && ComponentsMapper[comp._component]) {
        if (
          useParams &&
          useParams.formRoot &&
          comp.condition &&
          ComponentsMapper.formComponentNames.indexOf(comp._component) === -1
        ) {
          return (
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                return (
                  <PermissionChecker key={key} permission={comp["permission"]}>
                    <TooltipComponent component={comp} params={params}>
                      {React.createElement(ComponentsMapper[comp._component], {
                        key: key,
                        ...comp,
                        params: useParams,
                        allValues: values,
                      })}
                    </TooltipComponent>
                  </PermissionChecker>
                );
              }}
            </FormSpy>
          );
        } else {
          return (
            <PermissionChecker key={key} permission={comp["permission"]}>
              <TooltipComponent component={comp} params={params}>
                {React.createElement(ComponentsMapper[comp._component], {
                  key: key,
                  ...comp,
                  params: useParams,
                })}
              </TooltipComponent>
            </PermissionChecker>
          );
        }
      }
    } else {
      if (typeof comp === "function") {
        return comp(params);
      } else {
        return comp;
      }
    }
    Log.error("#### Couldnt find component: " + comp);
    return null;
  },
  //custom applications
  ApplicationAdmin: ApplicationAdmin,
  ApplicationDemo: ApplicationDemo,

  //app layouts
  SidenavApp: ApplicationSidenavLayoutContent,
  SidenavRoutes: ApplicationSidenavLayoutRoutes,

  //routes
  routes: RoutesComponent,
  redirect: RedirectComponent,
  assetLoader: AssetLoaderRoute,

  //layout components
  TabLayout: TabLayout,
  tabs: TabLayout,
  FlexLayout: FlexLayout,
  Flex: FlexLayout,
  flex: FlexLayout,
  GridLayout: GridLayout,
  Grid: GridLayout,
  grid: GridLayout,
  DivLayout: DivLayout,
  Div: DivLayout,
  div: DivLayout,
  span: SpanLayout,
  SplitPageLayout: SplitPageLayout,
  scroll: ScrollPanel,
  scrollPanel: ScrollPanel,
  modal: ModalContainer,
  NavLayout: NavLayout,

  //standand components
  action: ActionComponent,
  Action: ActionComponent,
  Card: CardComponent,
  Table: TableComponent,
  separator: Separator,
  Separator: Separator,
  searchfield: SearchField,
  tableSearchfield: TableSearchField,
  log: LogComponent,
  chart: ChartComponent,
  dropdown: DropdownComponent,
  avatar: ConfAvatarComponent,
  contextContainer: ConfigurableContextContainerComponent,

  //specific compoenents
  detailsRoute: DetailsRouteComponent,
  businessUnitSelection: BusinessUnitSelectionComponent,
  //dashboard components

  // checkbox: FormCheckbox,

  // internal components
  userManagementTrustedDevices: TrustedDevices,

  //forms
  form: FormWrapper,

  //display components
  icon: IconComponent,
  simpleText: TextComponent,

  //wrapper
  tabButtonDefault: TabButtonDefault,
  ScrollCard: ScrollCard,
  fill: Fill,
  CardSection: CardSection,
  sidenavAction: SidenavAction,
  "meta-row": MetaRow,

  // app initializings
  "app.init.todo": TodoInit,
  "app.init.tatar.kfc.survey": KFCDataSurveyInit,
  "app.init.tatar.kfc.evaluation": KFCDataEvaluationInit,
  "app.init.tatar.cashBudget": TatarCashBudget,
  "app.init.tatar.upload": UploadApp,
  "app.init.franchise.kpiApp": FAInit,
  "app.init.tatar.invoice": TatarInvoiceApp,
  "app.init.administration": Administration,
  "app.init.tatar.activity": ActivityApp,
  "app.init.tatar.activity.config": ActivityConfigApp,
  "app.init.tatar.invoiceConfig": TatarInvoiceConfigApp,
  "app.init.objectsApp": ObjectsApp,
  "app.init.accounting": AccountingApp,
  "app.init.kanban": KanbanApp,

  formComponentNames: [],
  registerComponent: (
    name: string,
    component: React.Component,
    isFormComponent?: boolean
  ) => {
    ComponentsMapper[name] = component;
    if (isFormComponent) {
      ComponentsMapper.formComponentNames.push(name);
    }
  },
};
export const registerComponent = (name: string, component: React.Component) => {
  ComponentsMapper[name] = component;
};
(window as any).ComponentsMapper = ComponentsMapper;
