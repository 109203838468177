import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import { AccountingBookingType } from "@/apps/tatar/accounting/interfaces/account.interface";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { useHttpCache } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { Loader } from "rsuite";
import { RentalAgreement } from "../../../TenantsInterfaces";
import "./CBRentalAgreementOpenAmounts.scss";
import { DebitPositionResult } from "./CBRentalAgreementOpenDebitPositions";
import CBRentalAgreementSaldoGraph from "./CBRentalAgreementSaldoGraph";

interface CBRentalAgreementOpenAmountsProps {
  accountingData: AccountingData;
  rentalAgreement: RentalAgreement;
  object: OAObject;
}
const CBRentalAgreementOpenAmounts = (
  props: CBRentalAgreementOpenAmountsProps
) => {
  const account = props.accountingData.accounting.data.accounts.find(
    (e) => e.assetLink?.assetId === props.rentalAgreement._id
  )?.id;
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-open-debit-positions-${account}`,
    `/api/accounting/${props.rentalAgreement.data.entity}/${account}/getOpenDebitPositions`,
    "get",
    null
  );

  const debitAccount = props.accountingData.accounting.data.accounts.find(
    (e) =>
      e.assetLink?.assetType === AssetTypes.Rental.RentalAgreement &&
      e.assetLink?.assetId === props.rentalAgreement._id
  );

  if (!debitAccount) {
    return (
      <div
        className={`cb-rental-agreement-accounting __card no-debit-positions`}
      >
        {i18n.t(
          "cb:RentalAgreement.Accounting.NoDebitPositions",
          "Es wurde bisher kein Mieterkonto angelegt."
        )}
      </div>
    );
  }

  const objectKindId = props.object.data.objectKindId;
  const objectKind = ObjectKindStruct.getKind(objectKindId);

  const debitPositions = (
    props.object.data.feature?.immo?.accounting?.debitposition || []
  ).filter((e) => props.rentalAgreement.data.taxable && e.taxRate !== 0);
  const debitPositionsIds = debitPositions.map((e) => e.id);

  const balance = (debitAccount.sumBalance || 0) * -1;
  const relevantDebitPositionAccountIds =
    props.accountingData.accounting.data.accounts
      .filter(
        (e) =>
          e.assetLink?.assetType === AssetTypes.Portfolio.Object &&
          e.assetLink?.assetId === props.object._id &&
          debitPositionsIds.some((a) => a === e.assetLink?.extra)
      )
      .map((e) => e.id);

  if (data.state === "loading") {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (data.state === "error") {
    return (
      <div>
        <p>error</p>
      </div>
    );
  }

  const bookings = data.data
    .map((e) =>
      e.bookings.map((a) =>
        AccountingService.getCorrectedBooking(e.data.account, a)
      )
    )
    .flat();
  const contraBookings = data.data
    .map((e) =>
      e.contraBookings.map((a) =>
        AccountingService.getCorrectedBooking(e.data.account, a)
      )
    )
    .flat();
  const relevantAccountIds = _.uniq([
    ...relevantDebitPositionAccountIds,
    ...bookings.map((e) => e.data.contraAccount),
  ]);

  return (
    <div className={classNames(`cb-rental-agreement-open-accounts`)}>
      <DebugDataComponent
        data={{
          debitAccount,
          accountingData: props.accountingData,
          objectKind,
          object: props.object,
          relevantAccountIds,
        }}
      />
      <div className={`head-line`}>
        <div className={`label`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.Accounting.CurrentDebit",
            "Aktueller Kontostand"
          )}
        </div>
        <div
          className={classNames(`value`, {
            negative: balance < 0,
            postive: balance > 0,
          })}
        >
          {StringUtils.formatCurrency(
            balance,
            undefined,
            undefined,
            props.accountingData.accounting.data.currency
          )}
        </div>
      </div>
      <div className={`info-container`}>
        <div className={`graph`}>
          <CBRentalAgreementSaldoGraph
            accountingData={props.accountingData}
            account={
              props.accountingData.accounting.data.accounts.find(
                (e) => e.assetLink?.assetId === props.rentalAgreement._id
              )?.id
            }
          />
        </div>
        <div className={`sub-positions`}>
          <table>
            {relevantAccountIds.map((accId) => {
              const account =
                props.accountingData.accounting.data.accounts.find(
                  (e) => e.id === accId
                );

              const balance = _.sum([
                ...bookings
                  .filter((e) => e.data.contraAccount === accId)
                  .map(
                    (e) =>
                      e.data.value.converted.amount *
                        (e.data.bookingType === AccountingBookingType.HABEN
                          ? 1
                          : -1) +
                      _.sum(
                        contraBookings
                          .filter(
                            (a) =>
                              a.data.costId === e._id &&
                              a.data.bookingType === AccountingBookingType.HABEN
                          )
                          .map((a) => a.data.value.converted.amount)
                      ) -
                      _.sum(
                        contraBookings
                          .filter(
                            (a) =>
                              a.data.costId === e._id &&
                              a.data.bookingType === AccountingBookingType.SOLL
                          )
                          .map((a) => a.data.value.converted.amount)
                      )
                  ),
              ]);

              return (
                <>
                  <tr className={`sub-debit`}>
                    <td className={`label`}>
                      <span>
                        {LanguageService.translateLabel(account.displayName)}
                      </span>
                    </td>
                    <td
                      className={classNames(`value`, {
                        negative: balance < 0,
                        postive: balance > 0,
                      })}
                    >
                      {StringUtils.formatCurrency(
                        balance,
                        true,
                        undefined,
                        props.accountingData.accounting.data.currency
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CBRentalAgreementOpenAmounts;
