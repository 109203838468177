import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Drawer } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import Log from "../../../../../debug/Log";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import {
  Signature,
  Textblock,
  UserConfigAsset,
} from "../../../../../model/db/UserConfigAsset";
import CommentUtils from "../../../../../modules/comments-module/CommentUtils";
import { MailIncomingComment } from "../../../../../modules/comments-module/Comments";
import CommentsService from "../../../../../modules/comments-module/CommentsService";
import { MailIncomingCommentEntry } from "../../../../../modules/comments-module/components/CommentEntry";
import { CommentAttachmentEntry } from "../../../../../modules/comments-module/components/CommentInput";
import CommentInputWrapper from "../../../../../modules/comments-module/components/CommentInputWrapper";
import { EmailAddressFieldModel } from "../../../../../modules/comments-module/components/EmailAddressFields";
import { useTypedSelector } from "../../../../../redux/hooks";
import DataBus from "../../../../../services/DataBus";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import PermissionService from "../../../../../services/PermissionService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { useActivityConstants } from "../../ActivityHooks";
import { AP_LIST_TABLE_IDENTIFIER } from "../../ActivityUtils";
import "./APUnassignedMessageDetails.scss";
import APAssignMessageToActivityActions from "./actions/APAssignMessageToActivityActions";

interface APUnassignedMessageDetailsProps {
  mail: MailIncomingComment;
  showAsDrawer?: boolean;
  restrictFullscreen?: boolean;
  hideResubmissionActionForMail?: boolean;
  hideAllActions?: boolean;
}

const APUnassignedMessageDetails = (props: APUnassignedMessageDetailsProps) => {
  const constants = useActivityConstants();
  const userId = useTypedSelector((state) => state.global.user._id);
  const [commentActive, setCommentActive] = React.useState(false);
  const match = useRouteMatch();

  useEffect(() => {
    setCommentActive(false);
  }, [props.mail]);

  const units = PermissionService.hasBusinessUnitRole(
    `${constants?.permissionPrefix}createActivities`
  );
  const hasUnitPermission = units && units.length > 0;

  const renderActions = () => {
    if (!hasUnitPermission) {
      return null;
    }

    return (
      <div className={`action-row`}>
        <div className={`fill`} />
        <APAssignMessageToActivityActions mail={props.mail} units={units} />
      </div>
    );
  };

  const onCloseDrawer = () => {
    DataBusDefaults.route({
      route: match.path.replace("/:id", ""),
    });
  };

  const renderAsDrawer = () => {
    return (
      <Drawer
        open={true}
        size="full"
        backdrop={"static"}
        placement={"bottom"}
        onClose={() => onCloseDrawer()}
      >
        <Drawer.Header>
          <Drawer.Title>{i18n.t("Global.Buttons.close")}</Drawer.Title>
          {hasUnitPermission && (
            <Drawer.Actions>{renderActions()}</Drawer.Actions>
          )}
        </Drawer.Header>
        <Drawer.Body>{renderDetailsView(true)}</Drawer.Body>
      </Drawer>
    );
  };

  const renderDetailsView = (renderedAsDrawer?: boolean) => {
    return (
      <div className={classNames(`ap-unassigned-message-details`)}>
        {!renderedAsDrawer && renderActions()}
        <div className={`details-content`}>
          <AssetLoader
            assetType={AssetTypes.UserConfig}
            query={{
              type: "op",
              name: "data.user",
              op: "eq",
              value: userId,
            }}
            render={(userConfig: UserConfigAsset) => {
              const signatures: Signature[] = userConfig?.data.signatures || [];
              const defaultSignature = userConfig?.data.defaultSignature;
              const textblocks: Textblock[] = userConfig?.data.textblocks || [];
              const signature = signatures?.[defaultSignature]?.signature;
              return (
                <div className={`comment-entry-container`}>
                  <MailIncomingCommentEntry
                    apperance={renderAsDrawer ? "slim" : "normal"}
                    hideAllActions={props.hideAllActions}
                    hideResubmissionActionForMail={
                      props.hideResubmissionActionForMail
                    }
                    allowFullscreen={
                      !renderedAsDrawer && !props.restrictFullscreen
                    }
                    comment={props.mail}
                    defaultSignature={
                      defaultSignature >= 0
                        ? signatures[defaultSignature].signature
                        : undefined
                    }
                    onReply={() => {
                      DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                        identifier: `${
                          AP_LIST_TABLE_IDENTIFIER + constants?.assetType
                        }`,
                        cursorPosition: "start",
                        sendAsMail: true,
                        comment: `
                  <p>
                  </p>
                  ${signature || ""}
                  <br>
                  <br>
                  
                  <div class="iberio-cite-prefix">${i18n.t(
                    "CommentsModule.IncomingMail.citePrefix",
                    "Am {{date}}, {{sender}} schrieb: ",
                    {
                      date: moment(props.mail.data.date).format(
                        i18n.t("Formats.dateTimeFormat")
                      ),
                      sender: CommentUtils.formatEmailUser(
                        props.mail.data.typeData.sender
                      ),
                    }
                  )}<br></div>
                  <blockquote type="cite" cite="mid:${
                    props.mail.data.typeData.sender.address
                  }">
                  ${
                    props.mail.cdnHtml
                      ? CommentUtils.stripImagesBase64FromHTML(
                          CommentUtils.stripCommentsFromHTML(props.mail.cdnHtml)
                        )
                      : props.mail.data.message?.replaceAll("\n", "<br>") || ""
                  }
                  </blockquote>
                `,
                        subactivity: props.mail.data.subactivityId,
                        emailAddressFields: {
                          recipients: [props.mail.data.typeData.sender],
                          cc: props.mail.data.typeData.cc,
                          subject: props.mail.data.typeData.subject,
                        } as EmailAddressFieldModel,
                      });

                      setCommentActive(true);
                    }}
                    onRedirect={() => {
                      DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                        identifier: `${
                          AP_LIST_TABLE_IDENTIFIER + constants?.assetType
                        }`,
                        cursorPosition: "start",
                        comment: `
              <p>
              </p>
              ${signature || ""}
              <br>
              <br>
              
              <div class="iberio-cite-prefix">${i18n.t(
                "CommentsModule.IncomingMail.citePrefix",
                "Am {{date}}, {{sender}} schrieb: ",
                {
                  date: moment(props.mail.data.date).format(
                    i18n.t("Formats.dateTimeFormat")
                  ),
                  sender: CommentUtils.formatEmailUser(
                    props.mail.data.typeData.sender
                  ),
                }
              )}<br></div>
              <blockquote type="cite" cite="mid:${
                props.mail.data.typeData.sender.address
              }">
              ${
                props.mail.cdnHtml
                  ? CommentUtils.stripImagesBase64FromHTML(
                      CommentUtils.stripCommentsFromHTML(props.mail.cdnHtml)
                    )
                  : props.mail.data.message?.replaceAll("\n", "<br>") || ""
              }
              </blockquote>
            `,
                        sendAsMail: true,
                        subactivity: props.mail.data.subactivityId,
                        attachments: props.mail.cdn
                          .filter((cdn) => {
                            return props.mail.data?.attachments?.find(
                              (attachment) => attachment.linkToCdn === cdn._id
                            );
                          })
                          .map(
                            (cdn) =>
                              ({
                                cdnId: cdn._id,
                                filename: cdn.filename,
                                fromtAssetId: props.mail._id,
                              } as CommentAttachmentEntry)
                          ),
                        emailAddressFields: {
                          recipients: [],
                          cc: [],
                          subject: props.mail.data.typeData.subject,
                        } as EmailAddressFieldModel,
                      });

                      setCommentActive(true);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <Collapse in={commentActive}>
          <div>
            <div className={`comment-input-wrapper`}>
              <CommentInputWrapper
                ignoreDraft
                identifier={`${
                  AP_LIST_TABLE_IDENTIFIER + constants?.assetType
                }`}
                onSend={async (
                  message,
                  mailAddressFields,
                  subactivityName,
                  attachments
                ) => {
                  try {
                    const attachmentsFromOtherAssets = attachments?.filter(
                      (e) => e.fromtAssetId
                    );
                    await CommentsService.sendMail({
                      assetType: constants?.assetType,
                      type: props.mail.data.typeData.unassignedData[0]?.type,
                      message,
                      ...mailAddressFields,
                      subactivityName,
                      attachments: attachments
                        ?.filter((e) => !e.fromtAssetId)
                        .map((e) => e.cdnId),
                      attachmentsFromOtherMail:
                        attachmentsFromOtherAssets &&
                        attachmentsFromOtherAssets.length > 0
                          ? {
                              assetId:
                                attachmentsFromOtherAssets[0].fromtAssetId,
                              linksToCdn: attachmentsFromOtherAssets.map(
                                (e) => e.cdnId
                              ),
                            }
                          : undefined,
                    });

                    setCommentActive(false);
                    DataBusDefaults.toast({
                      type: "success",
                      text: i18n.t(
                        "apTemplate:List.Activities.sendMailSuccess",
                        "E-Mail wurde versendet",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      ),
                    });
                  } catch (err) {
                    Log.error("err", err);
                    DataBusDefaults.toast({
                      type: "error",
                      text: i18n.t(
                        "apTemplate:List.Activities.sendMailError",
                        "Fehler beim Versenden der Mail",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      ),
                    });
                  }
                }}
                onlyActive
                onlyAsMail={true}
                onCancel={() => setCommentActive(false)}
                type={props.mail.data.type}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  if (props.showAsDrawer) {
    return renderAsDrawer();
  }

  return renderDetailsView();
};

export default APUnassignedMessageDetails;
