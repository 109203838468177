import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFDate from "@/modules/abstract-ui/forms/date/BFDate";
import BFCurrencySelect from "@/modules/abstract-ui/forms/input/BFCurrencySelect";
import { useTypedSelector } from "@/redux/hooks";
import { Field } from "react-final-form";
import AccountingService from "../../AccountingService";
// import "./AddAccountingEntityForm.scss";

interface AddAccountingEntityFormProps {
  close: () => void;
  entity?: string;
  onSuccess?: () => void;
}
const AddAccountingEntityForm = (props: AddAccountingEntityFormProps) => {
  const unitTypes = useTypedSelector(
    (state) => state.uiConfig.activeApplication.constants?.businessUnits
  );

  return (
    <FormStruct
      initialValues={{
        entity: props.entity,
      }}
      allowOverflow
      onAbort={props.close}
      onSubmit={async (values) => {
        await AccountingService.createAccountForEntity(
          values.entity,
          values.startOfAccounting,
          values.currency.currency
        );
        props.onSuccess?.();
        props.close();
      }}
      submitText={i18n.t(
        "acc:Root.AddAccountingEntityForm.SubmitText",
        "Gesellschaft hinzufügen"
      )}
      description={i18n.t(
        "acc:Root.AddAccountingEntityForm.Description",
        "Wählen Sie die Gesellschaft aus, welche Sie zur Buchhaltung hinzufügen wollen. Geben Sie ebenso die weiteren Daten für die Buchhaltung an."
      )}
      render={(formProps) => (
        <>
          {!props.entity && (
            <div className={`__field`}>
              <Field name="entity" validate={FV.compose(FV.required())}>
                {({ input, meta }) => (
                  <BFChooserSelect
                    data={AccountingService.getEntitySelectOptions(
                      unitTypes,
                      false
                    )}
                    {...input}
                    {...FV.getValidation(meta)}
                    label={i18n.t(
                      "acc:Root.AddAccountingEntityForm.Label",
                      "Gesellschaft"
                    )}
                    placeholder={i18n.t(
                      "acc:Root.AddAccountingEntityForm.Placeholder",
                      "Gesellschaft auswählen"
                    )}
                  />
                )}
              </Field>
            </div>
          )}
          <div className={`__field`}>
            <Field
              name="startOfAccounting"
              validate={FV.compose(FV.required())}
            >
              {({ input, meta }) => (
                <BFDate
                  {...input}
                  {...FV.getValidation(meta)}
                  day
                  label={i18n.t(
                    "acc:Root.AddAccountingEntityForm.StartOfAccounting",
                    "Start des Wirtschaftsjahrs"
                  )}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="currency" validate={FV.compose(FV.required())}>
              {({ input, meta }) => (
                <BFCurrencySelect
                  placeholder={i18n.t(
                    "acc:Root.AddAccountingEntityForm.CurrencyPlaceholder",
                    "Währung auswählen"
                  )}
                  noDefaultValue
                  appearance="input"
                  label={i18n.t(
                    "acc:Root.AddAccountingEntityForm.Currency",
                    "Währung der Buchhaltung"
                  )}
                  {...input}
                  {...FV.getValidation(meta)}
                />
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};

export default AddAccountingEntityForm;
