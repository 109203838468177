import i18n from "../i18n";
import DataBusDefaults from "./DataBusDefaults";

class ServiceUtilsClass {
  async toastError(
    promise: () => Promise<any>,
    errorMsg?: string | ((err: any) => Promise<string>)
  ) {
    try {
      return await promise();
    } catch (error) {
      let msg;
      if (typeof errorMsg === "function") {
        try {
          msg = await errorMsg(error);
        } catch (err) {
          msg = null;
        }
      } else {
        msg = errorMsg;
      }
      if (!msg) {
        msg = i18n.t(
          "Global.Error.general",
          "Leider ist ein Fehler aufgetreten."
        );
      }
      DataBusDefaults.toast({
        type: "error",
        text: msg,
      });
      throw error;
    }
  }
}
const ServiceUtils = new ServiceUtilsClass();
export default ServiceUtils;
